import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import AccountDropDown from 'components/DropDowns/AccountsDropDown'
import TimePeriodDropDown from 'components/DropDowns/TimeDropDown'
import { bankTransactionCategories, addHttp } from '../../../util'
import icon from 'assets/icons/icon-category.svg'
import { fetchFilterTransactions } from 'actions/paginateTransactions'

import SectionList from 'components/List/SectionList'

const TransactionsContent = (props) => {
  const [accountName, setSelectedAccount] = useState()
  const [groupedTx, setGroupedTx] = useState([])
  const [rowHeight, setRowHeight] = useState('12px')

  useEffect(() => {
    const size = window.innerHeight <= 900 ? 80 : 100
    setRowHeight(size)
  }, [])

  const cleanTransactionData = (tx) => {
    const {
      id: txId,
      amount,
      company_id: companyId,
      iso_currency_code: currency,
      // user_category: category,
      notes,
      date,
      heron_category: category,
      ntropy_website_url: merchantWebsite,
      institution_name: bankName,
      ntropy_merchant_logo_url: ntropyLogo,
      merchant_logo_url: ogLogo,
      ntropy_merchant_name: ntropyMerchantName,
      user_category: userCategory,
      merchant_name: aggegatorMerchantName,
      receipts,
      receipt_file_name: receiptFileName,
      receipt_file_store_url: receiptUrl,
      name
    } = tx
    let logoURL = icon
    let isLogo = false
    if (ntropyLogo) {
      logoURL = ntropyLogo
      isLogo = true
    }
    if (ogLogo) {
      logoURL = ogLogo
      isLogo = true
    }
    let catId = 0
    if (category) {
      [catId] = category.split('_')
    }

    const formattedCat = bankTransactionCategories.find(({ id }) => id === Number(catId))

    return {
      txId,
      companyId,
      amount,
      date,
      notes,
      currency,
      bankName,
      receipts,
      name: ntropyMerchantName || aggegatorMerchantName || name,
      logoURL,
      category: formattedCat ? formattedCat.category : 'Unknown',
      isLogo,
      merchantWebsite: merchantWebsite && addHttp(merchantWebsite),
      userCategory,
      receiptFileName,
      receiptUrl
    }
  }

  useEffect(() => {
    const result = {}
    const target = []

    const cleanedTransactions = props.paginatedTransactions.txs.map(tx => cleanTransactionData(tx))

    // Group transaction by date
    cleanedTransactions.forEach(tx => {
      const txDate = moment(tx.date).format('ddd, D, MMMM')
      if (result[txDate]) {
        result[txDate].push(tx)
      } else {
        result[txDate] = [tx]
      }
    })

    // Transform object into array
    for (const date in result) {
      if (Object.prototype.hasOwnProperty.call(result, date)) {
        const element = result[date]
        target.push({
          id: uuidv4(),
          title: date,
          data: element
        })
      }
    }

    setGroupedTx(target)
  }, [props.paginatedTransactions])

  const handleAccountChange = account => {
    props.selectAccount(account)
    props.setTransactionDetails({})
    if (!account) {
      setSelectedAccount()
      return
    }
    setSelectedAccount(account)
  }

  const handleTransactionsFetch = () => {
    if (props.selectedAccount) {
      const params = `single=${props.selectedAccount}`
      props.fetchFilterTransactions(params)
    } else {
      props.fetchFilterTransactions()
    }
  }

  const TransactionRow = ({ item, sectionIndex, rowIndex, key, style, isScrolling, isVisible, parent }) => {
    const { txId, amount, currency, bankName, name, logoURL, category, isLogo } = item

    const rowClass = props.txDetails && props.txDetails.txId === txId ? 'selected' : ''
    let transformStyle = 'rotate(180deg)'
    if (isLogo) {
      transformStyle = ''
    }

    return (
      <div
        id='transaction-row'
        key={key}
        style={style}
        className={rowClass}
        onClick={() => props.setTransactionDetails(item)}
        onMouseEnter={() => props.setTransactionDetails(item)}
      >
        <div className='row-left'>
          <img
            src={logoURL}
            style={amount > 0 ? {} : { transform: transformStyle }}
          />
          <div className='tx-detail-wrapper'>
            <span className='tx-title'>{name}</span>
            <span className='tx-category'>{category}</span>
          </div>
        </div>
        <div className='row-right'>
          <div className='tx-meta-wrapper'>
            <span className='tx-amt'>{currency} {amount.toFixed(2).toLocaleString()}</span>
            <span className='tx-bank text-gray'>{bankName}</span>
          </div>
        </div>
      </div>
    )
  }

  const renderHeader = ({ title, sectionIndex, key, style, isScrolling, isVisible, parent }) => {
    return (
      <div key={key} className='tx-header' style={style}>
        <span className='text'>{title}</span>
      </div>
    )
  }

  return (
    <>
      <div id='transactions-content'>
        <div className='transactions-header'>
          <span>
            Transactions from
            <AccountDropDown
              accounts={props.accounts}
              selectedAccount={accountName}
              setSelectedAccount={handleAccountChange}
            />
            from the last
            <TimePeriodDropDown
              selectedTimePeriod={props.period.text}
              setSelectedTimePeriod={props.selectPeriod}
            />
          </span>
        </div>
        <div id='scroll' className='transactions-list dashboard-transactions'>
          <SectionList
            handleTransactionsFetch={handleTransactionsFetch}
            sections={groupedTx}
            sectionHeaderRenderer={renderHeader}
            sectionHeaderHeight={50}
            rowHeight={rowHeight}
            rowRenderer={TransactionRow}
          />
        </div>
      </div>
    </>
  )
}

const propMap = state => ({
  accounts: state.accounts.accounts,
  paginatedTransactions: state.paginatedTransactions
})

export default connect(propMap, { fetchFilterTransactions })(TransactionsContent)
