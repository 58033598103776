import {
  AUTHORIZED_LOADING,
  SET_EVENT,
  SET_INSTITUTION,
  STOP_AUTHORIZED_LOADING
} from '../actions/constants'

const initState = {
  loading: false,
  event: {
    newTransactions: false,
    newAccounts: false
  },
  institutionName: ''
}

export default (state = initState, action) => {
  switch (action.type) {
    case AUTHORIZED_LOADING:
      return {
        ...state,
        loading: true
      }
    case SET_EVENT:
    case SET_INSTITUTION:
      return {
        ...state,
        ...action.payload
      }
    case STOP_AUTHORIZED_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
