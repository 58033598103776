import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'
import CancelRounded from '@material-ui/icons/CancelRounded'
import { Input } from 'reactstrap'

const CustomInput = ({ onChange, placeholder, valueLabel, options, value, name, labelName, type, disabled, completed, error, style }) => {
  const [open, toggleOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)

  const getOpts = () => {
    if (!open) {
      return (
        <div
          className='custom-input__selected-option'
          style={{ border: 'none', borderBottom: isFocused ? '1px solid #007AFF' : '1px solid #888888', borderRadius: '0px' }}
          onClick={() => setSelectOpen(!selectOpen)}
        >
          <span>{valueLabel}</span><FontAwesomeIcon size='sm' icon={faCaretDown} />
        </div>
      )
    }
    return (
      <>
        <div
          className='custom-input__selected-option'
          style={{ border: 'none', borderBottom: selectOpen ? '1px solid #007AFF' : '1px solid #888888', borderRadius: '0px' }}
          onClick={() => setSelectOpen(!selectOpen)}
        >
          <span>{valueLabel}</span><FontAwesomeIcon size='sm' icon={faCaretDown} />
        </div>
        <div className='custom-input__options' style={{ marginTop: '1px', width: '100%' }}>
          {options.map((opt, idx) => (
            <div
              style={{ marginTop: '1px', width: '100%' }}
              className='custom-input__option'
              key={idx}
              onClick={() => {
                console.log('opt', opt)
                onChange({ target: { name: name, value: opt.value, valueLabel: opt.label, ...opt } })
              }}
            >
              <span>{opt.label}</span>
            </div>
          ))}
        </div>
      </>
    )
  }

  const handleDateChange = dateValue => {
    onChange({ target: { name: name, value: new Date(dateValue), valueLabel: dateValue } })
  }

  const handleCheckboxChange = () => {
    onChange({ target: { name: name, value: !value } })
  }

  const getValidInput = () => {
    if (type === 'select') {
      return (
        <div onClick={() => toggleOpen(!open)} className='custom-input__selection'>
          <span className='custom-input__select-label d-block' style={{ color: selectOpen ? '#007AFF' : '#888888' }}>
            {labelName}
          </span>
          {getOpts()}
        </div>
      )
    }
    if (type === 'checkbox') {
      return (
        <div className='custom-input__checkbox'>
          <label className='custom-input__checkbox-label'>
            {labelName}
          </label>
          <Input
            className='custom-input__checkbox-input'
            type='checkbox'
            checked={value}
            onChange={handleCheckboxChange}
          />
        </div>
      )
    }
    if (type === 'date') {
      return (
        <div className='custom-input__date' style={{ border: 'none', borderBottom: '1px solid #888888', borderRadius: '0px' }}>
          <label className='custom-input__date-label d-block' style={{ color: isFocused ? '#007AFF' : '#888888' }}>
            {labelName}
          </label>
          <DatePicker
            selected={value ? new Date(value) : new Date()}
            onChange={handleDateChange}
          />
        </div>
      )
    }
    return (
      <div className={disabled ? 'custom-input__text disabled' : 'custom-input__text'} style={{ border: 'none', borderBottom: isFocused ? '1px solid #007AFF' : '1px solid #888888', borderRadius: '0px' }}>
        <label className='custom-input__text-label d-block' style={{ color: isFocused ? '#007AFF' : '#888888' }}>
          {labelName}
        </label>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Input
            style={style}
            className='custom-input__regular-input'
            value={value}
            placeholder={placeholder || labelName}
            name={name}
            type={type}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          {completed
            ? (
              <div className='h-100  pb-1'>
                <CheckCircleRounded fontSize='small' style={{ color: '#007805' }} />
              </div>)
            : error &&
              (
                <div className='h-100  pb-1'>
                  <CancelRounded fontSize='small' style={{ color: 'red' }} />
                </div>)}
        </div>
      </div>
    )
  }

  return (
    <div className='custom-input'>
      <div className='custom-input'>
        {getValidInput()}
      </div>
    </div>
  )
}

export default CustomInput
