import { putRequest, getRequest, deleteRequest, postRequest } from 'API/ApiClient'

export const getDeliveryMethodString = (deliveryMethod, index) => {
  if (!deliveryMethod) {
    return ''
  };
  switch (deliveryMethod.delivery_method_type) {
    case 'sms':
      if (index > 0) {
        return 'and SMS '
      } else {
        return 'SMS '
      }
    case 'email':
      if (index > 0) {
        return 'and email '
      } else {
        return 'email '
      }
    case 'slack':
      if (index > 0) {
        return 'and Slack '
      } else {
        return 'Slack '
      }
    case 'webhook':
      if (index > 0) {
        return 'and webhook '
      } else {
        return 'webhook '
      }
    case 'whatsapp':
      if (index > 0) {
        return 'and Whatsapp '
      } else {
        return 'Whatsapp '
      }
    default:
      return ''
  }
}

export const fetchAlerts = async (signal) => {
  return await getRequest({ path: '/api/alerts/list', signal })
}

export const listDeliveryMethods = async (signal) => {
  return await getRequest({ path: '/api/alerts/delivery-methods/list', signal })
}

export const deleteSingleAlert = async (id) => {
  return await deleteRequest({ path: '/api/alerts/' + id })
}

export const saveAlert = async (body) => {
  return await postRequest({ path: '/api/alerts', body })
}

export const saveDeliveryMethod = async (body) => {
  return await postRequest({ path: '/api/alerts/delivery-method', body })
}

export const updateSlackChannel = async (body) => {
  return putRequest({ path: '/api/alerts/auth/slack', body })
}

export const initStyle = {
  color: '#109CF1',
  padding: 0,
  marginBottom: 0,
  border: 'none',
  height: 'auto',
  appearance: 'none',
  MozAppearance: 'none',
  WebkitAppearance: 'none',
  boxSizing: 'border-box',
  background: 'none',
  fontSize: '1em',
  marginRight: '1px',
  marginLeft: '1px'
}
