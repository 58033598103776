import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Amount from 'components/Amount'
import { setSelectedAccount } from 'actions/accountActions'

export const AccountCard = props => {
  const [amountSize, setAmountSize] = useState(25)

  const {
    id: accountId,
    current_balance: currentBalance,
    logo_url: logo,
    name,
    institution_name: institutionName,
    iso_currency_code: currency
  } = props.account

  const cardClassName = props.selectedAccountId === accountId ? 'account-card selected-account-card' : 'account-card'

  useEffect(() => {
    const size = window.innerWidth <= 1280 ? 20 : 25
    setAmountSize(size)
  }, [])

  let style
  if (institutionName === 'BBVA Bancomer') {
    style = { height: '20px', width: '70px', paddingLeft: '3px' }
  }

  return (
    <>
      <div id='belvo' className={cardClassName} onClick={() => props.setSelectedAccount(accountId)}>
        <div className='account-card-header'>
          <div className='account-details'>
            <img style={style} src={logo} />
            <span className='account-name'>{name}</span>
          </div>
        </div>
        <div className='account-card-body'>
          <Amount amount={currentBalance} fontSize={amountSize} currency={currency} />
          <span className='text-gray'>Available balance</span>
        </div>
      </div>
    </>
  )
}

const propMap = state => ({
  selectedAccountId: state.accounts.selectedAccountId
})

export default connect(propMap, {
  setSelectedAccount
})(AccountCard)
