import Connect from 'views/Connect/Connect'
import Reauthenticate from 'views/Reauth/Reauthenticate'
import Invoices from 'views/Invoices/Invoices'
import Home from 'views/Home/Home'
import Transact from 'views/Transact/Transact'
// import Data from 'views/Data/Data'
import AlertsView from 'views/Alerts/AlertsView'
import TeamView from 'views/Team/TeamView'
import SettingsView from 'views/Settings/SettingsView'
import Cash from 'views/Cash/Cash'
// import Worlflow from 'views/Workflow/Workflow'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    layout: '/admin',
    sidebar: true,
    header: true,
    commandbar: true,
    isLink: true
  },
  {
    path: '/cash',
    name: 'Cash',
    component: Cash,
    layout: '/admin',
    sidebar: true,
    header: true,
    commandbar: true,
    isLink: true
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
    layout: '/admin',
    sidebar: true,
    header: true,
    commandbar: true,
    isLink: true
  },
  // {
  //   path: '/data',
  //   name: 'Data Science',
  //   component: Data,
  //   layout: '/admin',
  //   sidebar: true,
  //   header: true,
  //   commandbar: true,
  //   isLink: true
  // },
  // {
  //   path: '/workflow',
  //   name: 'Workflows',
  //   component: Worlflow,
  //   layout: '/admin',
  //   sidebar: true,
  //   header: true,
  //   commandbar: true,
  //   isLink: true
  // },
  {
    path: '/reauthenticate',
    name: 'Reauthenticate',
    component: Reauthenticate,
    layout: '/admin',
    sidebar: true,
    commandbar: true
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: 'ni ni-settings-gear-65',
    component: SettingsView,
    layout: '/admin',
    sidebar: true,
    header: true,
    commandbar: true
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: AlertsView,
    layout: '/admin',
    sidebar: true,
    header: true,
    commandbar: true,
    isLink: true,
    right: true
  },
  {
    path: '/connect',
    name: 'Connect',
    component: Connect,
    layout: '/admin',
    header: true,
    sidebar: true,
    commandbar: true
  },
  {
    path: '/team',
    name: 'Team',
    component: TeamView,
    layout: '/admin',
    header: true,
    sidebar: true,
    commandbar: true
  },
  {
    path: '/transact',
    name: 'Move money',
    component: Transact,
    layout: '/admin',
    sidebar: true,
    commandbar: true
  }
]

export default routes
