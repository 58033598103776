import React from 'react'
import { DropdownItem } from 'reactstrap'
import { currencyList } from '../../util'
import UniversalDropdown from './UniversalDropdown'

const CurrencyDropDown = props => {
  const displayCurrencyList = currencyList.filter(currency => currency.value !== props.selectedCurrency)
  return (
    <UniversalDropdown
      menuSize='sm'
      toggleText={props.selectedCurrency}
    >
      {displayCurrencyList.map(currency => {
        return (
          <DropdownItem
            key={currency.value}
            onClick={() => props.setSelectedCurrency(currency)}
          >{currency.text}
          </DropdownItem>
        )
      })}
    </UniversalDropdown>
  )
}

export default CurrencyDropDown
