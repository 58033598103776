import React, { useRef, useState } from 'react'
import ThirdButton from 'components/Buttons/ThirdButton'
import { FileUploadSection } from 'components/FileUpload/FileUploadSection'

export const ReceiptUpload = ({ transactionId, uploadTransactionReceipts, setTransactionDetails }) => {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [isDragging, setDragging] = useState(false)

  const inputRef = useRef()

  const triggerInput = fileType => {
    inputRef.current.click()
  }

  const handleDragDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    setFiles([...e.dataTransfer.files])
    setDragging(false)
  }

  const handleOnDragOver = e => {
    e.preventDefault()
    setDragging(true)
  }

  const handleFileUpload = e => {
    e.preventDefault()
    e.stopPropagation()
    setFiles([...e.target.files])
  }

  const upload = async () => {
    setLoading(true)
    const fd = new window.FormData()
    for (let i = 0; i < files.length; i++) {
      fd.append('receipts', files[i])
    }
    fd.append('transactionId', transactionId)
    await uploadTransactionReceipts(fd)
    setFiles([])
    setLoading(false)
  }

  return (
    <>
      <form>
        <FileUploadSection
          isDragging={isDragging}
          fileText={files.length ? `${files.length} receipts uploaded` : 'Upload receipts'}
          typeSupported='We accept PDF, JPG & PNG files (Max 5MB)'
          setDragging={setDragging}
          handleDragDrop={handleDragDrop}
          handleOnDragOver={handleOnDragOver}
          triggerInput={triggerInput}
          small
          file={files.length}
        />
        <input
          style={{ display: 'none' }}
          type='file'
          name='receiptFile'
          multiple
          onChange={handleFileUpload}
          ref={inputRef}
        />
      </form>
      <div className='mt-2'>
        <ThirdButton
          handler={upload}
          text='Upload receipt'
          loading={loading}
        />
      </div>
    </>
  )
}
