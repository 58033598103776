import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized'

const SectionList = props => {
  const [state, setState] = useState(0)

  useEffect(() => {
    setState(_rowCount(props))
  }, [props])

  const _rowCount = (props) => {
    const rowCount = props.sections.reduce((v, section) => {
      return v + section.data.length + 1 // Add one for the section header.
    }, 0)

    return rowCount
  }

  const _rowHeight = ({ index }) => {
    const info = subExtractor(props.sections, index)
    if (!info) return 0

    if (info.header) {
      if (props.sectionHeaderRenderer) {
        if (props.sectionHeaderHeight) {
          return +props.sectionHeaderHeight
        }
      } else {
        return 0
      }
    }

    return +props.rowHeight
  }

  const _renderItem = ({ key, index, isScrolling, isVisible, style, parent }) => {
    const info = subExtractor(props.sections, index)

    if (info) {
      if (info.header) {
        if (props.sectionHeaderRenderer) {
          return props.sectionHeaderRenderer({
            key,
            title: info.section.title || null,
            sectionIndex: info.sectionIndex,
            isScrolling,
            isVisible,
            parent,
            style
          })
        }
      } else {
        return props.rowRenderer({
          key,
          item: info.section.data[info.index],
          sectionIndex: info.sectionIndex,
          rowIndex: info.index,
          isScrolling,
          isVisible,
          parent,
          style
        })
      }
    }

    return null
  }

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => {
        return props.paginatedTransactions.noTxs || index < props.paginatedTransactions.txs.length
      }}
      loadMoreRows={props.paginatedTransactions.loading ? () => {} : props.handleTransactionsFetch}
      rowCount={props.paginatedTransactions.txs.length}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ height, width }) => (
            <ArrowKeyStepper
              columnCount={1}
              rowCount={state}
            >
              {({ onSectionRendered, scrollToRow }) => (
                <List
                  width={width}
                  height={height}
                  ref={registerChild}
                  onRowsRendered={({ overscanStartIndex, overscanStopIndex, startIndex, stopIndex }) => {
                    onRowsRendered({ overscanStartIndex, overscanStopIndex, startIndex, stopIndex })
                    onSectionRendered({ rowStartIndex: startIndex, rowStopIndex: stopIndex })
                  }}
                  scrollToIndex={scrollToRow}
                  rowHeight={_rowHeight}
                  rowRenderer={_renderItem}
                  rowCount={state}
                />
              )}
            </ArrowKeyStepper>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  )
}

const propMap = state => ({
  accounts: state.accounts.accounts,
  paginatedTransactions: state.paginatedTransactions
})

export default connect(propMap)(SectionList)

function subExtractor (sections = [], index) {
  let itemIndex = index
  for (let ii = 0; ii < sections.length; ii++) {
    const section = sections[ii]
    itemIndex -= 1 // The section adds an item for the header
    if (itemIndex >= section.data.length) {
      itemIndex -= section.data.length
    } else if (itemIndex === -1) {
      return {
        section,
        sectionIndex: ii,
        index: null,
        header: true
      }
    } else {
      return {
        section,
        sectionIndex: ii,
        index: itemIndex,
        header: false
      }
    }
  }
};

const ArrowKeyStepper = (props) => {
  const [state, setState] = useState({
    scrollToColumn: 0,
    scrollToRow: 0,
    instanceProps: {
      prevScrollToColumn: 0,
      prevScrollToRow: 0
    },
    rowStartIndex: 0,
    rowStopIndex: 0
  })

  const onKeyDown = (event) => {
    const { rowCount } = props

    const {
      scrollToRow: scrollToRowPrevious
    } = state

    let { scrollToRow } = state

    // The above cases all prevent default event event behavior.
    // This is to keep the grid from scrolling after the snap-to update.
    switch (event.key) {
      case 'ArrowDown':
      case 'k':
        scrollToRow = Math.min(state.rowStopIndex + 1, rowCount - 1)
        break
      case 'ArrowUp':
      case 'j':
        scrollToRow = Math.max(state.rowStartIndex - 1, 0)
        break
    }

    if (scrollToRow !== scrollToRowPrevious) {
      event.preventDefault()
      setState({ ...state, scrollToRow })
    }
  }

  const onSectionRendered = ({
    rowStartIndex,
    rowStopIndex
  }) => {
    setState({ ...state, rowStartIndex: rowStartIndex, rowStopIndex: rowStopIndex })
  }

  const { className, children } = props
  const { scrollToRow } = state

  return (
    <div className={className} onKeyDown={onKeyDown}>
      {children({
        onSectionRendered: onSectionRendered,
        scrollToRow
      })}
    </div>
  )
}
