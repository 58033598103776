import {
  GET_SETTINGS,
  UPDATE_SETTINGS
} from '../actions/constants'

const initState = {
  companySettings: null,
  profile: null,
  apiTokens: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_SETTINGS:
    case UPDATE_SETTINGS:
      return {
        ...action.payload
      }
    default:
      return state
  }
}
