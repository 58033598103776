import React from 'react'
import { Col, Row } from 'reactstrap'
import bell from '../../assets/icons/bell.svg'
import { getDeliveryMethodString } from './helper'

const AlertRow = ({ alert, selectAlert, accounts, deliveryMethods }) => {
  const alertAccount = alert.accounts.find(e => e === 'any')
    ? ' any account ' : alert.accounts.map(e => {
        const account = accounts.find(a => a.id.toString() === e)
        return account ? account.name : 'deleted account'
      })

  const getAlertAccount = () => {
    const base = { name: 'Any account', currency: 'USD' }
    if (alert.accounts.find(a => a === 'any')) {
      return base
    } else {
      const [acc] = alert.accounts.map(accId => {
        const match = accounts.find(acc => acc.id === accId)
        return match
      })
      if (acc) {
        return {
          currency: acc.iso_currency_code,
          name: acc.name
        }
      } else {
        base.name = 'Deleted account'
        return base
      }
    }
  }

  const alertAcc = getAlertAccount()

  return (
    <Row
      className='alert-row'
      onClick={() => selectAlert(alert)}
    >
      <Col md='8'>
        <div className='alert-icon'>
          <img src={bell} className='bell-icon' style={{ verticalAlign: 'unset' }} />
        </div>
        <div className='alert-meta'>
          <span className='alert-text'>
            {alert.amount === null && ' Any amount'}
            {alert.operator === 'gt' && ' More than '}
            {alert.operator === 'lt' && ' Less than '}
            {alert.operator === 'eq' && ' Exactly '}
            {alert.amount && `$${alert.amount}`}
            {alert.fund_movement_type === 'credit' && ' arrives in '}
            {alert.fund_movement_type === 'debit' && ' leaves '}
            {alert.fund_movement_type === 'any' && ' arrives or leaves '}
          </span>
          <span className='deliver-method-text text-gray'>
            via {' '}{alert.dm.map((dm, idx) => (
            getDeliveryMethodString(dm, idx)
          ))}
          </span>
        </div>
      </Col>
      <Col md='4'>
        <span className='alert-account'>
          {alertAcc.name}
        </span>
        <span className='account-currency text-gray'>
          {alertAcc.currency}
        </span>
      </Col>
    </Row>
  )
}

export default AlertRow
