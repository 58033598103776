import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import CustomInput from 'components/CustomInput'

const Api = props => {
  const [databaseHost, setDatabaseHost] = useState('')
  const [databasePort, setDatabasePort] = useState('')
  const [databaseName, setDatabaseName] = useState('')
  const [databaseUsername, setDatabaseUsername] = useState('')
  const [databasePassword, setDatabasePassword] = useState('')
  const [databaseRoleName, setDatabaseRoleName] = useState('')

  useEffect(() => {
    if (props.settings.apiTokens) {
      setDatabaseHost(props.settings.apiTokens.host)
      setDatabasePort(props.settings.apiTokens.port)
      setDatabaseName(props.settings.apiTokens.databaseName)
      setDatabaseUsername(props.settings.apiTokens.username)
      setDatabasePassword(props.settings.apiTokens.password)
      setDatabaseRoleName(props.settings.apiTokens.roleName)
    }
  }, [props.settings])

  return (
    <Row className='setting-row w-100'>
      <Col>
        <div className='setting-card'>
          <CustomInput
            value={databaseHost}
            name='host'
            labelName='Database host'
            type='text'
            disabled
          />
          <CustomInput
            value={databasePort}
            name='port'
            labelName='Database port'
            type='text'
            disabled
          />
          <CustomInput
            value={databaseName}
            name='databaseName'
            labelName='Database name'
            type='text'
            disabled
          />
          <CustomInput
            value={databaseUsername}
            name='username'
            labelName='Database username'
            type='text'
            disabled
          />
          <CustomInput
            value={databasePassword}
            name='databasePassword'
            labelName='Database password'
            type='text'
            disabled
          />
          <CustomInput
            value={databaseRoleName}
            name='databaseRoleName'
            labelName='Database role name'
            type='text'
            disabled
          />
        </div>
      </Col>
    </Row>
  )
}

const propMap = state => ({
  settings: state.settings
})

export default connect(propMap)(Api)
