import React, { useState, useEffect } from 'react'
import LegalEntities from './LegalEnties'
import LegalEntitySelect from './LegalEntitySelect'
import ConnectView from './ConnectView'
import { connect } from 'react-redux'
import { fetchLegalEntities } from '../../actions/entityActions'

const Connect = props => {
  const [selectedEntity, setLegalSelectedLegalEntity] = useState(false)

  useEffect(() => {
    props.fetchLegalEntities()
  }, [])

  return (
    <>
      <main>
        {selectedEntity
          ? (
            <div id='connect'>
              <div className='connect-side'>
                <LegalEntitySelect
                  selectedEntity={selectedEntity}
                  setLegalSelectedLegalEntity={setLegalSelectedLegalEntity}
                />
              </div>
              <div className='connect-main'>
                <ConnectView selectedEntity={selectedEntity} />
              </div>
            </div>)
          : (
            <LegalEntities
              setLegalSelectedLegalEntity={setLegalSelectedLegalEntity}
            />
            )}
      </main>
    </>
  )
}

const propMap = state => ({
  entities: state.entities
})

export default connect(propMap, { fetchLegalEntities })(Connect)
