import AuthAccounts from 'components/Reauth/AuthAccounts'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'

export const Reauthenticate = () => {
  return (
    <div
      style={{
        marginTop: '100px',
        backgroundColor: 'white',
        marginRight: '20px',
        marginLeft: '20px',
        height: '83vh',
        overflow: 'scroll'
      }}
    >
      <Container className='mt-5 mb-5' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5 mb-xl-0' xl='12'>
            <AuthAccounts />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Reauthenticate
