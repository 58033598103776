import React from 'react'
import { DropdownItem } from 'reactstrap'
import { rates } from '../../util'
import UniversalDropdown from './UniversalDropdown'

const ExchangeRateDropDown = props => {
  const displayRate = rates.filter(rate => rate.name !== props.selectedRate)

  return (
    <UniversalDropdown
      menuSize='sm'
      toggleText={props.selectedRate}
    >
      {displayRate.map(rate => {
        return (
          <DropdownItem
            key={rate.name}
            onClick={() => props.setSelectedExchangeRate(rate)}
          >{rate.name}
          </DropdownItem>
        )
      })}
    </UniversalDropdown>

  )
}

export default ExchangeRateDropDown
