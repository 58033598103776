export const yapilyInstitutions = [
  {
    name: 'Yapily Mock',
    id: 'yapily-mock',
    logo: 'https://images.yapily.com/image/4bbb9c1b-29e0-4cc9-8d01-b2ad2fcd57a3?size=0'
  },
  {
    name: 'Vanquis Bank',
    id: 'vanquis-bank-sandbox',
    logo: 'https://images.yapily.com/image/737f4a72-8a58-4a0f-b1d8-6c8ada636dbb?size=0'
  },
  {
    name: 'AIB',
    id: 'aibgb-sandbox',
    logo: 'https://images.yapily.com/image/415ee4ea-d008-426e-bd71-e50122028f5f?size=0'
  },
  {
    name: 'Bank of Scotland',
    id: 'bankofscotland-sandbox',
    logo: 'https://images.yapily.com/image/a3dd4033-9179-4cb5-b2fa-2a7e25f43b46?size=0'
  },
  {
    name: 'Barclays',
    id: 'barclays-sandbox',
    logo: 'https://images.yapily.com/image/708a0d86-27f1-4c54-b2f4-0a75c92bea3b?size=0'
  },
  {
    name: 'Tesco Bank',
    id: 'tescobank-sandbox',
    logo: 'https://images.yapily.com/image/e4d5d9cd-7d8e-4319-ac35-b4e7a2c78ab5?size=0'
  },
  {
    name: "Sainsbury's Bank",
    id: 'sainsburysbank-sandbox',
    logo: 'https://images.yapily.com/image/36b39c27-1fe5-4ebc-9e60-7bbf991b5723?size=0'
  },
  {
    name: 'Royal Bank of Scotland',
    id: 'rbs-sandbox',
    logo: 'https://images.yapily.com/image/33543712-4ef8-49af-9836-dd8aadfb478a?size=0'
  },
  {
    name: 'Natwest Bank',
    id: 'natwest-sandbox',
    logo: 'https://images.yapily.com/image/4c28e551-1a56-4631-be2c-260105ba687b?size=0'
  },
  {
    name: 'Modelo Bank',
    id: 'modelo-sandbox',
    logo: 'https://images.yapily.com/image/ce2bfdbf-1ae2-4919-ab7b-e8b3d5e93b36?size=0'
  },
  {
    name: 'Lloyds Bank',
    id: 'lloyds-sandbox',
    logo: 'https://images.yapily.com/image/170bd6f9-4716-43d7-b9cc-af19d606857c?size=0'
  },
  {
    name: 'Cumberland Building Society Bank',
    id: 'cumberlandbuildingsociety-sandbox',
    logo: 'https://images.yapily.com/image/2b197304-e12f-4be7-b0b5-039ae57a9719?size=0'
  },
  {
    name: 'Fineco Bank',
    id: 'fineco-sandbox',
    logo: 'https://images.yapily.com/image/25f27b84-71a8-4b8d-838f-46407fa0ada9?size=0'
  }
]

export const ebInstitutions = [
  {
    country: 'FI',
    title: 'S Pankki',
    name: 'S-Pankki',
    logo: 'https://media.glassdoor.com/sqll/10384/danske-bank-squarelogo-1501506268661.png'
  },
  {
    country: 'EE',
    title: 'LHV Bank',
    name: 'LHV Pank',
    logo: 'https://s3.eu-central-1.amazonaws.com/alphablues/assets/lhv/lhv-logo.png'
  },
  {
    country: 'FI',
    title: 'Nordea',
    name: 'Nordea',
    logo: 'https://lh3.googleusercontent.com/bZXq_zYYlygtvZ20XsWKU2aM8Ok2iu2y6N1dBGNpuW-DPpxqLGFWdQu9z2g08tLiD3av'
  }
]
