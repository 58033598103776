import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { updateProfileSettings } from 'actions/settingsActions'
import CustomInput from 'components/CustomInput'
import PrimaryButton from 'components/Buttons/PrimaryButton'

const Profile = props => {
  const [saveButtonLoading, setSaveButtonLoading] = useState(false)
  const [firstNameValue, setFirstNameValue] = useState('')
  const [lastNameValue, setLastNameValue] = useState('')

  useEffect(() => {
    setFirstNameValue(props.user.attributes.given_name)
    setLastNameValue(props.user.attributes.family_name)
  }, [props.user])

  const handleSave = async () => {
    setSaveButtonLoading(true)
    await props.updateProfileSettings({
      firstName: firstNameValue,
      lastName: lastNameValue
    })
    setSaveButtonLoading(false)
  }

  return (
    <Row className='setting-row w-100'>
      <Col>
        <div className='setting-card'>
          <CustomInput
            name='firstName'
            labelName='First Name'
            type='text'
            value={firstNameValue}
            onChange={e => setFirstNameValue(e.target.value)}
          />
          <CustomInput
            name='lastName'
            labelName='Last Name'
            type='text'
            value={lastNameValue}
            onChange={e => setLastNameValue(e.target.value)}
          />
          <div className='mt-3 text-right'>
            <PrimaryButton
              text='Save Details'
              loading={saveButtonLoading}
              handler={() => handleSave()}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

const propMap = state => ({
  user: state.auth.user
})

export default connect(propMap, { updateProfileSettings })(Profile)
