import React from 'react'
import { DropdownItem } from 'reactstrap'
import UniversalDropdown from './UniversalDropdown'

const AccountDropDown = props => {
  const allAccountsTextToDisplay = props.allAccountsTextToDisplay || 'all accounts'
  const isAllAccounts = !props.selectedAccount
  const selectedAccountName = !isAllAccounts && props.accounts.find(acc => acc.id === props.selectedAccount).name

  const accountsList = props.selectedAccount
    ? props.accounts.filter(account => account.id !== props.selectedAccount)
    : props.accounts

  return (
    <UniversalDropdown
      menuSize='sm'
      toggleText={isAllAccounts ? allAccountsTextToDisplay : selectedAccountName}
    >
      {!isAllAccounts && (
        <DropdownItem
          key={0}
          onClick={() => props.setSelectedAccount(null)}
        >All accounts
        </DropdownItem>
      )}
      {accountsList.map(account => {
        return (
          <DropdownItem
            key={account.id}
            onClick={() => props.setSelectedAccount(account.id)}
          >{account.name}
          </DropdownItem>
        )
      })}
    </UniversalDropdown>
  )
}

export default AccountDropDown
