import { AccountSelect } from 'components/Transact/AccountSelect'
import React, { useState, useEffect } from 'react'
import { Button, Col, Row } from 'reactstrap'
import bell from '../../assets/icons/bell.svg'
import { getDeliveryMethodString } from './helper'

const AlertDetails = ({ selectedAlert: alert, updateAlertAccount, accounts, deleteAlert }) => {
  const [acc, setAcc] = useState(null)
  const getAlertAccount = () => {
    const base = { name: 'Any account', id: null, currency: 'USD' }
    if (alert.accounts.find(a => a === 'any')) {
      return base
    } else {
      const [acc] = alert.accounts.map(accId => {
        const match = accounts.find(acc => acc.id === accId)
        return match
      })
      if (acc) {
        return acc
      } else {
        base.name = 'Deleted account'
        return base
      }
    }
  }

  useEffect(() => {
    const temp = getAlertAccount()
    setAcc(temp)
  }, [])

  useEffect(() => {
    const temp = getAlertAccount()
    setAcc(temp)
  }, [alert])

  const selectAlertAccount = async account => {
    if (account && account.id) {
      updateAlertAccount({ accountId: account.id, alertId: alert.id })
    }
  }

  if (!alert && !alert.id) {
    return <div />
  }

  return (
    <div className='alert-sidebar'>
      <Row>
        <Col xs='12'>
          <div className='alert-icon'>
            <img src={bell} className='bell-icon' style={{ verticalAlign: 'unset' }} />
          </div>
        </Col>
        <Col xs='12' className='mt-2'>
          <h2>
            Notify me by
            <span className='blue'>
              {' '} {alert.dm.map((dm, idx) => getDeliveryMethodString(dm, idx))}
            </span>{' '}
            when
            {alert.amount === null && ' any amount'}
            {alert.operator === 'gt' && ' more than '}
            {alert.operator === 'lt' && ' less than '}
            {alert.operator === 'eq' && ' exactly '}
            {alert.amount && `$${alert.amount}`}
            {alert.fund_movement_type === 'credit' && ' arrives in '}
            {alert.fund_movement_type === 'debit' && ' leaves '}
            {alert.fund_movement_type === 'any' && ' arrives or leaves '}
            <span className='blue'>
              {acc && acc.name ? acc.name : ''}
            </span>
          </h2>
        </Col>
        {
        acc
          ? (
            <Col xs='12' className='mt-4'>
              <AccountSelect
                setAccount={selectAlertAccount}
                accounts={accounts}
                selectedAccount={acc}
              />
            </Col>
            )
          : <div />
          }
        <Col xs='12' className='mt-4'>
          <Button
            className='delete-alert-btn'
            onClick={() => deleteAlert(alert.id)}
          >
            Remove Alert
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default AlertDetails
