import React from 'react'
import { Col, Input, Row } from 'reactstrap'
import { CategorySelect } from './CategorySelect'

export const TransactionSpecifics = ({ fields, setFields }) => {
  const setFieldValue = (fieldName, fieldValue) => {
    const copy = { ...fields }
    copy[fieldName] = fieldValue
    setFields(copy)
  }

  const setPurposeField = newSelected => {
    const base = {
      employee: { selected: false, text: 'employee' },
      contractor: { selected: false, text: 'contractor' },
      vendor: { selected: false, text: 'vendor' },
      intra: { selected: false, text: 'Intra-company' },
      landlord: { selected: false, text: 'landlord' },
      other: { selected: false, text: 'other' }
    }
    base[newSelected].selected = true
    setFieldValue('purpose', base)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFieldValue(name, value)
  }

  return (
    <div className='transact-wrapper'>
      <div className='purpose-select'>
        <h3 className='text-left'>Purpose</h3>
        <Row>
          {
            Object.keys(fields.purpose).map((key) => {
              const { selected, text } = fields.purpose[key]
              return (
                <Col xs='6' key={key}>
                  <div onClick={() => setPurposeField(key)} className={selected ? 'purpose-check selected' : 'purpose-check'}>
                    <span className={selected ? 'purpose-dot selected' : 'purpose-dot'} />
                    <span className='purpose-name'>{text}</span>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </div>
      <div className='mt-3 mb-3'>
        <h3 className='text-left'>Category</h3>
        <CategorySelect setCategory={setFieldValue} selectedCategory={fields.category} />
      </div>
      <div className='mt-3 mb-3'>
        <h3 className='text-left'>Note</h3>
        <Input name='note' type='textarea' style={{ height: '200px', width: '100%', resize: 'none' }} placeholder='Add a note' onChange={e => handleChange(e)} />
      </div>
    </div>
  )
}
