import numeral from 'numeral'

export const sortByDate = data => {
  const sorted = data.sort((a, b) => new Date(a.date) - new Date(b.date))
  return sorted
}

export const addHttp = (url) => {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'http://' + url
  }
  return url
}

export const rates = [
  { name: 'live exchange rate', endpoint: 'balance_current_rate' },
  { name: 'historical exchange rate', endpoint: 'balance_historical_rate' }
]

export const currencyList = [
  {
    text: '$ USD',
    symbol: '$',
    front: true,
    value: 'USD',
    label: 'USD'
  },
  {
    text: '$ AUD',
    symbol: '$',
    value: 'AUD',
    label: 'AUD'
  },
  {
    text: '$ MXN',
    symbol: '$',
    value: 'MXN',
    label: 'MXN'
  },
  {
    text: '€ EUR',
    symbol: '€',
    value: 'EUR',
    label: 'EUR'
  },
  {
    text: '$ CAD',
    symbol: '$',
    value: 'CAD',
    label: 'CAD'
  },
  {
    text: '£ GBP',
    symbol: '£',
    front: true,
    value: 'GBP',
    label: 'GBP'
  }
]

export const timePeriods = [
  {
    value: 30,
    text: '30 days'
  },
  {
    value: 90,
    text: '3 months'
  },
  {
    value: 180,
    text: '6 months'
  },
  {
    value: 360,
    text: 'year'
  }
]

export const timeInterval = [
  {
    value: 'months',
    name: 'Monthly'
  },
  {
    value: 'weeks',
    name: 'Weekly'
  }
]

export const bankTransactionCategories = [
  {
    id: 0,
    category: 'Unknown'
  },
  {
    id: 1,
    category: 'Revenue'
  },
  {
    id: 2,
    category: 'Salaries'
  },
  {
    id: 3,
    category: 'Other Payroll'
  },
  {
    id: 4,
    category: 'Travel/Transportation'
  },
  {
    id: 5,
    category: 'Bank/Finance charges'
  },
  {
    id: 6,
    category: 'Other Expenses'
  },
  {
    id: 7,
    category: 'Taxes'
  },
  {
    id: 8,
    category: 'ATM'
  },
  {
    id: 9,
    category: 'Food & Grocery'
  },
  {
    id: 10,
    category: 'Gas Stations'
  },
  {
    id: 11,
    category: 'Hardware & Equipment'
  },
  {
    id: 12,
    category: 'Hotels & Lodging'
  },
  {
    id: 13,
    category: 'Insurance'
  },
  {
    id: 14,
    category: 'IT & Electronics'
  },
  {
    id: 15,
    category: 'Revenue'
  },
  {
    id: 16,
    category: 'Legal & Accounting'
  },
  {
    id: 17,
    category: 'Logistics'
  },
  {
    id: 18,
    category: 'Manufacturing'
  },
  {
    id: 19,
    category: 'Office Rental'
  },
  {
    id: 20,
    category: 'Office Supplies'
  },
  {
    id: 21,
    category: 'Online Services'
  },
  {
    id: 22,
    category: 'Other Services'
  },
  {
    id: 23,
    category: 'Restaurants & Bars'
  },
  {
    id: 24,
    category: 'Utilities'
  }
]

export const countries = [
  {
    fullName: 'UNITED STATE OF AMERICA',
    value: 'USA',
    label: 'United States'
  },
  {
    value: 'IRL',
    label: 'Ireland'
  },
  {
    value: 'GBR',
    label: 'Great Britain'
  },
  {
    value: 'CAN',
    label: 'Canada'
  },
  {
    value: 'AUS',
    label: 'Australia'
  },
  {
    value: 'ESP',
    label: 'Spain'
  },
  {
    fullName: 'FRANCE',
    value: 'FRA',
    label: 'France'
  },
  {
    value: 'MXN',
    label: 'Mexico'
  },
  {
    value: 'EST',
    label: 'Estonia'
  },
  {
    value: 'FIN',
    label: 'Finland'
  }
]

export const formatAmount = (amount) => {
  const numeralAmount = numeral(amount).format('0,0.00')
  const formatedAmount = numeralAmount.split('.')
  return {
    integerPart: formatedAmount[0],
    fractionalPart: formatedAmount[1]
  }
}

export const normalizeCountry = (countryName) => {
  switch (countryName.toLowerCase()) {
    case 'france':
    case 'fr':
      return {
        isoCode: 'FRA',
        ocCode: 'FR',
        name: 'France',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/195-france.svg'
      }
    case 'united states':
    case 'usa':
    case countryName.includes('us'):
    case 'us_':
      return {
        isoCode: 'USA',
        ocCode: 'US',
        name: 'United States',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/226-united-states.svg'
      }
    case 'ireland':
    case 'irl':
    case 'ie':
    case 'ie_':
      return {
        isoCode: 'IRL',
        ocCode: 'IE',
        name: 'Ireland',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/179-ireland.svg'
      }
    case 'united kingdom':
    case 'great britain':
    case 'uk':
    case 'gb':
    case 'gbr':
      return {
        isoCode: 'GBR',
        ocCode: 'UK',
        name: 'United Kingdom',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/260-united-kingdom.svg'
      }
    case 'canada':
    case 'ca':
      return {
        isoCode: 'CAN',
        ocCode: 'CA',
        name: 'Canada',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/243-canada.svg'
      }
    case 'mexico':
    case 'mx':
      return {
        isoCode: 'MEX',
        ocCode: 'MX',
        name: 'Mexico',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/252-mexico.svg'
      }
    case 'estonia':
    case 'ee':
      return {
        isoCode: 'EST',
        ocCode: 'EE',
        name: 'Estonia',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/008-estonia.svg'
      }
    case 'finland':
    case 'fi':
      return {
        isoCode: 'FIN',
        ocCode: 'FI',
        name: 'Finland',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/125-finland.svg'
      }
    case 'spain':
    case 'es':
      return {
        isoCode: 'ESP',
        ocCode: 'ES',
        name: 'Spain',
        flagUrl: 'https://flags-image.s3.amazonaws.com/svg/128-spain.svg'
      }
    default:
      return null
  }
}
