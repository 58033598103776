import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Mentions from 'rc-mentions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faFile } from '@fortawesome/free-solid-svg-icons'
import { Input } from 'reactstrap'
import { downloadRequest } from 'API/ApiClient'
import ThirdButton from 'components/Buttons/ThirdButton'
import { CategorySelect } from 'components/Transact/CategorySelect'
import { ReceiptUpload } from 'components/Transactions/ReceiptUpload'
import {
  setTransactionCategory,
  uploadTransactionReceipts,
  setTransactionNotes
} from 'actions/paginateTransactions'

import {
  SendBirdProvider,
  Channel,
  withSendBird, sendBirdSelectors
} from 'sendbird-uikit'
import 'sendbird-uikit/dist/index.css'
import './index.css'

const APP_ID = '8C6F3CE7-1B6B-43A6-882D-64A0F6624FEA'

const TransactionDetails = props => {
  const [noteField, setNoteField] = useState('')
  const [noteLoading, setNoteLoading] = useState(false)

  const {
    companyId,
    txId,
    amount,
    date,
    currency,
    logoURL,
    merchantWebsite,
    category,
    isLogo,
    bankName,
    notes,
    name,
    receiptFileName
  } = props.txDetails

  const CustomInput = ({ channel, sendMessage, sdk, disabled }) => {
    const [messageInputValue, setMessageInputValue] = useState('')
    const [selectValue, setSelectValue] = useState([])

    const submitInput = () => {
      const params = new sdk.UserMessageParams()

      const mentionedUserIds = selectValue.map(user => user.value)
      const mentionedUsers = selectValue.map(user => user.key)

      params.message = messageInputValue
      params.mentioned_user_ids = mentionedUserIds
      params.data = JSON.stringify({
        companyId,
        mentionedUsers,
        transaction: {
          name,
          amount,
          currency,
          date,
          bankName
        }
      })

      sendMessage(channel.url, params)
      setMessageInputValue('')
      setSelectValue([])
    }

    const { Option } = Mentions

    return (
      <>
        <Mentions
          disabled={disabled}
          autoSize
          style={{ width: '100%' }}
          value={messageInputValue}
          onPressEnter={submitInput}
          onSelect={value => setSelectValue([...selectValue, value])}
          onChange={value => setMessageInputValue(value)}
        >
          {props.users.map(user => {
            return <Option key={user.sub} value={user.name}>{user.name}</Option>
          })}
        </Mentions>
      </>
    )
  }

  const CustomInputWithSendbird = withSendBird(CustomInput, (state) => {
    const sendMessage = sendBirdSelectors.getSendUserMessage(state)
    const sdk = sendBirdSelectors.getSdk(state)
    return {
      sendMessage,
      sdk
    }
  })

  const handleCategoryChange = async (userCategory) => {
    const updatedTx = await props.setTransactionCategory({ id: txId, userCategory })
    props.setTransactionDetails(updatedTx)
  }

  useEffect(() => {
    setNoteField(notes || '')
  }, [notes])

  let transformStyle = 'rotate(180deg)'
  if (isLogo) {
    transformStyle = ''
  }

  const spanStyle = { color: '#007AFF', fontWeight: '600' }

  const addTxNote = async () => {
    setNoteLoading(true)
    await props.setTransactionNotes({
      notes: noteField,
      transactionId: txId
    })
    setNoteLoading(false)
  }

  const downloadReceipt = async fileName => {
    const res = await downloadRequest({ path: `/api/transactions/receipt/${fileName}` })
    const url = window.URL.createObjectURL(new window.Blob([res.data]
      , { type: 'application/pdf' }))
    window.open(url, '_blank')
  }

  return (
    <div className='tx-details-card'>
      {txId &&
        (
          <div id='transactions-details'>
            <div id='transactions-details-header' className='tx-details-header'>
              <img
                className='tx-details-header-image'
                src={logoURL}
                style={amount > 0 ? {} : { transform: transformStyle }}
              />
              <span className='tx-details-header-merchant-name'>
                {name}
              </span>
              {merchantWebsite &&
                <a
                  className='tx-details-header-website'
                  href={merchantWebsite}
                  target='_blank'
                  rel='noreferrer'
                >{merchantWebsite.replace(/(^\w+:|^)\/\//, '')}
                </a>}
              <div className='tx-details-header-category'>
                <CategorySelect
                  setCategory={handleCategoryChange}
                  selectedCategory={category}
                />
              </div>
            </div>
            <div className='tx-details-amount'>
              <span style={spanStyle}>{currency}</span> {amount.toFixed(2).toLocaleString()}
            </div>
            <div className='tx-details-paid-on'>
              <span className='d-block text-gray'>Paid on {bankName}</span>
              <span className='d-block text-gray mb-3'>The {moment(date).format('ddd, D MMMM, YYYY')}</span>

              <span className='d-block text-gray'>
                {/* <FontAwesomeIcon
                  className='tx-detail-icon-prev'
                  size='sm'
                  icon={faUndo}
                /> */}
                {/* {previous} previous props.transactions in the last <span style={spanStyle}>{props.timePeriod}</span> */}
              </span>

              <span className='d-block text-gray'>
                {/* <FontAwesomeIcon
                  className='tx-detail-icon-chart'
                  size='sm'
                  icon={faChartPie}
                /> */}
                {/* {spend} <span style={spanStyle}>{currency}</span> spent in the last <span style={spanStyle}>{props.timePeriod}</span> */}
              </span>
            </div>
            <div className='tx-details-note'>
              <Input
                type='textarea'
                height='100'
                resize='none'
                value={noteField}
                style={{ width: '100%', borderRadius: 0 }}
                onChange={e => setNoteField(e.target.value)}
              />
              <div className='mt-2'>
                <ThirdButton
                  handler={addTxNote}
                  text='Add a note'
                  loading={noteLoading}
                />
              </div>
            </div>
            <div className='tx-details-receipts mt-4'>
              {receiptFileName && (
                <div>
                  <h5>Receipts</h5>
                  <div className='receipt clickable'>
                    <FontAwesomeIcon icon={faFile} size='sm' />
                    <span className='receipt-name text-gray'>
                      {receiptFileName}
                    </span>
                    <FontAwesomeIcon
                      icon={faEye}
                      size='sm'
                      onClick={() => downloadReceipt(receiptFileName)}
                    />
                  </div>
                </div>
              )}
              <ReceiptUpload
                transactionId={txId}
                uploadTransactionReceipts={props.uploadTransactionReceipts}
                setTransactionDetails={props.setTransactionDetails}
              />
            </div>
            <div className='tx-details-chat'>
              <SendBirdProvider appId={APP_ID} userId='Mokhtar Bacha'>
                <Channel
                  style={{ backgroundColor: 'none', border: 'none' }}
                  renderChatHeader={() => null}
                  channelUrl={txId}
                  renderMessageInput={CustomInputWithSendbird}
                />
              </SendBirdProvider>
            </div>
          </div>)}
    </div>
  )
}

const propMap = state => ({
  users: state.company.users,
  user: state.auth.user.attributes,
  transactions: state.transactions
})

export default connect(propMap, {
  uploadTransactionReceipts,
  setTransactionNotes,
  setTransactionCategory
})(TransactionDetails)
