
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  Col
} from 'reactstrap'
import { usePlaidLink } from 'react-plaid-link'
import { fileUpload } from 'API/ApiClient'
import { fetchPlaidLinkToken, onPlaidSuccess, onPlaidExit, openBelvoWidget, openSaltEdge, openEb, openYapily, openBudgetInsight, openShopify } from '../../services/connect'

import YapilyModal from 'components/Signup/YapilyModal'
import EbModal from 'components/Signup/EbModal'
import ShopifyModal from './ShopifyModal'
import ConnectCard from 'components/Cards/ConnectCard'
import FileUploadModal from 'components/Signup/FileUploadModal'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { showError } from 'actions/errorActions'
import UploadIcon from '../../assets/icons/upload.png'
import StripeLogo from '../../assets/logos/stripe.png'
import PaypalLogo from '../../assets/logos/paypal.png'
import ShopifyLogo from '../../assets/logos/shopify.png'
import PayfitLogo from '../../assets/logos/payfit.png'
import GustoLogo from '../../assets/logos/gusto.png'
import RipplingLogo from '../../assets/logos/rippling.png'
import Zenefits from '../../assets/logos/zenfits.png'
import Freshbooks from '../../assets/logos/freshbooks.png'
import QuickBooks from '../../assets/logos/quickbooks.jpg'
import Xero from '../../assets/logos/xero.png'
import NetSuite from '../../assets/logos/netsuite.png'

export const ConnectView = ({ showError, selectedEntity }) => {
  const [plaidToken, setPlaidToken] = useState('')
  const [yapilyModal, setYapilyModal] = useState(false)
  const [ebModal, setEbModal] = useState(false)
  const [country, setCountry] = useState('')
  const [ebLoading, setEbLoading] = useState(false)
  const [selectedEbInstitution, selectEbInstitution] = useState(null)
  const [yapilyLoading, setYapilyLoading] = useState(false)
  const [selectedYapilyInst, setSelectedYapilyInst] = useState('')
  const [shopname, setShopName] = useState({
    name: 'shopname',
    labelName: 'Shopify store name',
    placeholder: 'Enter the name of your Shopify store',
    type: 'text',
    value: ''
  })
  const [shopLoading, toggleShopLoading] = useState(false)
  const [shopModal, setShopModal] = useState(false)
  const [fileModalOpen, setFileModalOpen] = useState(false)

  useEffect(() => {
    fetchPlaidLinkToken(setPlaidToken)
  }, [])

  const { open: openPlaid, error } = usePlaidLink({ token: plaidToken, onSuccess: onPlaidSuccess, onExit: onPlaidExit })
  if (error) {
    showError(error)
  }

  const getYapilyAuthData = async () => {
    if (selectedYapilyInst) {
      setYapilyLoading(true)
      await openYapily()
    }
  }

  const yapilyModalToggle = () => {
    setYapilyModal(!yapilyModal)
  }

  const ebModalToggle = (country = '') => {
    setCountry(country)
    setEbModal(!ebModal)
  }

  const startEbAuth = async () => {
    setEbLoading(true)
    try {
      await openEb(country, selectedEbInstitution)
    } catch (err) {
      setEbLoading(false)
    }
  }

  const selectYapilyInstitution = val => {
    if (!yapilyLoading) {
      setSelectedYapilyInst(val)
    }
  }

  const connectShopify = async () => {
    toggleShopLoading(true)
    try {
      await openShopify(shopname.value)
    } catch (err) {
      console.log(err)
    }
  }

  const handleConnectAccount = () => {
    const countryName = selectedEntity.country
    setCountry(country)
    switch (countryName) {
      case 'FRA':
        openBudgetInsight()
        break
      case 'USA':
        openPlaid()
        break
      case 'GBR':
        yapilyModalToggle()
        break
      case 'CAN':
        openPlaid()
        break
      case 'MEX':
        openBelvoWidget('MX')
        break
      case 'BRL':
        openBelvoWidget('BR')
        break
      case 'COL':
        openBelvoWidget('CO')
        break
      case 'EST':
        ebModalToggle('EE')
        break
      case 'FIN':
        ebModalToggle('FIN')
        break
      case 'ESP':
        openSaltEdge('ES')
        break
      case 'IRL':
        console.log('ireland')
        openSaltEdge('IE')
        break
      default:
        window.alert('Country not supported yet!')
    }
  }

  const handleUpload = async data => {
    const { json } = await fileUpload({ path: '/api/transactions/file/upload', body: data })
    return json
  }

  return (
    <div id='connect-view'>
      <div className='connect-view-banks'>
        <h1 className='mb-3 text-dark'>Connect a bank account</h1>
        <div id='belvo' />
        <Row>
          <Col xs='12'>
            <PrimaryButton
              text='Connect Account'
              handler={handleConnectAccount}
            />
          </Col>
        </Row>
        <YapilyModal
          yapilyModal={yapilyModal}
          loading={yapilyLoading}
          yapilyModalToggle={yapilyModalToggle}
          selectedYapilyInst={selectedYapilyInst}
          selectYapilyInstitution={selectYapilyInstitution}
          getYapilyAuthData={getYapilyAuthData}
        />
        <EbModal
          loading={ebLoading}
          country={country}
          ebModalToggle={ebModalToggle}
          ebModal={ebModal}
          selectedEbInstitution={selectedEbInstitution}
          selectEbInstitution={selectEbInstitution}
          startEbAuth={startEbAuth}
        />
        <ShopifyModal
          setShopName={(e) => {
            const { value } = e.target
            setShopName({ ...shopname, value })
          }}
          loading={shopLoading}
          shopname={shopname}
          toggle={() => setShopModal(!shopModal)}
          isOpen={shopModal}
          handleSave={connectShopify}
        />
        <FileUploadModal
          isOpen={fileModalOpen}
          toggle={setFileModalOpen}
          upload={handleUpload}
        />
      </div>
      <div className='connect-view-payments'>
        <h1 className='mb-3 text-dark'>Connect a payment processor</h1>
        <div className='connect-row'>
          <div className='connect-row-element-0'>
            <ConnectCard logo={StripeLogo} handler={() => window.alert('Coming soon!')} />
          </div>
          <div className='connect-row-element-1'>
            <ConnectCard logo={PaypalLogo} handler={() => window.alert('Coming soon!')} />
          </div>
          <div className='connect-row-element-2'>
            <ConnectCard logo={ShopifyLogo} handler={() => setShopModal(!shopModal)} />
          </div>
        </div>
      </div>
      <div className='connect-view-payroll'>
        <h1 className='mb-3 text-dark'>Connect a payroll software</h1>
        <div className='connect-row'>
          <div className='connect-row-element-0'>
            <ConnectCard logo={PayfitLogo} handler={() => window.alert('Coming soon!')} />
          </div>
          <div className='connect-row-element-1'>
            <ConnectCard width='100px' logo={GustoLogo} handler={() => window.alert('Coming soon!')} />
          </div>
          <div className='connect-row-element-2'>
            <ConnectCard logo={RipplingLogo} handler={() => window.alert('Coming soon!')} />
          </div>
          <div className='connect-row-element-3'>
            <ConnectCard logo={Zenefits} handler={() => window.alert('Coming soon!')} />
          </div>
        </div>
      </div>
      <div className='connect-view-accounting'>
        <h1 className='mb-3 text-dark'>Connect an accounting software</h1>
        <div className='connect-row'>
          <div className='connect-row-element-0'>
            <ConnectCard logo={QuickBooks} handler={() => window.alert('Coming soon!')} />
          </div>
          <div className='connect-row-element-1'>
            <ConnectCard logo={Xero} handler={() => window.alert('Coming soon!')} />
          </div>
          <div className='connect-row-element-2'>
            <ConnectCard width='100px' logo={Freshbooks} handler={() => window.alert('Coming soon!')} />
          </div>
          <div className='connect-row-element-3'>
            <ConnectCard width='100px' logo={NetSuite} handler={() => window.alert('Coming soon!')} />
          </div>
        </div>
      </div>
      <div className='connect-view-upload'>
        <h1 className='mb-3 text-dark'>Upload transaction data</h1>
        <div className='connect-row mb-5'>
          <div className='connect-row-element-0'>
            <ConnectCard width='100px' logo={UploadIcon} handler={() => setFileModalOpen(true)} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(undefined, { showError })(ConnectView)
