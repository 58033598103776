import { deleteRequest, putRequest, postRequest, getRequest } from 'API/ApiClient'

import { GET_ALERTS, UPDATE_ALERTS, ALERTS_LOADING, INITIALIZE_STATES } from './constants'
import { showError } from './errorActions'

export const fetchAlerts = () => async (dispatch) => {
  dispatch({ type: ALERTS_LOADING })
  return getRequest({ path: '/api/alerts/list' }).then(res => {
    dispatch({ type: GET_ALERTS, payload: { alerts: res.json.alerts } })
    return res.json
  }).catch(err => {
    showError(err.msg)(dispatch)
    dispatch({ type: GET_ALERTS, payload: { alerts: [] } })
  })
}

export const fetchDeliveryMethods = () => async (dispatch, getState) => {
  return getRequest({ path: '/api/alerts/delivery-methods/list' }).then(res => {
    const prev = getState()
    const payload = {
      ...prev.alerts,
      deliveryMethods: { ...res.json.deliveryMethods }
    }
    dispatch({ type: UPDATE_ALERTS, payload })
    return res.json
  }).catch(err => {
    showError(err.msg)(dispatch)
    dispatch({ type: UPDATE_ALERTS, payload: {} })
  })
}

export const updateAlertAccount = body => async (dispatch, getState) => {
  const { alerts } = getState()
  return putRequest({ path: '/api/alerts', body }).then(res => {
    const idx = alerts.alerts.findIndex(({ id }) => id === body.alertId)
    const { alert } = res.json
    alerts.alerts.splice(idx, 1, alert)
    dispatch({ type: UPDATE_ALERTS, payload: alerts })
  }).catch(err => {
    showError(err.msg || 'Failed to create delivery method')(dispatch)
    dispatch({ type: UPDATE_ALERTS, payload: alerts })
  })
}

export const createDeliveryMethod = (type, body) => async (dispatch, getState) => {
  const { alerts } = getState()
  return postRequest({ path: '/api/alerts/delivery-method', body }).then(res => {
    const payload = {
      ...alerts,
      deliveryMethods: { ...res.json.deliveryMethods }
    }
    dispatch({ type: UPDATE_ALERTS, payload })
  }).catch(err => {
    showError(err.msg || 'Failed to create delivery method')(dispatch)
    dispatch({ type: UPDATE_ALERTS, payload: alerts })
  })
}

export const deleteAlert = id => async (dispatch, getState) => {
  const { alerts: payload } = getState()
  return deleteRequest({ path: `/api/alerts/${id}` }).then(res => {
    payload.alerts = payload.alerts.filter(({ id: alertId }) => alertId !== id)
    dispatch({ type: UPDATE_ALERTS, payload })
    return payload
  }).catch(err => {
    showError(err.msg || 'Failed to create delivery method')(dispatch)
    dispatch({ type: UPDATE_ALERTS, payload: payload })
  })
}

export const createAlert = body => async (dispatch, getState) => {
  const { alerts } = getState()
  return await postRequest({ path: '/api/alerts', body }).then(res => {
    alerts.alerts.push(res.json.alert)
    dispatch({ type: UPDATE_ALERTS, payload: alerts })
    return res
  }).catch(err => {
    showError(err.msg || 'Failed to create delivery method')(dispatch)
    dispatch({ type: UPDATE_ALERTS, payload: alerts })
  })
}

export const init = () => dispatch => {
  dispatch({ type: INITIALIZE_STATES })
}
