import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const initState = {}

const middleware = [thunk]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const reduxStore = createStore(rootReducer, initState, composeEnhancers(
  applyMiddleware(...middleware)
  // offline(offlineConfig)
))

export default reduxStore
