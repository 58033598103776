import React from 'react'
import { Spinner } from 'reactstrap'

const PrimaryButton = ({ text, loading, handler, danger }) => {
  return (
    <button
      type='button'
      className='btn float-left'
      onClick={handler}
      style={{
        padding: '0.5rem 2rem',
        backgroundColor: danger ? '#f5365c' : '#007AFF',
        borderColor: '#007AFF',
        borderRadius: '2px',
        color: 'white',
        boxShadow: 'none',
        border: 0
      }}
    >
      {loading
        ? <Spinner size='sm' />
        : text}
    </button>
  )
}

export default PrimaryButton
