import { getRequest, postRequest } from 'API/ApiClient'
import { ENTITIES_LOADING, SET_ENTITIES } from './constants'

export const fetchLegalEntities = () => async dispatch => {
  dispatch({ type: ENTITIES_LOADING })
  try {
    const { json: { flatLegalEntities, herichicalLegalEntites } } = await getRequest({ path: '/api/entities' })
    dispatch({ type: SET_ENTITIES, payload: { legalEntities: flatLegalEntities, hle: herichicalLegalEntites, loading: false } })
    return true
  } catch (err) {
    console.error(err)
  }
}

export const createLegalEntity = body => async dispatch => {
  try {
    await postRequest({ path: '/api/entities', body })
    window.analytics.track('Create Legal Entity', body)
    const { json: { flatLegalEntities, herichicalLegalEntites } } = await getRequest({ path: '/api/entities' })
    dispatch({ type: SET_ENTITIES, payload: { legalEntities: flatLegalEntities, hle: herichicalLegalEntites, loading: false } })
    return true
  } catch (err) {
    console.error(err)
  }
}
