import React from 'react'

import CreateLegalEntity from 'views/LegalEntities/CreateLegalEntity'

const LegalEntityStep = ({ setIndex }) => {
  return (
    <div className='step-wrapper entity' style={{ height: '100%' }}>
      <CreateLegalEntity onboarding setIndex={setIndex} />
    </div>
  )
}

export default LegalEntityStep
