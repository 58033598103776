import React from 'react'
import { Input } from 'reactstrap'
// import SearchIcon from '../../assets/icons/search-gray.svg'
import FlagIcon from '../../assets/icons/flag.svg'
import { normalizeCountry } from '../../util'

const SearchInput = ({
  onChange,
  onKeyUp,
  setValue,
  suggestions,
  value,
  labelName,
  name
}) => {
  return (
    <div className='custom-input'>
      <div className='custom-input__text custom-input__suggestions' style={{ border: 'none', borderBottom: '1px solid #888888', borderRadius: '0px' }}>
        <label className='custom-input__text-label d-block'>
          {labelName}
        </label>
        {/* <img alt='Search Icon' src={SearchIcon} /> */}
        <Input
          className='custom-input__regular-input'
          value={value}
          placeholder='Press enter to search for the legal name'
          name={name}
          type='text'
          autoComplete='nocomplete'
          onKeyUp={onKeyUp}
          onChange={onChange}
        />
        {suggestions && suggestions.length &&
          (
            <div className='custom-input__suggestion-wrapper'>
              {suggestions.map((sg, index) => {
                const { name, registered_address: registeredAddress, jurisdiction_code: jcode } = sg.company
                const registeredAddressCountry = registeredAddress && registeredAddress.country
                const country = normalizeCountry(registeredAddressCountry || jcode)
                const flag = (country && country.flagUrl) || FlagIcon
                sg.company.country = country && country.name

                return (
                  <div
                    key={index}
                    onClick={() => setValue(sg.company)}
                    style={{ padding: '0.65rem 1rem' }}
                  >
                    <span className='suggestion-company'>
                      <img alt='flag' src={flag} style={{ width: '20px', marginRight: '8px' }} />
                      {name}
                    </span>
                  </div>
                )
              })}
            </div>
          )}
      </div>
    </div>
  )
}

export default SearchInput
