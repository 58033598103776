import { INITIALIZE_STATES, UPDATE_TEAM } from 'actions/constants'

const initState = {
  users: [],
  permissions: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_TEAM: {
      return {
        ...state,
        users: [...state.users, action.payload]
      }
    }
    case 'SET_TEAM': {
      return {
        ...state,
        users: action.payload
      }
    }
    case INITIALIZE_STATES:
      return initState
    default:
      return state
  }
}
