import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts'
import { getRequest } from 'API/ApiClient'
import TimeIntervalPeriodDropDown from 'components/DropDowns/TimeIntervalDropDown'
import CurrencyDropDown from 'components/DropDowns/CurrencyDropDown'
import AccountDropDown from 'components/DropDowns/AccountsDropDown'
import ExchangeRateDropDown from 'components/DropDowns/ExchangeRateDropDown'
import CashFlowDetails from './CashFlowDetails'
import { fetchConsolidatedCashflow, fetchAccountCashflow } from 'actions/cashflowActions'

const CashFlow = props => {
  const [currencyTxs, setCurrencyTxs] = useState([])
  const [graphHeight, setGraphHeight] = useState(25)
  const [period, setPeriod] = useState({ name: 'Monthly', value: 'months' })
  const [monthlyCashFlow, setMonthlyCashFlow] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [activeCurrency, setActiveCurrency] = useState({
    value: 'USD', symbol: '$', front: true
  })
  const [rate, setExchangeRate] = useState({ name: 'live exchange rate', value: 'current_rate' })
  const [primaryPeriod, setPrimaryPeriod] = useState(null)
  const [comparaisonPeriod, setComparaisonPeriod] = useState(null)

  useEffect(() => {
    const controller = new window.AbortController()
    const fetchConvertedTxs = async () => {
      try {
        const { json: { txs } } = await getRequest({ path: `/api/transactions/converted/?currency=${activeCurrency.value}` })
        setCurrencyTxs(txs)
      } catch (err) {
        console.log(err)
      }
    }
    fetchConvertedTxs()
    return function cleanup () {
      controller.abort()
    }
  }, [activeCurrency, rate])

  const CustomizedAxisTick = props => {
    const date = period.value === 'months' ? props.payload.value : props.payload.value.slice(0, 7)
    return (
      <g transform={`translate(${props.x},${props.y})`}>
        <text x={0} y={0} dy={16} textAnchor='middle' fill='#666'>
          {moment(date).format('MMM YYYY')}
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const cashIn = payload.find(({ dataKey }) => dataKey === 'cashin_current_rate')
      const cashOut = payload.find(({ dataKey }) => dataKey === 'cashout_current_rate')

      const monthTxs = currencyTxs.filter(({ date, account_id: aid }) => {
        const formatted = moment(date).format('YYYY-MM')
        const dateMatch = formatted === label
        if (selectedAccount) {
          return aid === selectedAccount && dateMatch
        }
        return dateMatch
      })

      const sorted = monthTxs.sort((a, b) => a.amount - b.amount)

      let negatives = []
      let positives = []

      if (sorted.length > 0) {
        negatives = sorted.filter(({ amount }) => amount < 0).slice(0, 2)
        positives = sorted.filter(({ amount }) => amount > 0).slice(0, 2)
      }

      return (
        <div className='custom-tooltip cashflow-tooltip'>
          <div className='display-block w-100 mb-1 tooltip-header' style={{ paddingBottom: '1.5rem!important', fontSize: '15px' }}>
            <div className='d-inline-block float-left ml-2'>
              <span className='d-inline-block mr-1' style={{ background: '#15a01abf', borderRadius: '50%', height: '10px', width: '10px' }} />
              <span>In</span>
            </div>
            <div className='d-inline-block text-align-right float-left ml-1'>
              <span>{cashIn.value.toLocaleString()} {activeCurrency.value}</span>
            </div>
          </div>
          {positives.length > 0 && positives.map((tx, idx) => {
            if (!tx) return null
            return (
              <div key={idx} className='tx-tooltip-data' style={{ backgroundColor: '#f8f9fe' }}>
                <table className='table mb-0'>
                  <tbody>
                    <tr>
                      {/* <td className='p-2'>By: {tx.merchant}</td> */}
                      <td className='p-2'>{tx.amount}</td>
                      <td className='p-2'>{moment(tx.date).format('YYYY-MM-DD')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
          )}
          <div className='display-block w-100 mb-2 tooltip-header mt-2' style={{ paddingBottom: '1.5rem!important', fontSize: '15px' }}>
            <div className='d-inline-block float-left ml-2'>
              <span className='d-inline-block mr-1' style={{ background: '#fe000054', borderRadius: '50%', height: '10px', width: '10px' }} />
              <span>Out</span>
            </div>
            <div className='d-inline-block text-align-right float-left ml-1'>
              <span>{cashOut.value.toLocaleString()} {activeCurrency.value}</span>
            </div>
          </div>
          {negatives.length > 0 && negatives.map((tx, idx) => {
            if (!tx) return null
            return (
              <div key={idx} className='tx-tooltip-data' style={{ backgroundColor: '#f8f9fe' }}>
                <table className='table mb-0'>
                  <tbody>
                    <tr>
                      {/* <td className='p-2'>By: {tx.merchant}</td> */}
                      <td className='p-2'>{tx.amount}</td>
                      <td className='p-2'>{moment(tx.date).format('YYYY-MM-DD')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
          )}
        </div>
      )
    }

    return null
  }

  // const getPeriodBankBalance = async period => {
  //   const end = moment(period).endOf('month').format('YYYY-MM-DD')
  //   const start = moment(period).startOf('month').format('YYYY-MM-DD')
  //   let params = `?start=${start}&end=${end}&currency=${activeCurrency.value}&conversionType=converted`
  //   if (selectedAccount) {
  //     params += `&accountId=${selectedAccount}`
  //   }

  //   try {
  //     const res = await getRequest({ path: '/api/balances/range', params })
  //     return res.json.periodBalance
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }
  const tableRef = useRef(null)

  const handleChange = async (data) => {
    // const bankBalance = await getPeriodBankBalance(data.date)
    // console.log('bankBalance', bankBalance)

    if (comparaisonPeriod && primaryPeriod) {
      setPrimaryPeriod(null)
      setComparaisonPeriod(null)
      tableRef.current.setPrimaryPeriod(null)
      tableRef.current.setComparisonPeriod(null)
    }

    if (!primaryPeriod) {
      const object = { period: data.date, bankBalance: 0, cashIn: data.cashin_current_rate, cashOut: data.cashout_current_rate }
      setPrimaryPeriod(object)
      tableRef.current.setPrimaryPeriod(object)
      return
    }

    if (!comparaisonPeriod) {
      const object = { period: data.date, bankBalance: 0, cashIn: data.cashin_current_rate, cashOut: data.cashout_current_rate }
      setComparaisonPeriod(object)
      tableRef.current.setComparisonPeriod(object)
    }
  }

  useEffect(() => {
    const size = window.innerHeight <= 900 ? '62vh' : '75vh'
    setGraphHeight(size)
  }, [])

  useEffect(() => {
    if (selectedAccount) {
      const accountToFind = props.cashflow.accounts.find(
        c => c.accountId === selectedAccount &&
        c.period === period.value &&
        c.currency === activeCurrency.value
      )
      if (!accountToFind) {
        props.fetchAccountCashflow({
          currency: activeCurrency.value,
          period: period.value,
          accountId: selectedAccount
        })
        return
      }

      setMonthlyCashFlow(accountToFind.cashflow)
    } else {
      const consolidatedToFind = props.cashflow.consolidated.find(
        c => c.period === period.value &&
        c.currency === activeCurrency.value
      )

      if (!consolidatedToFind) {
        props.fetchConsolidatedCashflow({
          currency: activeCurrency.value,
          period: period.value
        })
        return
      }

      setMonthlyCashFlow(consolidatedToFind.cashflow)
    }
  }, [activeCurrency, period, selectedAccount, props.cashflow])

  return (
    <>
      <div id='cashflow-content' className='item2'>
        <span className='cash-flow-header'>
          <TimeIntervalPeriodDropDown
            selectedPeriodInterval={period}
            setSelectedPeriodInterval={setPeriod}
          />
          cash flow in
          <CurrencyDropDown
            selectedCurrency={activeCurrency.value}
            setSelectedCurrency={setActiveCurrency}
          />
          for
          <AccountDropDown
            accounts={props.accounts.accounts}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
          at the
          <ExchangeRateDropDown
            selectedRate={rate.name}
            setSelectedExchangeRate={setExchangeRate}
          />
        </span>
        <div className='cash-flow-graph'>
          <div style={{ height: graphHeight }}>
            <ResponsiveContainer width='100%' height='100%'>
              <ComposedChart
                data={monthlyCashFlow}
                stackOffset='sign'
                margin={{
                  top: 20,
                  right: 40,
                  bottom: 20,
                  left: 40
                }}
              >
                <XAxis tickLine={false} axisLine={false} dataKey='date' scale='band' tick={<CustomizedAxisTick />} />
                <YAxis tickLine={false} axisLine={false} tick={<CustomizedYAxisTick />} />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y={0} stroke='#000' />
                <Bar
                  onClick={x => handleChange(x)}
                  dataKey={rate.value === 'current_rate' ? 'cashin_current_rate' : 'cashin_historical_rate'}
                  fill='#80BDFF'
                  stackId='stack'
                />
                <Bar
                  onClick={x => handleChange(x)}
                  dataKey={rate.value === 'current_rate' ? 'cashout_current_rate' : 'cashout_historical_rate'}
                  fill='#E9E9E9'
                  stackId='stack'
                />
                <Line
                  type='monotone'
                  dataKey={rate.value === 'current_rate' ? 'tx_current_rate' : 'tx_historical_rate'}
                  stroke='#ff7300'
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className='item3'>
        <div className='tab-sidebar-wrapper'>
          <div className='tab-sidebar' style={{ paddingRight: '1.5rem' }}>
            <CashFlowDetails
              ref={tableRef}
              activeCurrency={activeCurrency}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const propMap = state => ({
  accounts: state.accounts,
  cashflow: state.cashflow
})

export const handleTooltipLogic = (sorted) => {
  const noPositive = false
  const noNegative = false

  let negatives = []
  let positives = []

  if (sorted.length === 0) return { noNegative, noPositive, negatives: [], positives: [] }

  negatives = sorted.filter(({ amount }) => amount < 0).slice(0, 2)
  positives = sorted.filter(({ amount }) => amount > 0).slice(0, 2)

  return { noPositive, noNegative, negatives, positives }
}

const CustomizedYAxisTick = props => {
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text x={0} y={0} dy={5} textAnchor='end' fill='#666'>
        {props.payload.value.toLocaleString()}
      </text>
    </g>
  )
}

export default connect(propMap, { fetchConsolidatedCashflow, fetchAccountCashflow })(CashFlow)

// import React, { useState, useRef, useEffect } from 'react'
// import { connect } from 'react-redux'
// import moment from 'moment'
// import {
//   select,
//   axisBottom,
//   scaleLinear,
//   max,
//   axisLeft,
//   extent,
//   format,
//   scaleBand,
//   curveBasis,
//   // line,
//   timeFormat
// } from 'd3'
// import CurrencyDropDown from 'components/DropDowns/CurrencyDropDown'
// import AccountDropDown from 'components/DropDowns/AccountsDropDown'
// import ExchangeRateDropDown from 'components/DropDowns/ExchangeRateDropDown'
// import { getConvertedTransactions } from '../../Dashboard/helper'
// import CashFlowDetails from './CashFlowDetails'
// import { fetchCashflowAnalytics, getBankBalance } from 'actions/cashflowActions'

// import { line } from 'd3-shape'

// import {
//   ComposedChart,
//   Line,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   Legend,
//   ReferenceLine,
//   ResponsiveContainer
// } from 'recharts'

// // import {
// //   ComposedChart,
// //   Line,
// //   Area,
// //   Bar,
// //   XAxis,
// //   YAxis,
// //   CartesianGrid,
// //   Tooltip,
// //   Legend,
// //   Scatter,
// //   ResponsiveContainer,
// // } from 'recharts';

// const data1 = [
//   {
//     name: 'Page A',
//     'Cash In': 4000,
//     'Cash Out': 2400,
//     amt: 2400,
//     Balance: 490
//   },
//   {
//     name: 'Page B',
//     'Cash In': -3000,
//     'Cash Out': 1398,
//     amt: 2210,
//     Balance: 590
//   },
//   {
//     name: 'Page C',
//     'Cash In': -2000,
//     'Cash Out': -9800,
//     amt: 2290,
//     Balance: 350
//   },
//   {
//     name: 'Page D',
//     'Cash In': 2780,
//     'Cash Out': 3908,
//     amt: 2000,
//     Balance: 480
//   },
//   {
//     name: 'Page E',
//     'Cash In': -1890,
//     'Cash Out': 4800,
//     amt: 2181,
//     Balance: 460
//   },
//   {
//     name: 'Page F',
//     'Cash In': 2390,
//     'Cash Out': -3800,
//     amt: 2500,
//     Balance: 380
//   }
// ]

// const CashflowChart = () => {
//   return (
//     <ResponsiveContainer width='100%' height='100%'>
//       <ComposedChart
//         width={500}
//         height={400}
//         data={data1}
//         stackOffset='sign'
//         margin={{
//           top: 20,
//           right: 20,
//           bottom: 20,
//           left: 20
//         }}
//       >
//         <XAxis dataKey='name' scale='band' />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <ReferenceLine y={0} stroke='#000' />
//         <Bar dataKey='Cash In' fill='#80BDFF' stackId='stack' />
//         <Bar dataKey='Cash Out' fill='#E9E9E9' stackId='stack' />
//         <Line type='monotone' dataKey='Balance' stroke='#ff7300' />
//       </ComposedChart>
//     </ResponsiveContainer>
//   )
// }

// export const handleTooltipLogic = (sorted, majorTxs, displayOptions) => {
//   let noPositive = false
//   let noNegative = false
//   for (let i = 0; i < 4; i++) {
//     let tx
//     try {
//       if (sorted.length < i) {
//         break
//       }
//       const tempTx = sorted[i]
//       if (i === 0 && [1, 2].includes(sorted.length) && tempTx.amount < 0) {
//         majorTxs.negative.push({
//           merchant: tempTx.merchant_name,
//           amount: tempTx.amount,
//           date: tempTx.date
//         })
//       }
//       if (i < 2) {
//         tx = sorted[sorted.length - 1 - i]
//         if (tx.amount > 0) {
//           majorTxs.positive.push({
//             merchant: tx.merchant_name,
//             amount: tx.amount,
//             date: tx.date
//           })
//         }
//         if ((tx.amount < 0 && i === 0) || displayOptions.out) {
//           noPositive = true
//         }
//       } else {
//         tx = sorted[i - 2]
//         if (tx.amount < 0) {
//           majorTxs.negative.push({
//             merchant: tx.merchant_name,
//             amount: tx.amount,
//             date: tx.date
//           })
//         }
//         if ((tx.amount > 0 && i === 2) || displayOptions.in) {
//           noNegative = true
//         }
//       }
//     } catch (err) {
//       break
//     }
//   }
//   return { noPositive, noNegative }
// }

// export const getTooltipHeader = (text, dotColor, number, activeCurrency, extraClass = '') => {
//   return `
//   <div class="display-block w-100 mb-1 tooltip-header ${extraClass}" style="padding-bottom:1.5rem!important;font-size:15px;">
//       <div class="d-inline-block float-left ml-2">
//         <span class="d-inline-block" style="background:${dotColor};border-radius:50%;height:10px;width:10px;"></span>
//         <span>${text}</span>
//       </div>
//       <div class="d-inline-block text-align-right float-left ml-1">
//         <span>${getFormattedAmt(number, activeCurrency)}</span>
//       </div>
//   </div>
// `
// }

// export const getFormattedAmt = (amt, activeCurrency) => {
//   const { symbol, front } = activeCurrency
//   amt = amt.toLocaleString()
//   return front ? symbol + amt : amt + symbol
// }

// export const getTooltipRow = (shouldNotShow, tx, activeCurrency) => {
//   if (!shouldNotShow) {
//     return `<div class="tx-tooltip-data" style="background-color: #f8f9fe;">
//       <table class="table mb-0">
//         <tr>
//           <td class="p-2">By: ${tx.merchant}</td>
//           <td class="p-2">${getFormattedAmt(tx.amount, activeCurrency)}</td>
//           <td class="p-2">${moment(tx.date).format('YYYY-MM-DD')}</td>
//         </tr>
//       </table>
//     </div>`
//   } else {
//     return ''
//   }
// }

// const CashFlow = props => {
//   const [monthlyCashFlow, setMonthlyCashFlow] = useState(null)
//   const [selectedAccount, setSelectedAccount] = useState(null)
//   const [activeCurrency, setActiveCurrency] = useState({
//     value: 'USD', symbol: '$', front: true
//   })
//   const [currencyTxs, setCurrencyTxs] = useState(null)
//   const [rate, setExchangeRate] = useState({ name: 'live exchange rate', endpoint: 'converted' })
//   let selectedPeriod = {
//     primary: {},
//     comparison: {}
//   }

//   const ref = useRef()
//   const wRef = useRef()
//   const tableRef = useRef(null)

//   const displayOptions = {
//     out: false,
//     in: false,
//     both: true
//   }

//   const marginValues = dimensions => {
//     const margin = { top: 20, right: 30, bottom: 50, left: 60 }
//     return {
//       margin,
//       width: dimensions.width - margin.left - margin.right,
//       height: 700 - margin.top - margin.bottom
//     }
//   }

//   const compileTxsDiv = data => {
//     const monthTxs = currencyTxs.filter(({ date, account_id: aid }) => {
//       const formatted = moment(date).format('YYYY-MM')
//       const dateMatch = formatted === data.date
//       if (selectedAccount) {
//         return aid === selectedAccount && dateMatch
//       }
//       return dateMatch
//     })
//     const cIn = monthlyCashFlow.cashIn.find(({ date }) => date === data.date)
//     const cOut = monthlyCashFlow.cashOut.find(({ date }) => date === data.date)
//     const sorted = monthTxs.sort((a, b) => a.amount - b.amount)
//     const majorTxs = { negative: [], positive: [] }
//     const { noPositive, noNegative } = handleTooltipLogic(sorted, majorTxs, displayOptions)
//     const positiveElems = majorTxs.positive.map((tx, idx) => (
//         `
//           ${idx === 0 && !noPositive ? getTooltipHeader('In', '#2dce8980', cIn && cIn.amount_current_rate ? cIn.amount_current_rate.toLocaleString() : 0, activeCurrency) : ''}
//           ${getTooltipRow(noPositive, tx, activeCurrency)}
//         `
//     )).join('')
//     const negativeElems = majorTxs.negative.map((tx, idx) => (
//         `
//         ${idx === 0 && !noNegative ? getTooltipHeader('Out', '#f5365c80', cOut && cOut.amount_current_rate ? cOut.amount_current_rate.toLocaleString() : 0, activeCurrency, noPositive ? '' : 'mt-2') : ''}
//         ${getTooltipRow(noNegative, tx, activeCurrency)}
//         `
//     )).join('')
//     return `
//         <div>
//           ${positiveElems}
//           ${negativeElems}
//         <div>`
//   }

//   const redrawChart = () => {
//     const svg = select(ref.current)
//     svg.selectAll('g').remove()
//   }

//   const getPeriodBankBalance = async per => {
//     const res = await getBankBalance(activeCurrency.value, per, rate.endpoint, selectedAccount)
//     return res.periodBalance
//   }

//   const handlePrimChange = async () => {
//     const { primary } = selectedPeriod
//     if (primary.period) {
//       primary.bankBalance = await getPeriodBankBalance(primary.period)
//     }
//     tableRef.current.setPrimaryPeriod(primary)
//   }

//   const handleComparisonChange = async () => {
//     const { comparison } = selectedPeriod
//     if (comparison.period) {
//       comparison.bankBalance = await getPeriodBankBalance(comparison.period)
//     }
//     tableRef.current.setComparisonPeriod(comparison)
//   }

//   useEffect(() => {
//     const controller = new window.AbortController()
//     const fetchConvertedTxs = async () => {
//       try {
//         initPeriods()
//         const { json: { txs } } = await getConvertedTransactions(activeCurrency.value, rate.endpoint)
//         setCurrencyTxs(txs)
//       } catch (err) {
//         console.log(err)
//       }
//     }
//     fetchConvertedTxs()
//     return function cleanup () {
//       controller.abort()
//     }
//   }, [activeCurrency, rate])

//   const useResizeObs = wrapperRef => {
//     const [dimensions, setDimensions] = useState(null)
//     useEffect(() => {
//       const target = wrapperRef.current
//       const rs = new window.ResizeObserver(entries => {
//         entries.forEach(entry => {
//           setDimensions(entry.contentRect)
//         })
//       })
//       rs.observe(target)
//       return () => rs.unobserve(target)
//     }, [wrapperRef])
//     redrawChart()
//     return dimensions
//   }

//   const dimensions = useResizeObs(wRef)

//   const initPeriods = () => {
//     selectedPeriod = {
//       primary: {},
//       comparison: {}
//     }
//     handleComparisonChange()
//     handlePrimChange()
//   }

//   useEffect(() => {
//     const removePeriodSelection = primary => {
//       const svg = select(ref.current)
//       if (primary) {
//         svg
//           .select('.primary-period')
//           .remove()
//       } else {
//         svg
//           .select('.comparison-period')
//           .remove()
//       }
//     }

//     const removeOtherSelected = className => {
//       const cl = `.bar-selected.${className}`
//       const otherSelections = document.querySelectorAll(cl)
//       otherSelections.forEach(selection => {
//         selection.classList.remove('bar-selected')
//         selection.classList.remove(className)
//         const { x: { value: targetX } } = selection.attributes
//         const borderClass = className !== 'primary' ? '.primary-period' : '.comparison-period'
//         const borderEl = document.querySelectorAll(borderClass)
//         borderEl.forEach(el => {
//           const { x: { value: borderX } } = el.attributes
//           const numX = Number(borderX) + 10
//           const tX = Number(targetX)
//           if (tX === numX) {
//             el.remove()
//           }
//         })
//       })
//     }

//     if (monthlyCashFlow && dimensions) {
//       const { margin: { top, right, bottom, left }, width, height } = marginValues(dimensions)
//       const { txs, cashIn, cashOut } = monthlyCashFlow
//       let movements = []
//       movements = [...cashIn, ...cashOut]
//       cashIn.forEach(cashInData => {
//         const { date: dateVal, amount_current_rate: amt } = cashInData
//         const cashOutVal = cashOut.find(({ date }) => date === dateVal)
//         const netValue = amt + cashOutVal.amount_current_rate
//         movements.push({ date: dateVal, amount_current_rate: netValue, netData: true })
//       })

//       console.log('cashIn', cashIn)
//       console.log('cashOut', cashOut)

//       movements.sort((a, b) => new Date(a.date) - new Date(b.date))
//       txs.sort((a, b) => new Date(a.date) - new Date(b.date))
//       const svg = select(ref.current)
//         .attr('width', width + left + right)
//         .attr('class', 'cashflow-chart')
//         .attr('height', height + top + bottom)
//         .append('g')
//         .attr('transform', `translate(${left}, ${top})`)

//       let filteredDates = []
//       movements.map(m => m.date).forEach(strDate => {
//         if (!filteredDates.includes(strDate)) {
//           filteredDates.push(strDate)
//         }
//       })
//       filteredDates = filteredDates.map(d => new Date(d))
//       // Get the width of the secondary X axis to include it in the first X axis calculation
//       const xScale = scaleBand()
//         .domain(filteredDates)
//         .range([0, width])
//         .paddingInner(0.5)

//       const xAxis = axisBottom().scale(xScale).ticks(13).tickSize(0).tickFormat(timeFormat('%b'))

//       const numberFormat = el => {
//         return format('~s')(Number(el.toFixed(2)))
//       }

//       // create y Axis
//       const txsAmount = txs.map(tx => Math.abs(tx.amount_current_rate))
//       const maxAmount = Math.max(...txsAmount)
//       txs.push({ amount_current_rate: maxAmount }, { amount_current_rate: -maxAmount })
//       const yScale = scaleLinear().domain(extent(txs, d => d.amount_current_rate)).range([height, 0])
//       svg.append('g')
//         .attr('class', 'y-axis-tick')
//         .attr('transform', 'translate(-10, 0)')
//         .call(axisLeft(yScale).tickFormat(d => numberFormat(d)).tickSize(0))

//       const xAxis2 = axisBottom().scale(xScale).ticks(13).tickSize(0).tickFormat(timeFormat('%b'))

//       const maxHeight = max(movements, d => Math.abs(d.amount_current_rate))
//       // Scale for secondary y axis bars - divide height into 2 because two bars will coexist on the same x axis
//       const yScale2 = scaleLinear().domain([maxHeight, -maxHeight]).rangeRound([0, height]).nice()
//       svg.append('g')
//         .attr('transform', `translate(0, ${height / 2})`)
//         .style('opacity', 0.2)
//         .call(xAxis2.tickFormat('').tickSize(0))

//       const div = select('body').append('div')
//         .attr('class', 'cashflow-tooltip')
//         .style('opacity', 0)

//       const createBars = barData => {
//         svg.selectAll('bar')
//           .data(barData)
//           .enter()
//           .append('rect')
//           .attr('class', 'bar')
//           .style('fill', d => {
//             let color = d.amount_current_rate > 0 ? '#80BDFF' : '#E9E9E9'
//             if (d.netData) {
//               color = '#9BEAEC'
//             }
//             return color
//           })
//           .attr('rx', '1')
//           .attr('x', d => {
//             const scale = xScale(new Date(d.date))
//             return scale
//           })
//           .attr('width', xScale.bandwidth())
//           .attr('y', d => {
//             if (d.netData) {
//               if (d.amount_current_rate > 0) {
//                 return yScale2(d.amount_current_rate)
//               } else {
//                 return height / 2
//               }
//             }
//             if (d.amount_current_rate < 0) {
//               return (height / 2) - 0.5
//             } else {
//               return yScale2(d.amount_current_rate) + 0.5
//             }
//           })
//           .attr('height', d => {
//             const val = height / 2 - yScale2(Math.abs(d.amount_current_rate))
//             return val
//           }).on('mouseover', (evt, d) => {
//             const { pageX, pageY } = evt
//             select(evt.target).transition()
//               .duration(50)
//               .attr('opacity', '0.8')
//             div.transition()
//               .duration(50)
//               .style('opacity', 1)
//             const divHTML = compileTxsDiv(d)
//             const { innerWidth } = window
//             div.html(divHTML)
//               .style('top', `${pageY + -50}px`)

//             const divHtmlElem = document.querySelector('div.cashflow-tooltip')
//             if (divHtmlElem) {
//               const { width: divWidth } = divHtmlElem.getBoundingClientRect()
//               let xPos = pageX + divWidth
//               if (innerWidth - 100 < xPos) {
//                 xPos = pageX - divWidth
//                 div.style('left', `${xPos - 100}px`)
//               } else {
//                 div.style('left', `${pageX + 100}px`)
//               }
//             }
//           }).on('mouseout', (evt) => {
//             select(evt.target).transition()
//               .duration(50)
//               .attr('opacity', 1)
//             div.transition()
//               .duration(50)
//               .style('opacity', 0)
//           }).on('click', (evt, d) => {
//             const { width: { value: targetWidth }, x: { value: targetX } } = evt.target.attributes
//             const isAlreadySelected = evt.target.classList.value.includes('bar-selected')
//             if (!isAlreadySelected) {
//               const chartHeight = 700
//               evt.target.classList.add('bar-selected')
//               const primarySelected = selectedPeriod.primary.period
//               svg
//                 .append('rect')
//                 .attr('class', primarySelected ? 'comparison-period' : 'primary-period')
//                 .attr('rx', '4')
//                 .attr('x', Number(targetX) - 10)
//                 .attr('width', Number(targetWidth) + 20)
//                 .attr('height', chartHeight - 25)
//                 .attr('y', -5)
//                 .style('fill', '#00000000')
//                 .style('stroke', '#00000040')
//                 .on('click', event => {
//                   evt.target.classList.remove('bar-selected')
//                   evt.target.classList.remove('primary')
//                   evt.target.classList.remove('comparison')
//                   const selectionTarget = event.target
//                   if (selectionTarget.classList.value.includes('primary-period')) {
//                     removePeriodSelection(true)
//                     selectedPeriod.primary = {}
//                     handlePrimChange()
//                   } else {
//                     removePeriodSelection(false)
//                     selectedPeriod.comparison = {}
//                     handleComparisonChange()
//                   }
//                 })
//               if (primarySelected) {
//                 removeOtherSelected('primary')
//                 evt.target.classList.add('primary')
//                 selectedPeriod.comparison = {
//                   period: d.date,
//                   xCordinate: targetX
//                 }
//                 handleComparisonChange()
//               } else {
//                 removeOtherSelected('comparison')
//                 evt.target.classList.add('comparison')
//                 selectedPeriod.primary = {
//                   period: d.x,
//                   xCordinate: targetX
//                 }
//                 handlePrimChange()
//               }
//             }
//           })
//       }

//       const lineFunc = line()
//         .x(v => xScale(new Date(v.date)))
//         .y(v => yScale(v.amount_current_rate))
//         .curve(curveBasis)

//       const createCashFlowLine = (data, color) => {
//         svg
//           .append('path')
//           .attr('class', 'line')
//           .attr('d', lineFunc(data))
//           .attr('fill', 'none')
//           .attr('stroke', color)
//           .attr('stroke-width', 3)
//       }

//       createBars(movements)
//       createCashFlowLine(txs, '#109CF1')
//       // create XAxis
//       svg.append('g')
//         .attr('transform', `translate(0, ${height / 2})`)
//         .attr('class', 'x-axis-tick')
//         .call(xAxis)
//     }
//   }, [monthlyCashFlow, dimensions])

//   useEffect(() => {
//     // initPeriods()
//     let ratePath = 'cashflow'
//     if (rate.endpoint === 'converted-historical') {
//       ratePath = 'cashflow-historical'
//     }

//     if (selectedAccount) {
//       const accountToFind = props.cashflow.accounts.find(c => c.accountId === selectedAccount)
//       if (!accountToFind) {
//         props.fetchCashflowAnalytics({
//           currency: activeCurrency.value,
//           ratePath,
//           accountId: selectedAccount
//         })
//         return
//       }
//       const cashflow = accountToFind.cashflow
//       setMonthlyCashFlow(cashflow.monthlyCashFlow)
//     } else {
//       if (!props.cashflow.consolidated.length) {
//         props.fetchCashflowAnalytics({
//           currency: activeCurrency.value,
//           ratePath
//         })
//         return
//       }
//       setMonthlyCashFlow(props.cashflow.consolidated)
//     }
//   }, [activeCurrency, selectedAccount, props.cashflow])

//   console.log('monthlyCashFlow', monthlyCashFlow)

//   return (
//     <>
//       <div id='cashflow-content' className='item2'>
//         <div ref={wRef} style={{ width: '100%', display: 'none' }} />
//         <span className='cash-flow-header'>
//           Cash flow in
//           <CurrencyDropDown
//             selectedCurrency={activeCurrency.value}
//             setSelectedCurrency={setActiveCurrency}
//           />
//           for
//           <AccountDropDown
//             accounts={props.accounts.accounts}
//             selectedAccount={selectedAccount}
//             setSelectedAccount={setSelectedAccount}
//           />
//           at the
//           <ExchangeRateDropDown
//             selectedRate={rate.name}
//             setSelectedExchangeRate={setExchangeRate}
//           />
//         </span>
//         <div className='cash-flow-graph'>
//           {/* <svg style={{ height: '700', color: '#888', paddingLeft: '20px' }} ref={ref} /> */}
//           <div style={{ height: '500px' }}>
//             <CashflowChart />
//           </div>
//         </div>
//       </div>
//       <div className='item3'>
//         <div className='tab-sidebar-wrapper'>
//           <div className='tab-sidebar' style={{ paddingRight: '1.5rem' }}>
//             <CashFlowDetails
//               ref={tableRef}
//               selectedAccount={selectedAccount}
//               transactions={currencyTxs}
//               activeCurrency={activeCurrency}
//               balances={monthlyCashFlow}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// const propMap = state => ({
//   accounts: state.accounts,
//   cashflow: state.cashflow
// })

// export default connect(propMap, { fetchCashflowAnalytics })(CashFlow)
