import React, { useState } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { getRequest } from 'API/ApiClient'
import InvoicesList from './InvoicesList'
import InvoiceModal from './InvoiceModal'
import history from '../../history'

export const fetchInvoicesApi = async () => {
  return getRequest({ path: '/api/invoices/list' })
}

const Invoices = () => {
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false)
  const [invoiceModalDetails, setInvoiceModalDetails] = useState()
  const [tabs, setTabs] = useState([
    {
      type: 'index',
      name: 'Invoices'
    },
    {
      type: 'vendors',
      name: 'Vendors'
    }
  ])

  const changeActiveTab = newType => {
    history.push(`/admin/invoices/${newType}`)
    const index = tabs.findIndex(({ type }) => type === newType)
    const copy = [...tabs].map(tab => ({ ...tab, active: false }))
    copy[index].active = true
    setTabs(copy)
  }

  const isActiveTab = (tabType) => {
    const path = `/admin/invoices/${tabType}`
    if (path === history.location.pathname) {
      return true
    }
    return false
  }

  const getTabNavigationContent = () => (
    tabs.map(tab => {
      const { name, type } = tab
      return (
        <div
          key={name}
          onClick={() => changeActiveTab(type)}
          className={isActiveTab(tab.type) ? 'tab tab-btn active' : 'tab tab-btn'}
        >
          <span className='tab-name text-capitalize'>{name}</span>
        </div>
      )
    })
  )

  return (
    <main>
      <div id='dashboard' className='dashboard'>
        <div className='item1'>
          <div className='tab-nav'>
            {getTabNavigationContent()}
          </div>
        </div>
        <InvoiceModal
          setInvoiceModalOpen={setInvoiceModalOpen}
          modalOpen={invoiceModalOpen}
          details={invoiceModalDetails}
        />
        <Router history={history}>
          <Switch>
            <Route path='/admin/invoices/index'>
              <InvoicesList
                setInvoiceModalOpen={setInvoiceModalOpen}
                setInvoiceModalDetails={setInvoiceModalDetails}
              />
            </Route>
            <Route path='/admin/invoices/vendors'>
              <p>Hello</p>
            </Route>
            <Redirect from='/admin/invoices' to='/admin/invoices/index' />
          </Switch>
        </Router>
      </div>
    </main>
  )
}

export default Invoices
