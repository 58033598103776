import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { fetchAllSettings } from 'actions/settingsActions'
import SettingsTabs from './SettingsTabs'

const SettingsView = props => {
  useEffect(() => {
    props.fetchAllSettings()
  }, [])

  return (
    <main>
      <div className='dashboard settings h-100'>
        <Row style={{ height: '100%', padding: '0' }}>
          <Col xs='12'>
            <SettingsTabs />
          </Col>
        </Row>
      </div>
    </main>
  )
}

const propMap = state => ({
  settings: state.settings,
  user: state.user
})

export default connect(propMap, { fetchAllSettings })(SettingsView)
