import {
  GET_ENTITIES,
  SET_ENTITIES,
  ENTITIES_LOADING,
  INITIALIZE_STATES
} from '../actions/constants'

const initState = {
  loading: false,
  legalEntities: [],
  hle: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_ENTITIES:
    case SET_ENTITIES:
      return {
        ...action.payload,
        loading: false
      }
    case ENTITIES_LOADING: {
      return {
        ...action.payload,
        loading: true
      }
    }
    case INITIALIZE_STATES:
      return initState
    default:
      return state
  }
}
