import React, { useState } from 'react'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import { Col, Row } from 'reactstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import CustomInput from 'components/CustomInput'
import { AccountSelect } from 'components/Transact/AccountSelect'
import CrossIcon from '../../assets/icons/cross.svg'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const LICENSE_KEY = 'DO8XMOMqxLC65xUKvFOouPip5T__FLjLOkSzKKNWsQbhnKoMCGL46e-JZIjgCA5L5HxjjGhHNlLebQ2Ohq3zRi9uCvNSJ9OqGKTb7EwK0NDCg_JtRj9oM8GSyg3SCjwsRtJVLTILe-Q-7Z1cpoPOdjKXswsPLN_SiDwowAdVAzFOWdGe7mGNifytQ8tdNutVJZk1A8ma1hCIBsA2MedbBP3sVhg-agmJoSWF2qxyoCCZVQUMLXkK1yCfHNbrSC4LqZCqxTacSLLv0mpSGFDLSnwI1hnZuZw5KF4W2GxL729Md9c-BhI9JLkHIq205QIG2w-GD3K54VJiZNJ7XvxPE89q0ewj6PLwqTIfGDykK1Pmns3ei06VWICFx_Xv_7Nc8LC05DBNiG2tLNMXnux1wIFX3YycKDCKnPhbxbi-dD7cwctl70nqg6L08L1j63sI'

// https://api.unsplash.com/photos/?client_id=OZn9i20qbrLT36seM_yH4mFH60bXVPrNdExU6RBgH28

const InvoiceModal = ({ modalOpen, setInvoiceModalOpen, details, accounts }) => {
  const [selectedAccount, setAccount] = useState(null)

  return (
    <ReactModal
      isOpen={modalOpen}
      style={{ overlay: { zIndex: 2 } }}
      contentLabel='Invoice Modal'
    >
      {details && (
        <div id='invoice-modal'>
          <div id='invoice-modal-header'>
            <img style={{ cursor: 'pointer' }} height='20px' src={CrossIcon} onClick={() => setInvoiceModalOpen(false)} />
          </div>
          <div className='invoice-modal-pdf' style={{ overflow: 'scroll' }}>
            <Document file={{ url: `https://api.getmetabank.com/api/invoices/${details.id}` }}>
              <Page pageNumber={1} />
            </Document>

            {/* <PSPDFKit
              documentUrl={details.id}
              licenseKey={LICENSE_KEY}
            //   baseUrl='https://api.getmetabank.com/api/invoices/'
            //   baseUrl='http://www.africau.edu/images/default'
            /> */}
            {/* <PDFReader scale={0.5} style={{ overflowY: 'scroll' }} url={`https://api.getmetabank.com/api/invoices/${details.id}`} /> */}
          </div>
          <div className='invoice-modal-form-field'>
            <Row className='mt-3'>
              <Col>
                <h1>Invoice summary</h1>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <CustomInput labelName='Merchant Name' value={details.merchantName} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <CustomInput labelName='Invoice Number' value={details.invoiceId} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <CustomInput type='date' labelName='Invoice Date' value={details.date} />
              </Col>
              <Col>
                <CustomInput type='date' labelName='Due Date' value={details.dueDate} />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col>
                <h1>Payment details</h1>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <CustomInput labelName='Amount' value={details.amount} />
              </Col>
              <Col>
                <CustomInput labelName='Currency' value={details.currency} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <AccountSelect
                  setAccount={setAccount}
                  selectedAccount={selectedAccount}
                  accounts={accounts}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <PrimaryButton text='Pay' />
                <SecondaryButton text='Schedule' />
                <SecondaryButton text='Remind me' />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </ReactModal>
  )
}

const propMap = state => ({
  accounts: state.accounts.accounts
})

export default connect(propMap, undefined)(InvoiceModal)
