import {
  SET_INVOICES,
  INITIALIZE_STATES
} from 'actions/constants'

const initState = {
  invoices: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_INVOICES: {
      return {
        invoices: action.payload
      }
    }
    case INITIALIZE_STATES:
      return initState
    default:
      return state
  }
}
