import { getRequest } from '../API/ApiClient'
import { ACCOUNT_LOADING, GET_ACCOUNTS, SET_SELECTED_ACCOUNT } from './constants'

const addCommandbarContextAcc = (accounts) => {
  const allAccounts = accounts.map(acc => ({ label: acc.name, value: acc.id }))
  allAccounts.unshift({ label: 'All accounts', value: null })
  window.CommandBar.addContext({
    accounts: allAccounts
  })
}

export const fetchAccounts = () => async (dispatch) => {
  dispatch({ type: ACCOUNT_LOADING })
  try {
    const { json } = await getRequest({ path: '/api/accounts' })
    dispatch({ type: GET_ACCOUNTS, payload: json.accounts })
    addCommandbarContextAcc(json.accounts)
    return json
  } catch (err) {
    console.error(err)
  }
}

export const setSelectedAccount = (accountId) => (dispatch, prevState) => {
  if (accountId === prevState.selectedAccountId) {
    setSelectedAccount(null)
    dispatch({ type: SET_SELECTED_ACCOUNT, payload: null })
  } else {
    window.analytics.track('Select an account', { accountId })
    dispatch({ type: SET_SELECTED_ACCOUNT, payload: accountId })
  }
}
