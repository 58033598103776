import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import moment from 'moment'
import { usePlaidLink } from 'react-plaid-link'
import { currencyList } from '../../../util'
import { formatCashflowAnalytics } from '../../Dashboard/helper'
import Amount from 'components/Amount'
import Dot from 'components/Dot'
import {
  getAccountAnalytics,
  getPrimaryAndComparisonBalance,
  getCashFlowAnalytics
} from 'actions/analyticsActions'
import { fetchBalances } from 'actions/balanceActions'
import { fetchLastMonthCashFlow } from 'actions/cashflowActions'

import Reconnect from 'assets/icons/reconnect-1.svg'
import Refresh from 'assets/icons/refresh-2.svg'

import { putRequest, getRequest, postRequest } from 'API/ApiClient'

const PlaidLinkButton = props => {
  const onPlaidSuccess = (token, metadata) => {
    console.log(token, metadata)
  }
  const { plaidToken } = props
  const { error, open } = usePlaidLink({ token: plaidToken, onSuccess: onPlaidSuccess })
  if (error) console.error(error)
  open()

  return <div />
}

const AccountDetails = props => {
  const [cashflow, setCashFlow] = useState({
    cashInFormatted: 0,
    cashOutFormatted: 0,
    cashFlowDifference: 0,
    lastPeriodDifference: 0,
    periodDifference: 0,
    periodDifferencePercentage: 0
  })
  const [plaidToken, setPlaidToken] = useState(null)

  console.log('props.selected', props.selectedAccountId)

  const [period, setPeriod] = useState({ text: 'month', value: 30 })
  const [selectedCurrency, setSelectedCurrency] = useState('USD')
  const {
    balanceCurrency
  } = props

  useEffect(() => {
    props.fetchBalances()
  }, [])

  const getDatesForComparison = () => {
    const dF = 'YYYY-MM-DD'
    return {
      compareStart: moment().subtract(1, 'month').startOf('month').format(dF),
      compareEnd: moment().subtract(1, 'month').endOf('month').format(dF),
      primaryStart: moment().startOf('month').format(dF),
      primaryEnd: moment().format(dF)
    }
  }

  useEffect(() => {
    const cashflowToFind = props.cashflow.lastMonth.find(c => c.period === period.value && c.currency === selectedCurrency)
    if (!cashflowToFind) {
      props.fetchLastMonthCashFlow({ currency: selectedCurrency, period: period.value })
      return
    }

    setCashFlow(cashflowToFind.cashflow)
  }, [period, selectedCurrency, props.cashflow])

  useEffect(() => {
    const updateAccountAnalytics = async () => {
      if (props.selectedAccount && props.selectedAccount.id) {
        if (!props.accountAnalytics || props.accountAnalytics.accountId !== props.selectedAccount.id) {
          const { compareStart, compareEnd, primaryStart, primaryEnd } = getDatesForComparison()
          await Promise.all([
            props.getAccountAnalytics(props.selectedAccount.currency, props.selectedAccount.id),
            props.getPrimaryAndComparisonBalance(compareStart, compareEnd, primaryStart, primaryEnd, props.selectedAccount.id)
          ])
        }
      }
    }
    updateAccountAnalytics()
  }, [props.selectedAccount])

  useEffect(() => {
    const updateBalanceAnalytics = async () => {
      if (props.selectedAccount && props.selectedAccount.id) {
        const { compareStart, compareEnd, primaryStart, primaryEnd } = getDatesForComparison()
        await props.getCashFlowAnalytics(balanceCurrency.value)
        await props.getPrimaryAndComparisonBalance(compareStart, compareEnd, primaryStart, primaryEnd, props.selectedAccount.id)
      }
    }
    updateBalanceAnalytics()
  }, [balanceCurrency])

  const refreshBankAccountData = async (aggregator, aggregatorItemId) => {
    if (aggregator === 'belvo') {
      await openBelvoWidget(aggregatorItemId)
    } else if (aggregator === 'salt-edge') {
      const { json } = await getRequest({ path: `/api/saltedge/refresh_link_url?connectionId=${aggregatorItemId}` })
      window.open(json.connectUrl.connect_url)
    } else if (aggregator === 'plaid') {
      const { json } = await postRequest({ path: `/api/plaid/create_update_link_token?aggregatorItemId=${aggregatorItemId}` })
      const token = json.link_token
      setPlaidToken(token)
    }
  }

  async function openBelvoWidget (linkId) {
    const { json: { access } } = await putRequest({ path: '/api/belvo/bank/auth', body: { linkId } })
    if (!access) {
      return
    }

    window.belvoSDK.createWidget(access, {
      locale: 'en', // 'en' for English or 'pt' for Portuguese
      company_name: 'Maytana', // the name of the company to be displayed in the first screen
      institution_types: ['business'], // to select the type of institution to show in the widget
      access_mode: 'recurrent', // to specify the type of link to be created from the widget
      callback: (link, institution) => console.log(link, institution),
      onExit: (data) => console.error(data),
      onEvent: (data) => console.log(data)
    }).build()
  }

  const getHeader = () => {
    const nopadding = { padding: '0' }
    if (props.selectedAccountId) {
      const selectedAccount = props.accounts.find(({ id }) => id === props.selectedAccountId)
      const {
        converted_balance: balance,
        iso_currency_code: currency,
        logo_url: logo,
        name,
        aggregator_item_id: aggregatorItemId,
        aggregator
      } = selectedAccount

      return (
        <Row>
          <Col sm='6' lg='7' style={nopadding}>
            <div className='account-meta'>
              {plaidToken && (
                <PlaidLinkButton plaidToken={plaidToken} />
              )}
              <img
                className='bank-account-logo'
                src={logo}
              />
              {/* <img
                onClick={() => reconnectBankAccount(aggregator, aggregatorItemId)}
                src={Reconnect}
                style={{ display: 'inline-block', height: '25px', marginRight: '10px', marginLeft: '10px', cursor: 'pointer' }}
              /> */}
              <img
                onClick={() => refreshBankAccountData(aggregator, aggregatorItemId)}
                src={Refresh}
                style={{ display: 'inline-block', height: '25px', cursor: 'pointer' }}
              />
              <div className='account-text'>
                <h1>{name}</h1>
                <span className='text-gray'>{currency}</span>
              </div>
            </div>
          </Col>
          <Col sm='6' lg='5' style={{ ...nopadding, paddingRight: '5px' }}>
            <div className='account-balance-meta'>
              <Amount amount={balance} fontSize={20} currency={currency} />
              <span className='text-gray'>Available balance</span>
            </div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col lg='7' style={nopadding}>
            <div className='account-meta'>
              <span className='all-balance-logo' />
              <div className='account-text'>
                <h1>All accounts</h1>
              </div>
            </div>
          </Col>
          <Col lg='5' style={{ ...nopadding, paddingRight: '5px' }}>
            <div className='account-balance-meta'>
              <Amount amount={props.balances.display} fontSize={20} />
              <span className='text-gray'>Available balance</span>
            </div>
          </Col>
        </Row>
      )
    }
  }

  const getRows = () => {
    const col1Keys = [
      {
        key: 'moneyIn',
        label: 'Money in'
      },
      {
        key: 'moneyOut',
        label: 'Money out'
      },
      {
        key: 'cashflow',
        label: 'Cashflow'
      },
      {
        key: 'latestBalance',
        label: 'Closing balance'
      },
      {
        key: 'txIn',
        label: 'Transactions in'
      },
      {
        key: 'txOut',
        label: 'Transactions out'
      }
    ]
    const col2Keys = [
      'moneyInLM',
      'moneyOutLM',
      'cashflowLM',
      'comparisonBalance',
      'txInLM',
      'txOutLM'
    ]
    let formatted
    let match
    if (props.selectedAccountId && props.accountAnalytics && props.accountAnalytics.accountId === props.selectedAccountId) {
      match = currencyList.find(({ value }) => value === props.selectedAccount.iso_currency_code)
      const { cashIn, cashOut } = props.accountAnalytics
      formatted = formatCashflowAnalytics(cashIn, cashOut, props.latestBalance, props.comparisonBalance)
    }
    if (props.cashflowAnalytics && !formatted) {
      const { cashIn, cashOut } = props.cashflowAnalytics
      formatted = formatCashflowAnalytics(cashIn, cashOut, props.latestBalance, props.comparisonBalance)
    }
    if (formatted) {
      const symbol = match && match.symbol ? match.symbol : '$'
      return (
        col1Keys.map((columnKey, idx) => {
          const { key, label } = columnKey
          const lastMonthData = formatted[col2Keys[idx]]
          const dataPoint = formatted[key].toFixed(2).toLocaleString()
          const rawDiff = formatted[key] - lastMonthData
          const diff = Math.abs(rawDiff).toFixed(2).toLocaleString()
          let sign = ''
          if (idx < 4) {
            sign = symbol
          }
          return (
            <Row key={idx}>
              <Col lg='6'>
                <div className='account-data-col'>
                  <h3>{sign}{dataPoint}</h3>
                  <span className='text-gray d-block'>{label}</span>
                </div>
              </Col>
              <Col lg='6'>
                <div className='account-data-col text-right'>
                  <h3>{sign}{diff}</h3>
                  <span className='d-block'>
                    <span className={rawDiff > 0 ? 'green' : 'red'}>
                      {rawDiff > 0 ? 'Up ' : 'Down '}
                    </span>
                    since {' '}
                    <span className='period'>
                      last month
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          )
        })
      )
    } else {
      return (
        <div>
          <Row>
            <Col lg='6'>
              <div className='account-data-col'>
                <Amount amount={props.analytics.reports.net} fontSize={16} />
                <span className='text-gray d-block'>Net</span>
              </div>
            </Col>
            <Col lg='6'>
              <div className='account-data-col text-right'>
                <h3>{Math.sign(cashflow.lastPeriodDifference)}{Math.abs(cashflow.lastPeriodDifference).toLocaleString()} ({Math.abs(cashflow.periodDifferencePercentage).toLocaleString()})</h3>
                <span className='d-block'>
                  <span className={cashflow.lastPeriodDifference > 0 ? 'green' : 'red'}>
                    {cashflow.lastPeriodDifference > 0 ? 'Up ' : 'Down '}
                  </span>
                  since {' '}
                  <span className='period'>
                    last month
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg='6'>
              <div className='account-data-col'>
                <Amount amount={cashflow.cashInFormatted} fontSize={16} />
                <span className='text-gray d-block'> <Dot color='green' />{' '}Cash in</span>
              </div>
            </Col>
            {/* <Col lg='6'>
              <div className='account-data-col text-right'>
                <h3>{sign}{diff}</h3>
                <span className='d-block'>
                  <span className={rawDiff > 0 ? 'green' : 'red'}>
                    {rawDiff > 0 ? 'Up ' : 'Down '}
                  </span>
                  since {' '}
                  <span className='period'>
                    last month
                  </span>
                </span>
              </div>
            </Col> */}
          </Row>
          <Row>
            <Col lg='6'>
              <div className='account-data-col'>
                <Amount amount={props.analytics.reports.burn} fontSize={16} />
                <span className='text-gray d-block'><Dot color='red' />{' '}Cash out</span>
              </div>
            </Col>
            {/* <Col lg='6'>
              <div className='account-data-col text-right'>
                <h3>{sign}{diff}</h3>
                <span className='d-block'>
                  <span className={rawDiff > 0 ? 'green' : 'red'}>
                    {rawDiff > 0 ? 'Up ' : 'Down '}
                  </span>
                  since {' '}
                  <span className='period'>
                    last month
                  </span>
                </span>
              </div>
            </Col> */}
          </Row>
          <Row>
            <Col lg='6'>
              <div className='account-data-col'>
                <Amount amount={props.analytics.reports.revenues} fontSize={16} />
                <span className='text-gray d-block'><Dot color='red' />{' '}Revenues</span>
              </div>
            </Col>
            {/* <Col lg='6'>
              <div className='account-data-col text-right'>
                <h3>{sign}{diff}</h3>
                <span className='d-block'>
                  <span className={rawDiff > 0 ? 'green' : 'red'}>
                    {rawDiff > 0 ? 'Up ' : 'Down '}
                  </span>
                  since {' '}
                  <span className='period'>
                    last month
                  </span>
                </span>
              </div>
            </Col> */}
          </Row>
        </div>
      )
    }
  }

  return (
    <div className='account-details-card'>
      <div className='account-details-header'>
        {getHeader()}
      </div>
      <div className='account-details-body'>
        {getRows()}
      </div>
    </div>
  )
}

const propMap = state => ({
  accounts: state.accounts.accounts,
  selectedAccountId: state.accounts.selectedAccountId,
  balances: state.balances,
  cashflow: state.cashflow,
  accountAnalytics: state.accountAnalytics,
  cashflowAnalytics: state.cashflowAnalytics,
  latestBalance: state.latestBalance,
  comparisonBalance: state.comparisonBalance,
  analytics: state.analytics
})

export default connect(propMap, {
  getAccountAnalytics,
  getPrimaryAndComparisonBalance,
  getCashFlowAnalytics,
  fetchLastMonthCashFlow,
  fetchBalances
})(AccountDetails)
