import { SET_ERROR, HIDE_ERROR } from 'actions/constants'

const initState = {
  msg: '',
  error: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...action.payload,
        error: true
      }
    case HIDE_ERROR:
      return {
        msg: '',
        error: false
      }
    default:
      return state
  }
}
