import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import history from '../../history'

import Profile from './Profile'
import Workspace from './Worskpace'
import Api from './ApiTokens'

const SettingsTabs = () => {
  const [tabs, setTabs] = useState([
    {
      name: 'Workspace',
      type: 'index',
      active: true
    },
    {
      name: 'My profile',
      type: 'profile',
      active: false
    },
    {
      name: 'API Tokens',
      type: 'tokens',
      active: false
    }
  ])

  const getTabNav = () => {
    return tabs.map(tab => {
      const { name, type } = tab
      return (
        <div
          key={name}
          onClick={() => changeActiveTab(type)}
          className={isActiveTab(tab.type) ? 'tab tab-btn active' : 'tab tab-btn'}
        >
          <span className='tab-name text-capitalize'>{name}</span>
        </div>
      )
    })
  }

  const changeActiveTab = newType => {
    history.push(`/admin/settings/${newType}`)
    const index = tabs.findIndex(({ type }) => type === newType)
    const copy = [...tabs].map(tab => ({ ...tab, active: false }))
    copy[index].active = true
    setTabs(copy)
  }

  const isActiveTab = (tabType) => {
    const path = `/admin/settings/${tabType}`
    if (path === history.location.pathname) {
      return true
    }
    return false
  }

  return (
    <div className='h-100' style={{ padding: '0 1rem' }}>
      <Row style={{ margin: '2rem 0', marginBottom: '0' }}>
        <Col xs='12'>
          <h1 style={{ fontSize: '40px', marginBottom: '0' }}>Settings</h1>
        </Col>
      </Row>
      <Row>
        <Col md='9' className='tab-col' style={{ boxShadow: 'none' }}>
          <div className='tabs'>
            <div className='tab-nav mt-4'>
              {getTabNav()}
            </div>
          </div>
        </Col>

        <Router history={history}>
          <Switch>
            <Route path='/admin/settings/index'>
              <Workspace />
            </Route>
            <Route path='/admin/settings/profile'>
              <Profile />
            </Route>
            <Route path='/admin/settings/tokens'>
              <Api />
            </Route>
            <Redirect from='/admin/settings/' to='/admin/settings/index' />
          </Switch>
        </Router>
      </Row>
    </div>
  )
}

export default SettingsTabs
