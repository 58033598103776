import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Router, Switch, Redirect, Route } from 'react-router-dom'
import { connect, Provider } from 'react-redux'
import reduxStore from 'redux-store'
import { Auth, Hub } from 'aws-amplify'
import Cohere from 'cohere-js'

import AdminLayout from 'layouts/Admin'
import AnalyzeLayout from 'layouts/Analyze'
import Authorized from 'layouts/Authorized'
import Loading from 'layouts/Loading'
import LoginLayout from 'layouts/Login'
import SignupLayout from 'layouts/Signup'

import { fetchAndSetCompany, fetchCompanyUsers } from './actions/companyActions'
import { fetchLegalEntities } from 'actions/entityActions'
import { fetchAccounts } from 'actions/accountActions'
import { fetchBalances } from 'actions/balanceActions'
import { fetchDeliveryMethods, fetchAlerts } from 'actions/alertActions'
import { finishLogin, logout } from 'actions/authActions'
import { getCashReport } from 'actions/analyticsActions'

import 'assets/plugins/nucleo/css/nucleo.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/scss/argon-dashboard-react.scss'

import { getUser } from './services/auth'

import history from './history'
import config from './config'
import addCommandBarCallbacks from './commandBarCallbacks'

Auth.configure(config.cognito)
addCommandBarCallbacks()
Cohere.init('2kcmjyQgpR2hFv9NDxfrWDJs')

const AppClass = props => {
  const [loading, setLoading] = useState(true)

  const { isLoggedIn } = props.auth

  const successLogin = async user => {
    setLoading(true)
    window.CommandBar.boot({ id: user.attributes.sub })
    const routerFunc = url => history.push(url)
    window.CommandBar.addRouter(routerFunc)
    props.finishLogin(user)
    const company = await props.fetchAndSetCompany()
    console.log('props.company', company)
    if (!company) {
      history.push('/onboarding/company')
      return
    }
    if (company.total_entities === '0') {
      history.push('/onboarding/entities')
      return
    }
    await props.fetchLegalEntities()
    if (company.total_accounts === '0') {
      history.push('/onboarding/connect')
      return
    }

    if (company) {
      await Promise.all([
        props.fetchAccounts(),
        props.fetchBalances(),
        props.getCashReport(),
        props.fetchCompanyUsers()
      ])
    }
    setLoading(false)
  }

  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          await successLogin(data)
          break
        case 'signOut':
          props.logout()
          setLoading(false)
          break
        case 'signIn_failure':
          console.log('Sign in failure', data)
          break
        default:
          console.log('Unrecognised auth event')
      }
    })

    getUser().then(async userData => {
      if (userData) {
        await successLogin(userData)
      }
      if (props.auth.isLoggedIn === false) {
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getUser().then(userData => {
        if (!userData && isLoggedIn) {
          props.logout()
        }
      })
    }, 5000)

    return () => clearInterval(interval)
  })

  if (loading) {
    return <Loading />
  }

  if (isLoggedIn === false) {
    return <LoginLayout />
  }

  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/login'
          component={LoginLayout}
        />
        <Route
          path='/onboarding'
          component={SignupLayout}
        />
        <Route
          path='/admin'
          component={AdminLayout}
        />
        <Route
          path='/analyze'
          component={AnalyzeLayout}
        />
        <Route
          path='/authorized'
          component={Authorized}
        />
        <Redirect from='/' to='/admin/home' />
      </Switch>
    </Router>
  )
}

const propMap = state => ({
  auth: state.auth,
  company: state.company
})

const App = connect(propMap, {
  finishLogin,
  fetchAndSetCompany,
  fetchCompanyUsers,
  fetchLegalEntities,
  fetchBalances,
  fetchDeliveryMethods,
  fetchAlerts,
  fetchAccounts,
  getCashReport,
  logout
})(AppClass)

ReactDOM.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>,
  document.getElementById('root')
)
