import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import CustomInput from 'components/CustomInput'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import {
  createTeamUser
} from '../../actions/teamActions'

const MemberModal = ({ isOpen, toggle, createTeamUser }) => {
  const [loading, setLoading] = useState(false)
  const [firstNameValue, setFirstNameValue] = useState('')
  const [lastNameValue, setLastNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [roleValue, setRoleValue] = useState('')

  const createNewMember = async () => {
    setLoading(true)
    const body = {
      email: emailValue,
      firstName: firstNameValue,
      lastName: lastNameValue,
      role: roleValue
    }

    console.log('body', body)

    const res = await createTeamUser(body)

    console.log('res', res)

    setFirstNameValue('')
    setLastNameValue('')
    setEmailValue('')
    setRoleValue('')
    setLoading(false)
    toggle()
  }

  return (
    <Modal style={{ minWidth: '500px' }} toggle={toggle} isOpen={isOpen}>
      <ModalHeader>
        <span className='h2'>Add new member to team</span>
      </ModalHeader>
      <ModalBody className='justify-content-center align-item-center alert-body'>
        <CustomInput
          value={firstNameValue}
          name='firstName'
          labelName='First name'
          type='text'
          placeholder={'Team member\'s first name'}
          onChange={e => setFirstNameValue(e.target.value)}
        />

        <CustomInput
          value={lastNameValue}
          name='lastName'
          labelName='Last name'
          type='text'
          placeholder={'Team member\'s last name'}
          onChange={e => setLastNameValue(e.target.value)}
        />

        <CustomInput
          value={emailValue}
          name='email'
          labelName='Work name'
          type='text'
          placeholder='Work email address'
          onChange={e => setEmailValue(e.target.value)}
        />

        <CustomInput
          value={roleValue}
          valueLabel={roleValue}
          name='role'
          labelName='Team member role'
          type='select'
          options={[
            {
              value: 'admin',
              label: 'Admin'
            },
            {
              value: 'bookkeeper',
              label: 'Bookkeeper'
            }
          ]}
          onChange={e => setRoleValue(e.target.value)}
        />

      </ModalBody>
      <ModalFooter>
        <SecondaryButton
          text='Cancel'
          handler={toggle}
        />
        <PrimaryButton
          text='Invite member'
          handler={createNewMember}
          loading={loading}
        />
      </ModalFooter>
    </Modal>
  )
}

export default connect(undefined, { createTeamUser })(MemberModal)
