import {
  GET_TRANSACTIONS,
  TRANSACTIONS_LOADING,
  INITIALIZE_STATES
} from 'actions/constants'

const initState = {
  transactions: [],
  loading: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...action.payload,
        loading: false
      }
    case TRANSACTIONS_LOADING: {
      return {
        ...action.payload,
        loading: true
      }
    }
    case INITIALIZE_STATES:
      return initState
    default:
      return state
  }
}
