import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import MemberModal from './MemberModal'
import MemberList from './MemberList'
import Permissions from './Permissions'

const TeamTabs = props => {
  const [tabs, setTabs] = useState([
    {
      type: 'members',
      name: 'Members',
      active: true
    },
    {
      type: 'permissions',
      name: 'Permissions',
      active: false
    }
  ])

  const [modal, setModal] = useState(false)
  const [fields, setFields] = useState({
    permissions: [
      {
        value: false,
        name: 'admin_view_accounts',
        labelName: 'View all bank accounts',
        type: 'radio'
      },
      {
        value: false,
        name: 'admin_view_reports',
        labelName: 'View reports',
        type: 'radio'
      },
      {
        value: false,
        name: 'admin_download_statements',
        labelName: 'Download statements',
        type: 'radio'
      },
      {
        value: false,
        name: 'admin_create_alerts',
        labelName: 'Create alerts',
        type: 'radio'
      },
      {
        value: false,
        name: 'admin_connect_bank_accounts',
        labelName: 'Connect bank accounts',
        type: 'radio'
      },
      {
        value: false,
        name: 'admin_invite_members',
        labelName: 'Invite team members',
        type: 'radio'
      },
      {
        value: false,
        name: 'admin_manage_permissions',
        labelName: 'Manage permissions',
        type: 'radio'
      },
      {
        value: false,
        name: 'keeper_view_accounts',
        labelName: 'View all bank accounts',
        type: 'radio'
      },
      {
        value: false,
        name: 'keeper_view_reports',
        labelName: 'View reports',
        type: 'radio'
      },
      {
        value: false,
        name: 'keeper_download_statements',
        labelName: 'Download statements',
        type: 'radio'
      },
      {
        value: false,
        name: 'keeper_create_alerts',
        labelName: 'Create alerts',
        type: 'radio'
      },
      {
        value: false,
        name: 'keeper_connect_accounts',
        labelName: 'Connect bank accounts',
        type: 'radio'
      },
      {
        value: false,
        name: 'keeper_invite_members',
        labelName: 'Invite team members',
        type: 'radio'
      },
      {
        value: false,
        name: 'keeper_manage_permissions',
        labelName: 'Manage permissions',
        type: 'radio'
      }
    ]
  })

  const toggle = () => setModal(!modal)

  useEffect(() => {
    const copy = { ...fields }
    const { permissions } = props.team
    if (permissions) {
      Object.keys(permissions).forEach(k => {
        const idx = copy.permissions.findIndex(({ name }) => name === k)
        if (idx !== -1) {
          copy.permissions.splice(idx, 1, { ...copy.permissions[idx], name: k, value: permissions[k] })
        }
      })
    }
    setFields(copy)
  }, [props.team.permissions])

  const changeActiveTab = newType => {
    const idx = tabs.findIndex(({ type }) => type === newType)
    const copy = [...tabs].map(tab => ({ ...tab, active: false }))
    copy.splice(idx, 1, { ...copy[idx], active: true })
    setTabs(copy)
  }

  const handlePermissionChange = (name, value) => {
    const inverseValue = !JSON.parse(value)
    const body = { fieldName: name, fieldValue: inverseValue }
    props.updateRolePermissions(body)
  }

  const { type } = tabs.find(({ active }) => active)

  const getTabContent = () => {
    switch (type) {
      case 'members':
        return (
          <>
            <MemberModal
              isOpen={modal}
              toggle={toggle}
            />
            <MemberList />
            <div className='mt-4'>
              <PrimaryButton
                text='Add member'
                handler={toggle}
              />
            </div>
          </>
        )
      default:
        return (
          <Permissions
            {...props}
            fields={fields.permissions}
            handlePermissionChange={handlePermissionChange}
          />
        )
    }
  }

  return (
    <div>
      <Row style={{ margin: '1rem 0' }}>
        <h1 style={{ fontSize: '40px', marginBottom: '0', marginLeft: '1rem' }}>Team</h1>
      </Row>
      <Col lg='9' className='tab-col' style={{ boxShadow: 'none' }}>
        <div className='tabs'>
          <div className='tab-nav' style={{ marginLeft: 0 }}>
            {tabs.map(tab => {
              const { active, name, type } = tab
              return (
                <div
                  key={name}
                  onClick={() => changeActiveTab(type)}
                  className={active ? 'tab tab-btn active' : 'tab tab-btn'}
                >
                  <span className='tab-name text-capitalize'>{name}</span>
                </div>
              )
            })}
          </div>
          <div className='tab-content' style={{ marginTop: '1rem' }}>
            {getTabContent()}
          </div>
        </div>
      </Col>
    </div>
  )
}

export default TeamTabs
