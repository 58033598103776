import React, { useEffect, useState } from 'react'

const green = '#2dce89'
const red = '#f5365c'

const Dot = ({ color }) => {
  const [size, setSize] = useState('12px')

  const backgroundColor = color === 'green' ? green : red

  useEffect(() => {
    const size = window.innerWidth <= 1280 ? '8px' : '12px'
    setSize(size)
  }, [])

  return (
    <span style={{
      height: size,
      width: size,
      borderRadius: '50%',
      background: backgroundColor,
      display: 'inline-block'
    }}
    />
  )
}

export default Dot
