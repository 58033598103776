import React, { useState, useEffect } from 'react'
import { formatAmount } from '../util'

const Amount = ({ amount, currency, fontSize, center }) => {
  const [number, setNumber] = useState({
    integerPart: '0',
    fractionalPart: '00'
  })
  const [font, setFont] = useState({
    integerPart: '75px',
    fractionalPart: '50px'
  })

  // const currencyMatch = currencyList.find(({ value }) => value === currency)
  // const currencySymbol = currencyMatch ? currencyMatch.symbol : currency
  useEffect(() => {
    if (amount !== null) {
      const parsed = formatAmount(amount)
      setNumber(parsed)
    }
  }, [amount])

  useEffect(() => {
    setFont({
      integerPart: `${fontSize}px`,
      fractionalPart: `${fontSize - (fontSize / 3)}px`
    })
  }, [fontSize])

  const className = center ? 'text-align-center text-center' : ''

  return (
    <span style={{ fontSize: font.integerPart, margin: 0, display: 'inline-block' }} className={className}>
      {currency} {number.integerPart}<span style={{ fontSize: font.fractionalPart }}>.{number.fractionalPart}</span>
    </span>
  )
}

export default Amount
