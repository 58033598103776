import React, { useState } from 'react'
import { NavLink as NavLinkRRD } from 'react-router-dom'
import { Button, Collapse, Nav, Navbar, NavItem, NavLink, NavbarToggler, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import routes from '../../routes'
import SearchIcon from '../../assets/icons/search.svg'

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const closeCollapse = () => setIsOpen(false)

  const headerRoutes = routes.filter(r => r.header)

  const getLinks = (left = true) => {
    if (left) {
      return createLinks(headerRoutes.filter(r => r.isLink && !r.right))
    } else {
      return createLinks(headerRoutes.filter(r => r.isLink && r.right))
    }
  }

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            className='navbar-maytana'
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName='active-navbar'
          >
            {prop.name}
          </NavLink>
        </NavItem>
      )
    })
  }

  const getDropdownItems = () => {
    const filtered = headerRoutes.filter(r => !r.isLink)
    return filtered.map((prop, key) => (
      <DropdownItem key={key}>
        <NavLink
          onClick={closeCollapse}
          to={prop.layout + prop.path}
          tag={NavLinkRRD}
          activeClassName='active-navbar'
        >
          {prop.name}
        </NavLink>
      </DropdownItem>
    ))
  }

  return (
    <header>
      <Navbar
        className='navbar-dark'
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }} expand='md'
      >
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className='mr-auto' navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret />
              <DropdownMenu style={{ marginTop: '0.5rem', backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                {getDropdownItems()}
              </DropdownMenu>
            </UncontrolledDropdown>
            {getLinks()}
          </Nav>
          <Nav className='mr-2' navbar>{getLinks(false)}</Nav>
          <Nav navbar>
            <NavItem style={{ cursor: 'pointer' }} onClick={() => window.CommandBar.open()}>
              <div style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                position: 'relative',
                marginTop: '12px'
              }}
              >
                <img alt='Search Icon' src={SearchIcon} />
              </div>
            </NavItem>
            <NavItem style={{ paddingLeft: '1rem' }}>
              <NavLink
                className='navbar-maytana'
                to='/admin/transact'
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName='active-navbar'
              >
                <Button
                  style={{
                    fontFamily: 'sf-pro-text-light',
                    boxShadow: 'none',
                    borderRadius: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.0)',
                    color: '#fff'
                  }}
                >
                  Move Money
                </Button>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  )
}

export default Header
