import store from './redux-store'
import history from './history'
import { setSelectedAccount } from './actions/accountActions'
import { fetchFilterTransactions } from './actions/paginateTransactions'
import { bankTransactionCategories } from './util'

const addCommandBarCallbacks = () => {
  const contextArgs = {
    categories: bankTransactionCategories.map(cat => ({ label: cat.category, value: `${cat.id}_${cat.category.toLowerCase().split(' ').join('_')}` }))
  }
  window.CommandBar.addContext(contextArgs)
  const currencyArgs = ['USD', 'EUR', 'GPB', 'JPY', 'CNY', 'AUD', 'CAD', 'MXN', 'PRL']
  window.CommandBar.addContext({ currency: currencyArgs })

  window.CommandBar.addCallback('swap-account', args => {
    history.push('/admin/cash/accounts')
    store.dispatch(setSelectedAccount(args.Account.value))
  })

  window.CommandBar.addCallback('category-filter', args => {
    const { value } = args.categories
    if (!value) {
      console.error('Cannot filter by category, not enough data')
      return
    }
    const params = `userCategory=${value}`
    store.dispatch(fetchFilterTransactions(params))
  })
  window.CommandBar.addCallback('currency-filter', args => {
    const value = args.currency
    if (!value) {
      console.error('Cannot filter by currency, not enough data')
      return
    }
    const params = `filterCurrency=${value}`
    store.dispatch(fetchFilterTransactions(params))
  })
  window.CommandBar.addCallback('merchant-tx-filter', args => {
    const { value } = args.merchantValue
    if (!value) {
      console.error('Cannot filter by merchants, not enough data')
      return
    }
    const params = `merchantId=${value}`
    store.dispatch(fetchFilterTransactions(params))
  })
  window.CommandBar.addCallback('amount-filter', args => {
    const { amount, type: { value } } = args
    const params = `amt=${amount}&type=${value}`
    store.dispatch(fetchFilterTransactions(params))
  })
  window.CommandBar.addCallback('exclude-account', args => {
    const accountId = args.value.value
    const params = `exclude=${accountId}`
    store.dispatch(fetchFilterTransactions(params))
  })
  window.CommandBar.addCallback('single-account', args => {
    const accountId = args.value.value
    const params = `single=${accountId}`
    store.dispatch(fetchFilterTransactions(params))
  })
  window.CommandBar.addCallback('tx-filter-page', args => {
    //   initGroupedTxs()
    //   setSorted(true)
    //   setSortParams(`dateFrom=${args   .from}&dateTo=${args.to}`)
  })
}

// const { json: { merchants } } = await fetchMerchants()
// if (merchants) {
//   window.CommandBar.addContext({
//     merchants: merchants.map(m => ({ label: m.merchant_name || m.aggregator_merchant_name, value: m.merchant_id }))
//   })
// }

export default addCommandBarCallbacks

// const checkAndSetSortCopy = (parentObj, copy, colName) => {
//   const { sort } = parentObj
// //   setTriggerUpdate(!triggerUpdate)
// //   if (!sort.desc) {
// //     copy.sort.desc = true
// //     copy.sort.asc = false
// //     initGroupedTxs()
// //     setSortParams(`sortBy=${colName}&sortDir=ASC`)
// //     return
// //   }
// //   if (sort.desc) {
// //     copy.sort.asc = true
// //     copy.sort.desc = false
// //     initGroupedTxs()
// //     setSortParams(`sortBy=${colName}&sortDir=DESC`)
// //   }
// }

// const sortByDate = () => {
//   const colsCopy = [...headerCols]
//   const amtIdx = colsCopy.findIndex(({ text }) => text === 'amount')
//   colsCopy[amtIdx].sort = {
//     desc: false,
//     asc: false
//   }
//   const dateIdx = colsCopy.findIndex(({ text }) => text === 'date')
//   checkAndSetSortCopy(headerCols[dateIdx], colsCopy[dateIdx], 'date')
//   setSorted(true)
//   setHeaderCols(colsCopy)
// }

// const sortByAmount = () => {
//   const colsCopy = [...headerCols]
//   const dateIdx = colsCopy.findIndex(({ text }) => text === 'date')
//   colsCopy[dateIdx].sort = {
//     desc: false,
//     asc: false
//   }
//   const amtIdx = colsCopy.findIndex(({ text }) => text === 'amount')
//   checkAndSetSortCopy(headerCols[amtIdx], colsCopy[amtIdx], 'amount')
//   setSorted(true)
//   setHeaderCols(colsCopy)
// }

// const clearSort = () => {
//   setSortParams('')
//   const copy = [...headerCols]
//   const amtIdx = copy.findIndex(({ text }) => text === 'amount')
//   const dateIdx = copy.findIndex(({ text }) => text === 'date')
//   copy[amtIdx].sort = { desc: false, asc: false }
//   copy[dateIdx].sort = { desc: false, asc: false }
//   setTriggerUpdate(!triggerUpdate)
//   setHeaderCols(copy)
//   setSorted(false)
//   reload()
// }

// useEffect(() => {
//   setTxsArray([...txsArray, ...transactionsArray])
//   setTxCategories(getHeronCategories())
//   const colsCopy = [...headerCols]
//   const dateIdx = colsCopy.findIndex(({ text }) => text === 'date')
//   colsCopy[dateIdx].handleClick = sortByDate
//   const amtIdx = colsCopy.findIndex(({ text }) => text === 'amount')
//   colsCopy[amtIdx].handleClick = sortByAmount
//   setHeaderCols(colsCopy)
// }, [])

// const findTxIdxFromGrouped = txId => {
//   let foundIdx
//   let parentProp
//   if (sorted) {
//     foundIdx = groupedTxs.full.list.find(({ id }) => id === txId)
//     parentProp = 'full'
//     return { parentProp, foundIdx }
//   }
//   ['today', 'yesterday', 'week', 'month', 'year'].forEach(propKey => {
//     const idx = groupedTxs[propKey].list.findIndex(({ id }) => id === txId)
//     if (idx !== -1) {
//       foundIdx = idx
//       parentProp = propKey
//     }
//   })
//   return { parentProp, foundIdx }
// }

// const setUserTxCategory = async body => {
//   await categoryChange(body)
//   const copy = { ...groupedTxs }
//   const { foundIdx, parentProp } = findTxIdxFromGrouped(body.id)
//   if (foundIdx === -1) {
//     return
//   }
//   copy[parentProp].list[foundIdx].user_category = body.userCategory
//   setGroupedTxs(copy)
// }

// useEffect(() => {
//   if (!transactionsLoading && transactionsArray.length) {
//     let base
//     if (groupedTxs) {
//       base = groupedTxs
//     } else {
//       base = initGroupedTxs()
//     }
//     const copy = { ...base }
//     const grouped = groupNewTransactions(transactionsArray, copy, sorted)
//     setGroupedTxs(grouped)
//   }
// }, [transactionsArray, transactionsLoading])

// const toggleDetails = async tx => {
//   // dont allow details window close while saving notes
//   if (notesLoading) {
//     return
//   }
//   if (!tx) {
//     setDetails(null)
//     return
//   }
//   try {
//     toggleDetailsLoading(true)
//     // const token = await getAccessTokenSilently()
//     // const existingId = tx.merchant_id || tx.heron_merchant_id || tx.ntropy_merchant_id
//     // const { json: { result } } = await getTransactionDetails(token, existingId)
//     // tx.totalTx = result.numberOfTx
//     // tx.totalAmount = result.totalAmount
//     setDetails(tx)
//   } catch (err) {
//     console.log(err)
//   } finally {
//     toggleDetailsLoading(false)
//   }
// }
