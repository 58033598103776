import React from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { showError } from 'actions/errorActions'
import { setCompany } from 'actions/companyActions'
import {
  fetchDeliveryMethods,
  init,
  createAlert,
  createDeliveryMethod,
  updateAlertAccount,
  deleteAlert,
  fetchAlerts
} from 'actions/alertActions'
import AlertRow from './AlertRow'
import AlertDetails from './AlertDetails'
import { updateSlackChannel } from './helper'
import AlertModal from './AlertModal'
import CreateAlert from './CreateAlert'

class AlertsView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      alertModal: false,
      selectedAlert: null
    }
  }

  async componentDidMount () {
    const {
      fetchDeliveryMethods,
      fetchAlerts,
      alerts: { alerts, deliveryMethods },
      company
    } = this.props
    if (!alerts) {
      await fetchAlerts()
    }
    if (!deliveryMethods) {
      await fetchDeliveryMethods()
    }
    const params = new URLSearchParams(window.location.search)
    const webhookURL = params.get('webhookURL')
    const channelName = params.get('channelName')
    if (channelName && webhookURL) {
      try {
        await updateSlackChannel({ channelName, webhookURL })
        fetchDeliveryMethods()
      } catch (err) {
        console.log(err)
      }
    }
  }

  setSelectedAlert (selectedAlert) {
    this.setState({ selectedAlert })
  }

  componentDidUpdate (prevProps) {
    const { alerts } = this.props
    const { selectedAlert } = this.state
    if (alerts.alerts && selectedAlert) {
      const match = alerts.alerts.find(({ id }) => id === selectedAlert.id)
      if (match.accounts !== selectedAlert.accounts) {
        console.log(match)
        this.setState({ selectedAlert: match })
      }
    }
  }

  toggleModal () {
    this.setState({ alertModal: !this.state.alertModal })
  }

  async handleAlertDelete (id) {
    this.props.deleteAlert(id)
    this.setSelectedAlert(null)
  }

  // <AlertModal
  //   alerts={alerts}
  //   toggle={this.toggleModal.bind(this)}
  //   accounts={accounts}
  //   modalOpen={alertModal}
  //   deliveryMethods={deliveryMethods}
  //   createAlert={createAlert}
  //   createDeliveryMethod={createDeliveryMethod}
  // />

  render () {
    const { alerts, deliveryMethods } = this.props.alerts
    const {
      accounts: { accounts },
      updateAlertAccount,
      createDeliveryMethod,
      createAlert,
      company
    } = this.props
    const { selectedAlert, alertModal } = this.state
    console.log(this.props, accounts)
    if (!company || !accounts) {
      return <div />
    }
    return (
      <main>
        <CreateAlert
          alerts={alerts}
          toggle={this.toggleModal.bind(this)}
          accounts={accounts}
          modalOpen={alertModal}
          deliveryMethods={deliveryMethods}
          createAlert={createAlert}
          createDeliveryMethod={createDeliveryMethod}
        />
        <div className='dashboard alerts'>
          <Row>
            <Col md='12' lg='9'>
              <Row className='pl-4 pr-4'>
                <Col xs='12' className='mt-5 mb-4'>
                  <h1 style={{ fontWeight: 700, fontSize: '40px' }}>Alerts</h1>
                </Col>
                <Col xs='7'>
                  <h3>All alerts</h3>
                </Col>
                <Col xs='5'>
                  <h3 className='text-right text-primary' style={{ cursor: 'pointer' }} onClick={() => this.setState({ alertModal: true })}>Create alert</h3>
                </Col>
              </Row>
              {
                  alerts && alerts.length
                    ? (
                      <div className='active-alerts'>
                        {
                          alerts.map((alert, idx) => (
                            <AlertRow
                              deliveryMethods={deliveryMethods}
                              accounts={accounts}
                              alert={alert}
                              key={idx}
                              selectAlert={this.setSelectedAlert.bind(this)}
                              alerts={alerts}
                            />
                          ))
                        }
                      </div>
                      )
                    : <div />
                }
            </Col>
            <Col md='12' lg='3'>
              {
                selectedAlert
                  ? (
                    <AlertDetails
                      selectedAlert={selectedAlert}
                      accounts={accounts}
                      updateAlertAccount={updateAlertAccount}
                      deleteAlert={this.handleAlertDelete.bind(this)}
                    />
                    )
                  : <div />
              }
            </Col>
          </Row>
        </div>
      </main>
    )
  }
}

const propMap = state => ({
  alerts: state.alerts,
  accounts: state.accounts,
  company: state.company
})

export default connect(propMap, {
  fetchDeliveryMethods,
  createDeliveryMethod,
  fetchAlerts,
  createAlert,
  updateAlertAccount,
  deleteAlert,
  showError,
  init,
  setCompany
})(AlertsView)
