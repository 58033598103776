import { getRequest } from 'API/ApiClient'
import { UPDATE_BALANCES } from './constants'

export const fetchBalances = () => async dispatch => {
  try {
    const { json } = await getRequest({ path: '/api/accounts' })
    let total = 0
    json.accounts.forEach(account => {
      total += account.converted_balance
    })
    dispatch({ type: UPDATE_BALANCES, payload: { display: total.toFixed(2).toLocaleString(), total } })
  } catch (error) {
    console.error(error)
  }
}

export const updateBalances = (display, total) => (dispatch, getState) => {
  const { balances } = getState()
  dispatch({ type: UPDATE_BALANCES, payload: { ...balances, display, total } })
}
