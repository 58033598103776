import React from 'react'
import { Card, Row } from 'reactstrap'

const LegalEntityCard = ({
  flag_url: flag,
  legal_business_name: name,
  business_address_city: city,
  business_address_zip: zip,
  business_type: type,
  country_of_incorporation: country,
  holding,
  setLegalSelectedLegalEntity
}) => {
  const width = holding ? { width: '50%', margin: 'auto' } : { width: '300px', paddingTop: 0 }

  return (
    <Card style={width} className='legal-entity-card'>
      <Row className='align-items-center' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <div className='col'>
          <img src={flag} height='80px' />
        </div>
        <div className='col'>
          <h2 style={{ fontWeight: '700', textAlign: 'left' }}>{name}</h2>
          <h4 style={{ fontWeight: '500', textAlign: 'left' }}>{city}, {zip}</h4>
          <div
            className='border select-btn'
            onClick={() => setLegalSelectedLegalEntity({
              flag_url: flag,
              legal_business_name: name,
              business_address_city: city,
              business_address_zip: zip,
              country
            })}
          >
            Select Entity
          </div>
        </div>
      </Row>
    </Card>
  )
}

export default LegalEntityCard
