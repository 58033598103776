import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import {
  fetchTeamUsers,
  createTeamUser,
  updateRolePermissions,
  fetchCompanyPermissions
} from '../../actions/teamActions'
import TeamTabs from './TeamTabs'

const TeamView = (props) => {
  useEffect(() => {
    props.fetchTeamUsers()
    props.fetchCompanyPermissions()
  }, [])

  return (
    <main>
      <div className='dashboard team h-100'>
        <Row style={{ overflowY: 'scroll', overflowX: 'hidden', height: '100%' }}>
          <Col xs='12' style={{ padding: '0 2rem', paddingRight: '0' }}>
            <TeamTabs
              team={props.team}
              updateRolePermissions={props.updateRolePermissions}
              createTeamUser={props.createTeamUser}
            />
          </Col>
        </Row>
      </div>
    </main>
  )
}

const propMap = state => ({
  team: state.team,
  company: state.company.company
})

export default connect(propMap, { fetchTeamUsers, fetchCompanyPermissions, createTeamUser, updateRolePermissions })(TeamView)
