import { FileUploadSection } from 'components/FileUpload/FileUploadSection'
import React, { useRef, useState } from 'react'
import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'

export const FileUploadModal = ({ isOpen, toggle, upload }) => {
  const [file, setFile] = useState(null)
  const [isDragging, setDragging] = useState(false)
  const [loading, setLoading] = useState(false)

  const [selectedInstitution, setSelectedInstitution] = useState({
    name: 'LHV',
    logo: 'https://s3.eu-central-1.amazonaws.com/alphablues/assets/lhv/lhv-logo.png'
  })

  const allInstitutions = [
    {
      name: 'LHV',
      logo: 'https://s3.eu-central-1.amazonaws.com/alphablues/assets/lhv/lhv-logo.png'
    }
  ]

  const inputRef = useRef()

  const triggerInput = fileType => {
    inputRef.current.click()
  }

  const handleDragDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    const lastIdx = e.dataTransfer.files.length - 1
    setFile(e.dataTransfer.files[lastIdx])
    setDragging(false)
  }

  const handleOnDragOver = e => {
    e.preventDefault()
    setDragging(true)
  }

  const handleUploadClick = async () => {
    setLoading(true)
    const fd = new window.FormData()
    const { name, logo } = selectedInstitution
    fd.append('institution', name)
    fd.append('logo', logo)
    fd.append('accFile', file)
    const resp = await upload(fd)
    setLoading(false)
    if (resp) {
      toggle()
    }
  }

  const handleChange = e => {
    const { value } = e.target
    const institution = allInstitutions.find(({ name }) => name === value)
    setSelectedInstitution(institution)
  }

  const handleFileUpload = e => {
    const { files } = e.target
    const lastIdx = files.length - 1
    setFile(files[lastIdx])
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <span className='title h4'>Upload CSV or JSON transactions file</span>
      </ModalHeader>
      <ModalBody>
        <form method='post'>
          <Label>Select institution</Label>
          <Input
            type='select'
            onChange={handleChange}
          >
            {
              allInstitutions.map((inst, i) => (
                <option
                  key={i}
                  name={inst.name}
                >
                  {inst.name}
                </option>)
              )
            }
          </Input>
          <FileUploadSection
            isDragging={isDragging}
            typeSupported='.csv and .json files supported'
            setDragging={setDragging}
            handleDragDrop={handleDragDrop}
            handleOnDragOver={handleOnDragOver}
            triggerInput={triggerInput}
            showInstruction
            file={file}
            fileText={file ? 'Transactions uploaded' : 'Upload bank statement'}
          />
          <input
            style={{ display: 'none' }}
            type='file'
            name='accFile'
            onChange={handleFileUpload}
            ref={inputRef}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        {
          loading
            ? <Spinner className='float-right' color='primary' />
            : <div className='btn btn-primary' onClick={handleUploadClick}>Save</div>
        }
      </ModalFooter>
    </Modal>
  )
}

export default FileUploadModal
