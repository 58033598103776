import React from 'react'
import { Modal, Spinner, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { yapilyInstitutions } from './helper'

export const YapilyModal = ({ yapilyModal, loading, yapilyModalToggle, selectedYapilyInst, selectYapilyInstitution, getYapilyAuthData }) => {
  return (
    <Modal isOpen={yapilyModal} toggle={yapilyModalToggle}>
      <ModalHeader toggle={yapilyModalToggle}>
        <span className='title h4'>Select your bank</span>
      </ModalHeader>
      <ModalBody style={{ maxHeight: '500px', overflow: 'auto' }}>
        {yapilyInstitutions.map((inst, i) => (
          <div
            className={selectedYapilyInst === inst.id ? 'p-2 mb-2 rounded shadow border-primary border' : 'p-2 mb-2 rounded border-0'}
            key={i}
            style={{
              cursor: 'pointer'
            }}
            onClick={() => selectYapilyInstitution(inst.id)}
          >
            <div className='mr-2 d-inline-block' style={{ width: '16%' }}>
              <img src={inst.logo} style={{ maxHeight: '50px', width: 'auto' }} />
            </div>
            <div className='d-inline-block w-75'>
              <span className='text-dark d-block'>
                {inst.name}
              </span>
            </div>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        {loading
          ? <Spinner />
          : <div className='btn btn-primary w-100 text-center cursor' onClick={getYapilyAuthData}>Select Bank</div>}
      </ModalFooter>
    </Modal>
  )
}

export default YapilyModal
