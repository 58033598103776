import React, { useEffect, useState } from 'react'
import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'

export const ReauthModal = ({ bankName, linkId, existingFields, submit, loading, isOpen, toggle }) => {
  const [fields, setFields] = useState([])

  useEffect(() => {
    if (existingFields) {
      setFields(existingFields.map(field => ({ ...field, value: '' })))
    }
  }, [existingFields])

  const handleChange = e => {
    if (loading) {
      return
    }
    const { name, value } = e.target
    const targetField = fields.find(({ name: fn }) => fn === name)
    const idx = fields.indexOf(targetField)
    const newFields = [...fields]
    targetField.value = value
    newFields.splice(idx, 1, targetField)
    setFields(newFields)
  }

  const getLoginForm = () => {
    return (
      fields.map((inp, i) => {
        const { name, type, label, placeholder } = inp
        return (
          <div key={i} className='mb-2'>
            <Label>{label}</Label>
            <Input
              name={name}
              placeholder={placeholder}
              type={type}
              onChange={handleChange}
            />
          </div>
        )
      })
    )
  }

  const handleSubmit = () => {
    const formattedFields = {}
    fields.forEach(field => {
      formattedFields[field.name] = field.value
    })
    const body = {
      fields: formattedFields,
      linkId
    }
    submit(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h1 className='title h4'>{'Reauthenticate for ' + bankName}</h1>
      </ModalHeader>
      <ModalBody style={{ maxHeight: '500px', overflow: 'auto' }}>
        {getLoginForm()}
      </ModalBody>
      <ModalFooter>
        {!loading
          ? (
            <div className='btn btn-primary' onClick={handleSubmit}>
              Reauthenticate
            </div>
            )
          : <Spinner />}
      </ModalFooter>
    </Modal>
  )
}

export default ReauthModal
