import React from 'react'
import { Modal, Spinner, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ebInstitutions } from './helper'

export const EbModal = ({ ebModal, loading, country, ebModalToggle, selectedEbInstitution, selectEbInstitution, startEbAuth }) => {
  const countryInstitutions = ebInstitutions.filter(inst => inst.country === country)

  return (
    <Modal isOpen={ebModal} toggle={ebModalToggle}>
      <ModalHeader toggle={ebModalToggle}>
        <h1 className='title h4'>Select your bank</h1>
      </ModalHeader>
      <ModalBody style={{ maxHeight: '500px', overflow: 'auto' }}>
        {countryInstitutions.map((inst, i) => (
          <div
            className={selectedEbInstitution === inst ? 'p-2 mb-2 rounded shadow border-primary border' : 'p-2 mb-2 rounded border-lighter border'}
            key={i}
            style={{
              cursor: 'pointer'
            }}
            onClick={() => selectEbInstitution(inst)}
          >
            <div className='mr-2 d-inline-block'>
              <img src={inst.logo} style={{ maxHeight: '50px', width: 'auto' }} />
            </div>
            <div className='mr-2 d-inline-block w-75'>
              <span className='text-dark d-block'>
                {inst.title}
              </span>
            </div>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        {loading
          ? <Spinner />
          : <div className='btn btn-primary w-100 text-center cursor' onClick={startEbAuth}>Select Bank</div>}
      </ModalFooter>
    </Modal>
  )
}

export default EbModal
