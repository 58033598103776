import { putRequest, getRequest, postRequest } from 'API/ApiClient'
import { Auth } from 'aws-amplify'

import { GET_TEAM, UPDATE_TEAM, TEAM_LOADING } from './constants'
import { showError } from './errorActions'

export const fetchTeamUsers = () => async dispatch => {
  dispatch({ type: TEAM_LOADING })
  const active = await Auth.currentAuthenticatedUser()
  return getRequest({ path: '/api/company/users-list' }).then(res => {
    const userList = res.json.users.filter(({ sub }) => sub !== active.attributes.sub)
    dispatch({ type: GET_TEAM, payload: { users: userList } })
    return userList
  }).catch(err => {
    showError(err.msg || 'Could not fetch team')(dispatch)
    dispatch({ type: GET_TEAM, payload: { users: [] } })
  })
}

export const fetchCompanyPermissions = () => async (dispatch, getState) => {
  const { team: payload } = getState()
  return getRequest({ path: '/api/company/permissions' }).then(res => {
    payload.permissions = { ...res.json.permissions }
    dispatch({ type: GET_TEAM, payload })
  }).catch(err => {
    showError(err.msg || 'Could not fetch team permissions')(dispatch)
    dispatch({ type: GET_TEAM, payload: { permissions: null } })
  })
}

export const createTeamUser = ({ firstName, lastName, email, role }) => async dispatch => {
  try {
    const body = { firstName, lastName, email, role }
    const res = await postRequest({ path: '/api/users', body })
    window.analytics.track('Invite team member', { body })
    dispatch({ type: UPDATE_TEAM, payload: res.json.users })
  } catch (error) {
    console.error(error)
    showError(error.msg || error.message || 'Could not add team member')
  }
}

export const updateRolePermissions = body => async (dispatch, getState) => {
  const { team: payload } = getState()
  return putRequest({ path: '/api/company/permissions', body }).then(res => {
    payload.permissions = res.json.permissions
    dispatch({ type: UPDATE_TEAM, payload })
  }).catch(err => {
    showError(err.msg || 'Could not update permissions')(dispatch)
    dispatch({ type: GET_TEAM, payload })
  })
}
