import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { usePlaidLink } from 'react-plaid-link'
import YapilyModal from 'components/Signup/YapilyModal'
import EbModal from 'components/Signup/EbModal'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { fetchPlaidLinkToken, onPlaidSuccess, onPlaidExit, openBelvoWidget, openSaltEdge, openYapily, openEb, openBudgetInsight } from '../../services/connect'

const ConnectStep = ({ entities }) => {
  const [country, setCountry] = useState('')
  const [yapilyModal, setYapilyModal] = useState(false)
  const [ebModal, setEbModal] = useState(false)
  const [ebLoading, setEbLoading] = useState(false)
  const [selectedEbInstitution, selectEbInstitution] = useState(null)
  const [yapilyLoading, setYapilyLoading] = useState(false)
  const [selectedYapilyInst, setSelectedYapilyInst] = useState('')
  const [plaidToken, setPlaidToken] = useState('')

  useEffect(() => {
    fetchPlaidLinkToken(setPlaidToken)
  }, [])

  const handleConnectAccount = () => {
    const countryName = entities.legalEntities[entities.legalEntities.length - 1].country_of_incorporation
    setCountry(country)
    switch (countryName) {
      case 'FRA':
        openBudgetInsight()
        break
      case 'USA':
        openPlaid()
        break
      case 'GBR':
        yapilyModalToggle()
        break
      case 'IRL':
        openSaltEdge('IE')
        break
      case 'CAN':
        openPlaid()
        break
      case 'BRL':
        openBelvoWidget('BR')
        break
      case 'COL':
        openBelvoWidget('CO')
        break
      case 'MEX':
        openBelvoWidget('MX')
        break
      case 'EST':
        ebModalToggle('EE')
        break
      case 'FIN':
        ebModalToggle('FIN')
        break
      case 'ESP':
        openSaltEdge('ES')
        break
      default:
        window.alert('Country not supported yet!')
    }
  }

  const getYapilyAuthData = async () => {
    if (selectedYapilyInst) {
      setYapilyLoading(true)
      try {
        await openYapily(selectedYapilyInst)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const yapilyModalToggle = () => {
    setYapilyModal(!yapilyModal)
  }

  const ebModalToggle = (country = '') => {
    setCountry(country)
    setEbModal(!ebModal)
  }

  const startEbAuth = async () => {
    setEbLoading(true)
    try {
      await openEb(country, selectedEbInstitution)
    } catch (err) {
      setEbLoading(false)
    }
  }

  const { open: openPlaid, error } = usePlaidLink({ token: plaidToken, onSuccess: onPlaidSuccess, onExit: onPlaidExit })
  if (error) {
    console.error(error)
  }

  return (
    <div className='step-wrapper connect'>
      <Row className='mt-4'>
        <Col xs='12'>
          <PrimaryButton
            text='Connect Account'
            handler={() => handleConnectAccount()}
          />
        </Col>
      </Row>
      <div id='belvo' />
      <Row className='mx-auto w-90 justify-content-center'>
        <YapilyModal
          yapilyModal={yapilyModal}
          loading={yapilyLoading}
          yapilyModalToggle={yapilyModalToggle}
          selectedYapilyInst={selectedYapilyInst}
          selectYapilyInstitution={setSelectedYapilyInst}
          getYapilyAuthData={getYapilyAuthData}
        />
        <EbModal
          loading={ebLoading}
          country={country}
          ebModalToggle={ebModalToggle}
          ebModal={ebModal}
          selectedEbInstitution={selectedEbInstitution}
          selectEbInstitution={selectEbInstitution}
          startEbAuth={startEbAuth}
        />
      </Row>
    </div>
  )
}

const propMap = state => ({
  entities: state.entities
})

export default connect(propMap)(ConnectStep)
