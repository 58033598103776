import { Auth } from 'aws-amplify'

export async function federatedSignIn () {
  try {
    const user = await Auth.federatedSignIn({ provider: 'Google' })
    return user
  } catch (error) {
    console.log('error signing in', error)
  }
}

export async function signOut () {
  try {
    const user = await getUser()
    if (user) await Auth.signOut()
  } catch (error) {
    console.log('error signing out: ', error)
  }
}

export const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user
  } catch (error) {
    return null
  }
}

export const signIn = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password)
    return user
  } catch (error) {
    console.log('error signing in', error)
    throw error
  }
}

export const completeNewPassword = async (username, newPassword) => {
  try {
    const user = await Auth.completeNewPassword(username, newPassword)
    return user
  } catch (error) {
    console.log('error signing in', error)
    throw error
  }
}
