import React from 'react'
import { Button } from 'reactstrap'

const ConnectCard = ({ logo, handler, width }) => {
  const imageStyle = width ? { width: width } : { height: '100px' }
  return (
    <div style={{
      border: '1px solid rgba(0, 0, 0, 0.05)',
      backgroundColor: 'white',
      borderRadius: 0,
      boxShadow: 'none',
      width: '150px',
      height: '150px'
    }}
    >
      <Button
        style={{
          boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
          backgroundColor: 'white',
          borderRadius: 0,
          width: '150px',
          height: '150px'
        }}
        onClick={handler}
      >
        <img src={logo} style={imageStyle} />
      </Button>
    </div>
  )
}

export default ConnectCard
