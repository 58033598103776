import { getRequest, putRequest } from 'API/ApiClient'
import {
  GET_COMPANY,
  COMPANY_LOADING,
  SET_COMPANY
} from './constants'

export const fetchAndSetCompany = () => async dispatch => {
  dispatch({ type: COMPANY_LOADING })
  try {
    const { json: { company } } = await getRequest({ path: '/api/company' })
    dispatch({ type: GET_COMPANY, payload: company })
    return company
  } catch (error) {
    console.error(error)
  }
}

export const fetchCompanyUsers = () => async dispatch => {
  try {
    const { json: { users } } = await getRequest({ path: '/api/company/users-list' })
    console.log('users', users)
    dispatch({ type: 'SET_COMPANY_USERS', payload: users })
  } catch (error) {
    console.error(error)
  }
}

export const refreshCompany = () => async dispatch => {
  try {
    const res = await getRequest({ path: '/api/company' })
    dispatch({ type: GET_COMPANY, payload: { company: res.json.company } })
    return res.json.company
  } catch (error) {
    console.error(error)
  }
}

export const updateCompany = body => async dispatch => {
  try {
    const res = await putRequest({ path: '/api/company', body })
    dispatch({ type: GET_COMPANY, payload: { company: res.json.company } })
    return res.json.company
  } catch (error) {
    console.error(error)
  }
}

export const setCompany = company => (dispatch, getState) => {
  const prev = getState()
  const prevData = { ...prev.company, company: company }
  dispatch({ type: SET_COMPANY, payload: prevData })
}
