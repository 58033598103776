import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import AccountsHeader from './AccountsHeader'
import { fetchAccounts } from 'actions/accountActions'
import AccountCard from 'components/Accounts/AccountCard'

import AccountDetails from './AccountDetails'

const Accounts = props => {
  const [balanceCurrency, setBalanceCurrency] = useState('USD')

  useEffect(() => {
    props.fetchAccounts()
  }, [])

  return (
    <>
      <div className='item2 dashboard-content'>
        <div className='accounts-chart'>
          <AccountsHeader />
        </div>
        <div className='accounts-list'>
          {props.accounts.accounts.map(account => {
            return (
              <div key={account.id} style={{ display: 'inline-block' }}>
                <AccountCard account={account} />
              </div>
            )
          })}
        </div>
      </div>
      <div className='item3'>
        <div className='tab-sidebar-wrapper'>
          <div className='tab-sidebar' style={{ paddingRight: '1.5rem' }}>
            <AccountDetails
              setCurrency={setBalanceCurrency}
              balanceCurrency={balanceCurrency}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const propMap = state => ({
  accounts: state.accounts
})

export default connect(propMap, { fetchAccounts })(Accounts)
