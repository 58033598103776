import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'

const Permissions = (props) => {
  const [admin, setAdmin] = useState([])
  const [keeper, setKeeper] = useState([])

  console.log(props.fields)

  useEffect(() => {
    setAdmin(props.fields.filter(({ name }) => name.includes('admin')))
    setKeeper(props.fields.filter(({ name }) => name.includes('keeper')))
  }, [props.fields])

  return (
    <Row>
      <Col md='4'>
        <div className='permissions-card'>
          <h2 className='mb-4'>
            Admin
          </h2>
          {
            admin.map((field, idx) => (
              <Row key={idx} className='py-2' style={{ width: '90%' }}>
                <Col sm='9'>
                  <span>{field.labelName}</span>
                </Col>
                <Col sm='3'>
                  <label key={idx} className='switch'>
                    <input
                      type='checkbox'
                      value={field.value}
                      defaultChecked={field.value}
                      onChange={(e) => props.handlePermissionChange(field.name, e.target.value)}
                    />
                    <span className='slider round' />
                  </label>
                </Col>
              </Row>
            ))
          }
        </div>
      </Col>
      <Col md='4'>
        <div className='permissions-card'>
          <h2 className='mb-4'>
            Bookkeeper
          </h2>
          {
            keeper.map((field, idx) => (
              <Row key={idx} className='py-2' style={{ width: '90%' }}>
                <Col sm='9'>
                  <span>{field.labelName}</span>
                </Col>
                <Col sm='3'>
                  <label key={idx} className='switch'>
                    <input
                      type='checkbox'
                      value={field.value}
                      defaultChecked={field.value}
                      onChange={(e) => props.handlePermissionChange(field.name, e.target.value)}
                    />
                    <span className='slider round' />
                  </label>
                </Col>
              </Row>
            ))
          }
        </div>
      </Col>
      <Col md='4' />
    </Row>
  )
}

export default Permissions
