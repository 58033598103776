import { dispatch } from 'd3'
import {
  AUTHORIZED_LOADING,
  STOP_AUTHORIZED_LOADING,
  SET_EVENT,
  SET_INSTITUTION
} from './constants'

export const setInstitutionNameAndLogo = ({ institutionName, institutionLogoUrl }) => dispatch => {
  dispatch({ type: SET_INSTITUTION, payload: { institutionName, institutionLogoUrl } })
}

export const setEvent = (type, bool) => (dispatch, getState) => {
  const prev = getState()
  const { event } = prev.authorization
  event[type] = bool
  dispatch({ type: SET_EVENT, payload: { event } })
}

export const initAuthorizedLoading = () => dispatch => {
  dispatch({ type: AUTHORIZED_LOADING })
}

export const stopAuthorizedLoading = () => {
  dispatch({ type: STOP_AUTHORIZED_LOADING })
}
