import { getRequest } from 'API/ApiClient'
import {
  SET_CONSOLIDATED_CASHFLOW,
  SET_ACCOUNT_CASHFLOW,
  SET_LAST_MONTH_CONSOLIDATED_CASHFLOW
} from './constants'

export const fetchConsolidatedCashflow = ({ currency, period }) => async dispatch => {
  let path = `/api/analytics/cashflow/?currency=${currency}`
  if (period) {
    path += `&period=${period}`
  }

  try {
    const res = await getRequest({ path })
    const payload = { currency, period, cashflow: res.json.cashflow }

    dispatch({
      type: SET_CONSOLIDATED_CASHFLOW,
      payload
    })
    return payload
  } catch (error) {
    console.error(error)
  }
}

export const fetchAccountCashflow = ({ currency, accountId, period }) => async dispatch => {
  let path = `/api/analytics/cashflow?currency=${currency}&account=${accountId}`
  if (period) {
    path += `&period=${period}`
  }

  try {
    const res = await getRequest({ path })
    const payload = { currency, period, accountId, cashflow: res.json.cashflow }

    dispatch({
      type: SET_ACCOUNT_CASHFLOW,
      payload
    })
    return payload
  } catch (error) {
    console.error(error)
  }
}

export const fetchLastMonthCashFlow = ({ currency, period }) => async dispatch => {
  let path = `/api/analytics/cashflow/last/?currency=${currency}`
  if (period) {
    path += `&period=${period}`
  }

  try {
    const res = await getRequest({ path })
    const payload = { currency, period, cashflow: res.json }

    dispatch({
      type: SET_LAST_MONTH_CONSOLIDATED_CASHFLOW,
      payload
    })
    return payload
  } catch (error) {
    console.error(error)
  }
}
