import { Auth } from 'aws-amplify'
import { apiOrigin } from 'config'

export const requestTimeout = (time, promise) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('The request timed out'))
    }, time)
    promise.then(resolve, reject)
  })
}

export const fetchRetry = async (url, options, retries = 3, backoff = 5000) => {
  try {
    const response = await requestTimeout(30000, window.fetch(url, options))
    const json = await response.json()
    if (response.status >= 200 && response.status <= 299) {
      return {
        json,
        response
      }
    } else {
      const { msg } = json
      if (msg) {
        retries = 0
      }
      throw new Error(msg || response.status)
    }
  } catch (err) {
    if (retries === 0) {
      throw err
    } else {
      return setTimeout(async () => {
        return fetchRetry(url, options, retries - 1, backoff * 2)
      }, backoff)
    }
  }
}

export const getHeaders = (token) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
}

export const getRequest = async ({ path, signal, params = '' }) => {
  const authRes = await Auth.currentSession()
  const { jwtToken } = authRes.getAccessToken()
  signal = signal ? new window.AbortController().signal : signal
  return fetchRetry(`${apiOrigin}${path}${params}`, {
    signal,
    headers: getHeaders(jwtToken)
  })
}

export const downloadRequest = async ({ path, signal, params = '' }) => {
  const authRes = await Auth.currentSession()
  const { jwtToken } = authRes.getAccessToken()
  signal = signal ? new window.AbortController().signal : signal
  return fetchRetry(`${apiOrigin}${path}${params}`, {
    signal,
    headers: getHeaders(jwtToken),
    responseType: 'arraybuffer'
  })
}

export const fileUpload = async ({ path, body, params = '' }) => {
  const authRes = await Auth.currentSession()
  const { jwtToken } = authRes.getAccessToken()
  return fetchRetry(`${apiOrigin}${path}${params}`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken}`
    },
    body: body
  })
}

export const postRequest = async ({ path, body, signal, params = '' }) => {
  const authRes = await Auth.currentSession()
  const { jwtToken } = authRes.getAccessToken()
  signal = signal ? new window.AbortController().signal : signal
  return fetchRetry(`${apiOrigin}${path}${params}`, {
    method: 'post',
    signal,
    headers: getHeaders(jwtToken),
    body: JSON.stringify(body)
  })
}

// Post request that does not catch errors by default
export const rawPostRequest = async ({ path, body, signal, params = '' }) => {
  const authRes = await Auth.currentSession()
  const { jwtToken } = authRes.getAccessToken()
  signal = signal ? new window.AbortController().signal : signal
  return window.fetch(`${apiOrigin}${path}${params}`, {
    method: 'post',
    signal,
    headers: getHeaders(jwtToken),
    body: JSON.stringify(body)
  })
}

export const putRequest = async ({ path, body, params = '' }) => {
  const authRes = await Auth.currentSession()
  const { jwtToken } = authRes.getAccessToken()
  return fetchRetry(`${apiOrigin}${path}${params}`, {
    method: 'put',
    headers: getHeaders(jwtToken),
    body: JSON.stringify(body)
  })
}

export const deleteRequest = async ({ path, body, params = '' }) => {
  const authRes = await Auth.currentSession()
  const { jwtToken } = authRes.getAccessToken()
  return fetchRetry(`${apiOrigin}${path}${params}`, {
    method: 'delete',
    headers: getHeaders(jwtToken),
    body: JSON.stringify(body)
  })
}
