import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

export const AccountSelect = ({ setAccount, accounts, selectedAccount }) => {
  const [isOpen, toggleOpen] = useState(false)

  const getAccountSelections = () => {
    return (
      <div style={{ position: 'relative' }}>
        <div className='accounts-dropdown'>
          {
            accounts.map((acc) => (
              <div
                key={acc.id}
                onClick={() => { setAccount(acc); toggleOpen(false) }}
                className='account-selection'
              >
                {acc.name}
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  const {
    iso_currency_code: currency,
    current_balance: balance,
    logo_url: logo,
    name
  } = selectedAccount || accounts[0]

  return (
    <div className='account-select'>
      <div className='selected-account' style={{ position: 'relative' }} onClick={() => toggleOpen(!isOpen)}>
        <img className='bank-logo rounded' src={logo} alt='account logo' />
        <div className='acc-details'>
          <span className='acc-name'>{name}</span>
          <span className='acc-balance'>{currency} {balance.toLocaleString()}</span>
        </div>
        <FontAwesomeIcon icon={faCaretDown} className='custom-caret' size='sm' />
      </div>
      {isOpen ? getAccountSelections() : undefined}
    </div>
  )
}
