import React from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'

const MemberList = props => {
  return (
    <div className='member-list'>
      <Row className='member-header-row'>
        <Col md={4}>
          <span className='member-header-text'>
            Email
          </span>
        </Col>
        <Col md={4}>
          <span className='member-header-text'>
            Name
          </span>
        </Col>
        <Col md={4}>
          <span style={{ textAlign: 'right' }} className='member-header-text'>
            Role
          </span>
        </Col>
      </Row>
      {props.company.users.map(user => {
        return (
          <div key={user.sub} className='member-row'>
            <Row>
              <Col md='4'>
                <span className='member-attribute-text'>
                  {user.email}
                </span>
              </Col>
              <Col md='4'>
                <span className='member-attribute-text'>
                  {user.firstName} {user.lastName}
                </span>
              </Col>
              <Col md='4'>
                <span className='member-attribute-text d-block text-right'>
                  {user.role}
                </span>
              </Col>
            </Row>
          </div>
        )
      })}
    </div>
  )
}

const propMap = state => ({
  company: state.company
})

export default connect(propMap)(MemberList)
