import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ebRedirect, biRedirectAndCall, weavRedirectAndCall, yapilyRedirectAndCall, saltEdgeRedirectAndCall } from 'services/connect'
import { setInstitutionNameAndLogo } from 'actions/authorizedActions'
import { showError } from 'actions/errorActions'
import Mountain from 'assets/img/login-layout.webp'
import Connection from 'assets/ux/Connection.webp'

const Analyzing = props => {
  const [yapilyCalled, setYapilyCalled] = useState(false)
  const [ebCalled, setebCalled] = useState(false)
  const [biCalled, setbiCalled] = useState(false)
  const [seCalled, setSeCalled] = useState(false)
  const [weavCalled, setWeavCalled] = useState(false)

  useEffect(() => {
    if (!ebCalled) {
      ebRedirect(setebCalled)
    }
    if (!yapilyCalled) {
      yapilyRedirectAndCall(setYapilyCalled)
    }
    if (!biCalled) {
      biRedirectAndCall(setbiCalled)
    }
    if (!weavCalled) {
      weavRedirectAndCall(setWeavCalled)
    }
    if (!seCalled) {
      saltEdgeRedirectAndCall(setSeCalled)
    }
  }, [])

  return (
    <div style={{ backgroundImage: `url(${Mountain})` }} id='app'>
      <main>
        <div className='step-wrapper analyzing h-100'>
          <h1 className='mt-5 text-dark text-center' style={{ fontSize: '30px' }}>
            Maytana is analysing data from your {props.authorization.institutionName} account
          </h1>
          <p className='text-center mb-2 mt-3' style={{ fontSize: '25px', color: '#888888' }}>
            This may take a few minutes
          </p>

          <div className='mt-8 justify-content-center align-items-center' style={{ display: 'flex' }}>
            <div className='image-stack'>
              <div className='image-stack__item image-stack__item--top'>
                <img
                  src={props.authorization.institutionLogoUrl}
                  alt='bank account logo'
                  style={{ height: '235px', padding: '20px' }}
                />
              </div>
              <div className='image-stack__item image-stack__item--bottom'>
                <img src={Connection} alt='' />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

const propMap = state => ({
  authorization: state.authorization
})

export default connect(propMap, {
  setInstitutionNameAndLogo,
  showError
})(Analyzing)
