import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const StepSidebar = ({ stepsSideData, updateIndex }) => {
  const location = useLocation()
  return (
    <div className='steps-sidebar p-2 display-sm-none'>
      {
          stepsSideData.map((d, idx) => (
            <div key={idx} className='step mt-3'>

              <Link to={d.path} onClick={() => updateIndex(d.path)} className={location.pathname === d.path ? 'step-label active' : 'step-label'}>
                {d.value}
              </Link>
            </div>
          ))
        }
    </div>
  )
}

export default StepSidebar
