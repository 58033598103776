import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Card,
  Button,
  CardBody,
  Container,
  Row,
  Col
} from 'reactstrap'
import history from '../history'

const Authorized = ({ authorization: { event: { newAccount, newTransactions }, institutionName } }) => {
  if (newAccount && newTransactions) {
    history.push('/admin/home')
  }

  const whlist = [
    'stripe',
    'shopify',
    'paypal'
  ]

  const accountText = whlist.includes(institutionName.toLowerCase()) ? `${institutionName} account` : `${institutionName} bank account`

  return (
    <Container fluid>
      <Row>
        <div className='col mt-5'>
          <Card className='mt-5'>
            <CardBody>
              <h1 className='text-center mt-6 mb-6' style={{ fontSize: '50px' }}>Maytana successfuly analysed your {accountText} data.</h1>
              <Row className='justify-content-md-center'>
                <Col lg='6'>
                  <Card>
                    <CardBody>
                      <h2 className='text-center'>See the full picture by connecting another account</h2>
                      <div className='col text-center mt-4 item-align-center'>
                        <Link to='/admin/home'><Button className='ml-2 mr-2' color='primary'>Ship this</Button></Link>
                        <Link to='/admin/connect-bank'><Button className='ml-2 mr-2' color='primary'>Connect another bank account</Button></Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Container>
  )
}

const propMap = state => ({
  authorization: state.authorization
})

export default connect(propMap, undefined)(Authorized)
