import React from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { TransactionDetails } from 'components/Transact/TransactionDetails'

const Transact = (props) => {
  const { accounts: { accounts } } = props
  return (
    <main>
      <Container fluid className='transact' style={{ minHeight: '100%', maxWidth: '88%' }}>
        <TransactionDetails accounts={accounts} />
      </Container>
    </main>
  )
}

const propMap = state => ({
  accounts: state.accounts
})

export default connect(propMap, undefined)(Transact)
