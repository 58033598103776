import { getRequest, postRequest, putRequest, rawPostRequest } from '../../API/ApiClient'

export const fetchItemIdAccounts = async (id, signal) => {
  return getRequest({ path: `/api/accounts/item/${id}`, signal })
}

export const reauthRequest = async (body, aggregatorName, signal) => {
  const path = `/api/${aggregatorName}/bank/reauth`
  return await rawPostRequest({ path, body, signal })
}

export const fetchInstitutionFields = async (itemId) => {
  const path = '/api/belvo/institution?itemId=' + itemId
  return getRequest({ path: path })
}

export const plaidReauthRequest = async () => {
  return postRequest({ path: '/api/plaid/create_link_token' })
}

export const updateEbItem = async (body) => {
  return putRequest({ path: '/api/ebanking/bank/reauth', body })
}
