import { GET_ANALYTICS } from 'actions/constants'

const initState = {
  accountAnalytics: null,
  balanceAnalytics: null,
  cashflowAnalytics: null,
  latestBalance: 0,
  comparisonBalance: 0,
  accountBalanceRange: null,
  balanceRange: null,
  loading: false,
  reports: {
    net: 0.00,
    burn: 0.00,
    runway: 0.00,
    revenues: 0.00
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    // case GET_ANALYTICS: {
    //   return {
    //     ...action.payload
    //   }
    // }
    case 'SET_REPORT': {
      console.log('action.o', action)

      return {
        reports: action.payload
      }
    }
    default:
      return state
  }
}
