import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchInstitutionFields, fetchItemIdAccounts, plaidReauthRequest, reauthRequest, updateEbItem } from './helper'
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Spinner } from 'reactstrap'
import { usePlaidLink } from 'react-plaid-link'
import history from '../../history'
import ReauthModal from './ReauthModal'
// import { openInstitutionAuthWindow } from 'views/windowHelper'
import { showError } from 'actions/errorActions'

const AuthAccounts = ({ showError }) => {
  const [accounts, setAccounts] = useState(null)
  const [aggregator, setAggregator] = useState(null)
  const [authLoading, setAuthLoading] = useState(false)
  const [itemID, setItemID] = useState('')
  const [modal, setModal] = useState(false)
  const [belvoFields, setBelvoFields] = useState([])
  const [bankName, setBankName] = useState('')
  const [plaidToken, setPlaidToken] = useState('')
  const [aggregatorItemId, setAggregatorItemId] = useState('')

  useEffect(() => {
    const handleEbRedirect = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const ebState = urlParams.get('ebState')
      const code = urlParams.get('ebCode')
      try {
        if (code && ebState) {
          const { response } = await updateEbItem({ code: code, state: ebState })
          if (response.status >= 200 && response.status <= 299) {
            history.push('/admin/home')
          } else {
            showError(response.statusText)
          }
        }
      } catch (err) {
        console.log(err)
        showError(err)
      }
    }
    handleEbRedirect()
  }, [])

  useEffect(() => {
    async function fetchItem () {
      const { search } = window.location
      const abortController = new window.AbortController()
      const signal = abortController.signal
      if (!search.length) {
        history.push('/home')
      } else {
        const params = new URLSearchParams(search)
        const itemId = params.get('id')
        const provider = params.get('aggregator')
        setItemID(itemId)
        setAggregator(provider)
        if (itemId && provider) {
          const { json } = await fetchItemIdAccounts(itemId, signal)
          const [acc] = json.accounts
          if (acc) {
            setAggregatorItemId(acc.aggregator_item_id)
            setBankName(acc.institution_name)
          }
          setAccounts(json.accounts)
        }
      }

      return function cleanup () {
        abortController.abort()
      }
    }

    fetchItem()
  }, [])

  const onSuccess = useCallback(() => (plaidPublicToken, meta) => {
    history.push('/admin/home')
  })

  const onExit = (err, meta) => {
    if (err != null) {
      showError(err)
    }
  }

  useEffect(() => {
    const handlePlaidReauth = async () => {
      try {
        const { response, json } = await plaidReauthRequest()
        if (response.status >= 200 && response.status <= 299) {
          const responseJSON = json
          setPlaidToken(responseJSON.link_token)
        } else {
          showError(response.statusText)
        }
      } catch (error) {
        showError(error)
      }
    }

    handlePlaidReauth()
  }, [])

  const { open, ready, error: plaidErr } = usePlaidLink({ token: plaidToken, onSuccess, onExit })

  if (plaidErr) {
    showError(plaidErr)
  }

  const handleYapilyAuth = async () => {
    const data = await reauthRequest({ itemId: itemID }, 'yapily')
    const resp = await data.json()
    // openInstitutionAuthWindow(resp.authorisationUrl)
  }
  const handleEbAuth = async () => {
    const data = await reauthRequest({ itemId: itemID }, 'ebanking')
    const resp = await data.json()
    // openInstitutionAuthWindow(resp.url)
  }

  const startReauth = async () => {
    switch (aggregator) {
      case 'plaid':
        // Fetch link token and display plaid widget
        if (ready) {
          open()
        } else {
          showError('Please wait a few seconds')
        }
        break
      case 'enablebanking':
        handleEbAuth()
        break
      case 'yapily':
        // Fetch reauthentication URL from yapily endpoint
        handleYapilyAuth()
        break
      case 'belvo':
        try {
          const { json } = await fetchInstitutionFields(itemID)
          setBelvoFields(json.form_fields)
        } catch (err) {
          console.log(err)
        }
        // Show authentication modal and let user login
        setModal(true)
        break
      default:
        break
    }
  }

  const toggleModal = () => {
    if (!authLoading) {
      setModal(!modal)
    }
  }

  const handleReauthentication = async (body) => {
    const resp = await reauthRequest(body, aggregator)
    const data = await resp.json()
    if (resp.status >= 200 && resp.status <= 299) {
      history.push('/admin/home')
    } else {
      showError(data.msg)
      setAuthLoading(false)
    }
  }

  const handleModalSubmit = data => {
    setAuthLoading(true)
    handleReauthentication(data)
  }

  return (
    accounts
      ? (
        <>
          <ReauthModal
            loading={authLoading}
            isOpen={modal}
            existingFields={belvoFields}
            linkId={aggregatorItemId}
            bankName={bankName}
            toggle={toggleModal}
            submit={handleModalSubmit}
          />
          <Card>
            <CardHeader>
              <Row className='align-items-center'>
                <Col>
                  <CardTitle
                    className='text-muted mb-0 mt-2'
                  >
                    Here are the accounts we have lost access to:
                  </CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className='no-gutters align-items-center'>
                {accounts.map(account => (
                  <Col sm='6' lg='4' key={account.id}>
                    <div style={{ width: '60px', margin: '0 auto' }}>
                      <img width='60px' alt='bank logo' src={account.logo_url} />
                    </div>
                    <CardTitle
                      tag='h5'
                      className='text-center text-uppercase text-muted mb-0 mt-2'
                    >
                      {account.name}
                    </CardTitle>
                  </Col>
                ))}
              </Row>
            </CardBody>
            <CardFooter>
              <div className='btn btn-primary' onClick={startReauth}>
                Reauthenticate
              </div>
            </CardFooter>
          </Card>
        </>)
      : <div><Spinner color='primary' className='m-auto d-block' /></div>
  )
}

export default connect(undefined, { showError })(AuthAccounts)
