import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

export const UniversalDropdown = ({ menuSize, toggleText, children }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle size={menuSize || 'sm'} color='primary'>
        {toggleText}
      </DropdownToggle>
      <DropdownMenu size={menuSize || 'sm'}>
        {children}
      </DropdownMenu>
    </Dropdown>
  )
}

export default UniversalDropdown
