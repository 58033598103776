import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Header from '../components/Headers/AdminHeader'
import TextLogo from '../components/Logo/TextLogo'
import routes from '../routes'
import Mountain from 'assets/img/mountains.webp'

const Admin = () => {
  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      <div style={{ backgroundImage: `url(${Mountain})` }} id='app'>
        <Header />
        <Switch>
          {getRoutes()}
        </Switch>
        <Footer />
      </div>
    </>
  )
}

const Footer = () => {
  return (
    <footer>
      <TextLogo />
    </footer>
  )
}

export default Admin
