import { GET_ALERTS, INITIALIZE_STATES, UPDATE_ALERTS, ALERTS_LOADING } from 'actions/constants'

const initState = {
  alerts: null,
  deliveryMethods: null,
  loading: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case ALERTS_LOADING:
      return {
        loading: true
      }
    case GET_ALERTS:
    case UPDATE_ALERTS: {
      return {
        ...action.payload,
        loading: false
      }
    }
    case INITIALIZE_STATES:
      return initState
    default:
      return state
  }
}
