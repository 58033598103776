import UniversalDropdown from 'components/DropDowns/UniversalDropdown'
import React, { useEffect, useState } from 'react'
import { Col, DropdownItem, Row } from 'reactstrap'
import { AccountSelect } from './AccountSelect'
import { TransactionSpecifics } from './TransactionSpecifics'

export const TransactionDetails = ({ accounts }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('USD')
  const [fromAccount, setFromAccount] = useState(null)
  const [toAccount, setToAccount] = useState(null)
  const currencies = [
    'USD',
    'EUR',
    'AUD',
    'CAD',
    'MXN'
  ]

  useEffect(() => {
    setFromAccount(accounts[0])
    setToAccount(accounts[accounts.length - 1])
  }, [])

  const [fields, setFields] = useState({
    purpose: {
      employee: { selected: false, text: 'employee' },
      contractor: { selected: false, text: 'contractor' },
      vendor: { selected: false, text: 'vendor' },
      intra: { selected: false, text: 'Intra-company' },
      landlord: { selected: false, text: 'landlord' },
      other: { selected: false, text: 'other' }
    },
    note: '',
    totalAmount: 0,
    category: '',
    receipt: false
  })

  const initMoneyMovement = () => {
    console.log(fields)
  }

  const setFieldValue = (fieldName, fieldValue) => {
    const copy = { ...fields }
    copy[fieldName] = fieldValue
    setFields(copy)
  }

  return (
    <Row style={{ height: '86vh' }}>
      <Col lg='9' className='pt-5'>
        <div className='transact-wrapper'>
          <div className='transact-amount'>
            <h1 className='text-center'> Move Money In
              <UniversalDropdown toggleText={selectedCurrency}>
                {currencies.map((c, i) => (<DropdownItem key={i} onClick={() => setSelectedCurrency(c)}>{c}</DropdownItem>))}
              </UniversalDropdown>
            </h1>
            <input
              className='transact-literal'
              type='number'
              onChange={e => setFieldValue('totalAmount', Number(e.target.value))}
              defaultValue={fields.totalAmount}
            />
          </div>
          <div className='transact-accounts'>
            <div className='transact-account'>
              <h3>Send From</h3>
              {fromAccount &&
                (
                  <AccountSelect
                    setAccount={setFromAccount}
                    accounts={accounts}
                    selectedAccount={fromAccount}
                  />)}
            </div>
            <div className='transact-account'>
              <h3>Send To</h3>
              {fromAccount && (
                <AccountSelect
                  setAccount={setToAccount}
                  accounts={accounts}
                  selectedAccount={toAccount}
                />)}
            </div>
          </div>
          <Row className='transact-options'>
            <Col xs='4'>
              <div className='btn btn-secondary btn-block' onClick={initMoneyMovement}>
                Send
              </div>
            </Col>
            <Col xs='4'>
              <div className='btn btn-secondary btn-block'>
                Send Later
              </div>
            </Col>
            <Col xs='4'>
              <div className='btn btn-secondary btn-block'>
                Remind
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col lg='3' className='details-col pt-5'>
        <TransactionSpecifics fields={fields} setFields={setFields} />
      </Col>
    </Row>
  )
}
