import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie, faEye, faFile, faUndo, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Button, Input } from 'reactstrap'
import { CategorySelect } from 'components/Transact/CategorySelect'
import { ReceiptUpload } from 'components/Transactions/ReceiptUpload'
import {
  setTransactionCategory,
  uploadTransactionReceipts,
  setTransactionNotes
} from 'actions/paginateTransactions'

const InvoiceDetails = props => {
  const [noteField, setNoteField] = useState('')

  const handleCategoryChange = async (userCategory) => {
    const { id } = props.txDetails
    const updatedTx = await props.setTransactionCategory({ id, userCategory })
    props.setTransactionDetails(updatedTx)
  }

  useEffect(() => {
    setNoteField(props.txDetails.notes || '')
  }, [props.txDetails])

  const {
    id,
    invoiceId,
    isLogo,
    merchantWebsite,
    paymentStatus,
    fileName,
    invoiceCategory,
    logoURL,
    amount,
    date,
    notes,
    currency,
    status,
    dueDate,
    merchantName
  } = props.txDetails

  let transformStyle = 'rotate(180deg)'
  if (isLogo) {
    transformStyle = ''
  }

  const spanStyle = { color: '#007AFF', fontWeight: '600' }

  const addTxNote = async () => {
    const { id } = props.txDetails
    const body = {
      notes: noteField,
      transactionId: id
    }
    const updated = await setTransactionNotes(body)
    props.setTransactionDetails(updated)
  }

  const getReceiptName = receiptURL => {
    const [_, uploadName] = receiptURL.split('amazonaws.com/')
    return uploadName
  }

  const openReceipt = rUrl => {
    window.open(rUrl, '_blank')
  }

  const openInvoiceModal = () => {
    props.setInvoiceModalOpen(true)
    props.setInvoiceModalDetails(props.txDetails)
  }

  return (
    <div className='tx-details-card'>
      {Object.keys(props.txDetails).length !== 0 &&
        (
          <div id='transactions-details'>
            <div id='transactions-details-header' className='tx-details-header'>
              <img
                className='tx-details-header-image'
                src={logoURL}
                style={amount > 0 ? {} : { transform: transformStyle }}
              />
              <span className='tx-details-header-merchant-name'>
                {merchantName}
              </span>
              {merchantWebsite &&
                <a
                  className='tx-details-header-website'
                  href={merchantWebsite}
                  target='_blank'
                  rel='noreferrer'
                >{merchantWebsite.replace(/(^\w+:|^)\/\//, '')}
                </a>}
              <div className='tx-details-header-category'>
                <CategorySelect
                  setCategory={handleCategoryChange}
                  selectedCategory={invoiceCategory}
                />
              </div>
            </div>
            <div className='tx-details-amount'>
              <h1 className='mb-0'><span style={spanStyle}>{currency}</span> {amount.toFixed(2).toLocaleString()}</h1>
            </div>
            <div className='tx-details-paid-on'>
              <span className='d-block text-gray'>Received on {moment(date).format('ddd, D MMMM, YYYY')}</span>
              <span className='d-block text-gray mb-3'>Due on {moment(dueDate).format('ddd, D MMMM, YYYY')}</span>

              <span style={{ fontSize: '14px' }} className='d-block text-gray'>
                <FontAwesomeIcon
                  className='tx-detail-icon-prev'
                  size='sm'
                  icon={faInfoCircle}
                />
                {' '}
                This invoice is not a priority you can wait up to 90 days after the due date (8, Jun, 2021) before paying it.
              </span>
            </div>
            <div className='tx-actions mt-4'>
              <h5>Receipts</h5>
              <div className='receipt clickable'>
                <FontAwesomeIcon icon={faFile} size='md' />
                <span className='receipt-name text-gray'>
                  {fileName.toLowerCase()}
                </span>
                <FontAwesomeIcon icon={faEye} size='md' onClick={() => openReceipt(fileName)} />
              </div>
            </div>
            <Button onClick={() => openInvoiceModal()} className='note-tx-details mt-3'>
              Review and Pay
            </Button>
          </div>)}
    </div>
  )
}

const propMap = state => ({
  transactions: state.transactions
})

export default connect(propMap, {
  uploadTransactionReceipts,
  setTransactionNotes,
  setTransactionCategory
})(InvoiceDetails)
