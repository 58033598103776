import React from 'react'
import { DropdownItem } from 'reactstrap'
import { timePeriods } from '../../util'
import UniversalDropdown from './UniversalDropdown'

const TimePeriodDropDown = props => {
  const displayTimePeriodList = timePeriods.filter(tp => tp.text !== props.selectedTimePeriod)
  return (
    <UniversalDropdown
      menuSize='sm'
      toggleText={props.selectedTimePeriod}
    >
      {displayTimePeriodList.map(period => {
        return (
          <DropdownItem
            key={period.value}
            onClick={() => props.setSelectedTimePeriod(period)}
          >{period.text}
          </DropdownItem>
        )
      })}
    </UniversalDropdown>
  )
}

export default TimePeriodDropDown
