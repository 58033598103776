import React from 'react'

const SecondaryButton = ({ text, handler }) => {
  return (
    <button
      type='button'
      className='btn float-left'
      onClick={handler}
      style={{
        border: '1px solid #EEEEEE',
        padding: '0.5rem 2rem',
        backgroundColor: '#ffff',
        borderColor: '#000',
        borderRadius: '2px',
        color: '#000',
        boxShadow: 'none'
      }}
    >
      {text}
    </button>
  )
}

export default SecondaryButton
