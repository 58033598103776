import { postRequest, getRequest, putRequest } from 'API/ApiClient'
import history from '../history'
import store from '../redux-store'
import { setInstitutionNameAndLogo } from 'actions/authorizedActions'
import { showError } from 'actions/errorActions'
import {
  apiOrigin,
  budgetInsightClientId,
  budgetInsightPrefixUrl
} from '../config'

export const onPlaidSuccess = async (publicToken, metadata) => {
  try {
    const { response, json } = await postRequest({ path: '/api/plaid/bank/auth', body: { publicToken, metadata } })
    if (response.status >= 200 && response.status <= 299) {
      const { institutionName, institutionLogoUrl } = json
      window.analytics.track('Connect Bank Account', { aggregator: 'plaid', metadata })
      store.dispatch(setInstitutionNameAndLogo({ institutionName, institutionLogoUrl }))
      history.push('/analyze')
    } else {
      console.log(response.statusText)
    }
  } catch (error) {
    console.log(error)
  }
}

export const onPlaidExit = async error => {
  const eventError = error || {}
  console.log(eventError)
}

export async function openSaltEdge (country) {
  const { json: { connectUrl } } = await getRequest({ path: '/api/saltedge/create_link_url', params: `?country=${country}` })
  window.open(connectUrl)
}

export const fetchPlaidLinkToken = async (setPlaidToken) => {
  const abortController = new window.AbortController()
  const signal = abortController.signal
  try {
    const { response, json } = await postRequest({ path: '/api/plaid/create_link_token', signal })
    if (response.status >= 200 && response.status <= 299) {
      const responseJSON = json
      setPlaidToken(responseJSON.link_token)
    } else {
      console.log(response.statusText)
    }
  } catch (error) {
    console.log(error)
  }

  return function cleanup () {
    abortController.abort()
  }
}

export const openBelvoWidget = async (country) => {
  const { json: { access } } = await getRequest({ path: '/api/belvo/bank/auth' })
  if (!access) return
  window.belvoSDK.createWidget(access, {
    locale: 'en', // 'en' for English or 'pt' for Portuguese
    company_name: 'Maytana', // the name of the company to be displayed in the first screen
    institution_types: ['fiscal', 'business'], // to select the type of institution to show in the widget
    access_mode: 'recurrent', // to specify the type of link to be created from the widget
    country_codes: [country],
    callback: (link, institution) => successBelvoCallbackFunction(link, institution),
    onExit: (data) => onExitBelvoCallbackFunction(data),
    onEvent: (data) => onEventBelvoCallbackFunction(data)
  }).build()
}

function onExitBelvoCallbackFunction (data) {
  // Do something with the exit data
}

function onEventBelvoCallbackFunction (data) {
  // Do something with the event data
  if (data && data.eventName === 'ERROR') {
    console.log(data.meta_data.error_message)
  }
}

const successBelvoCallbackFunction = async (linkId, institutionId) => {
  // Do something with the link_id or institution name
  try {
    const body = { linkId, institutionId }
    const { json, response } = await postRequest({ path: '/api/belvo/bank/auth', body })
    if (response.status >= 200 && response.status <= 299) {
      const { institutionName, institutionLogoUrl } = json
      window.analytics.track('Connect Bank Account', { aggregator: 'belvo', institutionName })
      store.dispatch(setInstitutionNameAndLogo({ institutionName, institutionLogoUrl }))
      history.push('/analyze')
    } else if (response.status === 500) {
      console.log(response)
    } else {
      console.log(response)
    }
  } catch (error) {
    console.log(error)
  }
}

export const openEb = async (country, selectedEbInstitution) => {
  const body = {
    country,
    ...selectedEbInstitution
  }
  const { json } = await postRequest({ path: '/api/ebanking/bank/auth', body })
  window.open(json.url, '_self')
}

export const openYapily = async (institutionId) => {
  const body = { institutionId }
  const { json } = await postRequest({ path: '/api/yapily/bank/auth', body })
  window.open(json.authorisationUrl, '_self')
}

export const openBudgetInsight = () => {
  const budgetInsightWebviewUrl = `https://${budgetInsightPrefixUrl}.biapi.pro/2.0/auth/webview/connect?client_id=${budgetInsightClientId}&redirect_uri=${apiOrigin}/api/bi/bank/auth`
  return window.open(budgetInsightWebviewUrl)
}

export const openShopify = async (shopname) => {
  const { json: { url } } = await getRequest({ path: '/api/weav/auth', params: `?shopname=${shopname}` })
  window.open(url, '_self')
}

export const ebRedirect = async (setebCalled) => {
  const urlParams = new URLSearchParams(window.location.search)
  const ebState = urlParams.get('ebState')
  const code = urlParams.get('ebCode')
  try {
    if (code && ebState) {
      const body = { code: code, institutionId: ebState }
      const { response, json } = await putRequest({ path: '/api/ebanking/bank/auth', body })
      if (response.status >= 200 && response.status <= 299) {
        const { institutionName, institutionLogoUrl } = json
        window.analytics.track('Connect Bank Account', { aggregator: 'eb', institutionName })
        store.dispatch(setInstitutionNameAndLogo({ institutionName, institutionLogoUrl }))
      } else {
        setebCalled(true)
        store.dispatch(showError(response.statusText))
      }
    }
  } catch (err) {
    console.log(err)
  } finally {
    setebCalled(true)
  }
}

export const biRedirectAndCall = async (setbiCalled) => {
  const urlParams = new URLSearchParams(window.location.search)
  const biCode = urlParams.get('bi_code')
  const biUserId = urlParams.get('bi_user_id')

  if (biCode !== null && biUserId !== null) {
    window.history.replaceState({}, document.title, '/analyze')
    try {
      const body = {
        biUserId,
        biCode
      }
      const { response, json } = await postRequest({ path: '/api/bi/bank/auth', body })
      if (response.status >= 200 && response.status <= 299) {
        const { institutionName, institutionLogoUrl } = json
        window.analytics.track('Connect Bank Account', { aggregator: 'bi', institutionName })
        store.dispatch(setInstitutionNameAndLogo({ institutionName, institutionLogoUrl }))
      } else {
        setbiCalled(true)
        store.dispatch(showError(response.statusText))
      }
    } catch (error) {
      store.dispatch(showError(error))
    } finally {
      setbiCalled(true)
    }
  }
}

export const weavRedirectAndCall = async (setWeavCalled) => {
  const urlParams = new URLSearchParams(window.location.search)
  const weavId = urlParams.get('weavId')
  const original = urlParams.get('extraParams')
  if (weavId !== null) {
    setWeavCalled(true)
    try {
      const body = { weavId, original }
      const { response, json } = await postRequest({ path: '/api/weav/auth', body })
      if (response.status >= 200 && response.status <= 299) {
        const { institutionName, institutionLogoUrl } = json
        window.analytics.track('Connect Account', { aggregator: 'weav', institutionName })
        store.dispatch(setInstitutionNameAndLogo({ institutionName, institutionLogoUrl }))
      } else {
        setWeavCalled(true)
        store.dispatch(showError(response.statusText))
      }
    } catch (error) {
      store.dispatch(showError(error))
    }
  }
}

export const yapilyRedirectAndCall = async (setYapilyCalled) => {
  const urlParams = new URLSearchParams(window.location.search)
  const consentJwt = urlParams.get('consent')
  const institutionId = urlParams.get('institution')
  try {
    if (consentJwt && institutionId) {
      const body = { consentJwt, institutionId }
      const { json, response } = await putRequest({ path: '/api/yapily/bank/auth', body })
      if (response.status >= 200 && response.status <= 299) {
        const { institutionName, institutionLogoUrl } = json
        store.dispatch(setInstitutionNameAndLogo({ institutionName, institutionLogoUrl }))
      } else {
        setYapilyCalled(true)
        store.dispatch(showError(response.statusText))
      }
    }
  } catch (err) {
    store.dispatch(showError(err))
  } finally {
    setYapilyCalled(true)
  }
}

export const saltEdgeRedirectAndCall = async (setSeCalled) => {
  const urlParams = new URLSearchParams(window.location.search)
  const seCode = urlParams.get('salt_edge_code')
  if (seCode !== null) {
    window.history.replaceState({}, document.title, '/admin/connect')
    try {
      const body = { seCode }
      const { response, json } = await postRequest({ path: '/api/saltedge/bank/auth', body })
      if (response.status >= 200 && response.status <= 299) {
        const { institutionName, institutionLogoUrl } = json
        window.analytics.track('Connect Bank Account', { aggregator: 'se', institutionName })
        store.dispatch(setInstitutionNameAndLogo({ institutionName, institutionLogoUrl }))
      } else {
        setSeCalled(true)
        store.dispatch(showError(response.statusText))
      }
    } catch (error) {
      store.dispatch(showError(error))
    } finally {
      setSeCalled(true)
    }
  }
}
