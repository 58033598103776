const {
  UPDATE_BALANCES,
  GET_BALANCES,
  BALANCES_LOADING
} = require('actions/constants')

const initState = {
  display: '0.00',
  total: 0,
  loading: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_BALANCES:
    case UPDATE_BALANCES:
      return {
        ...action.payload,
        loading: false
      }
    case BALANCES_LOADING: {
      return {
        ...action.payload,
        loading: true
      }
    }
    default:
      return state
  }
}
