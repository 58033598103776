import { getRequest, putRequest, postRequest, fileUpload } from 'API/ApiClient'
import { showError } from './errorActions'
import {
  GET_FILTERED_TX
} from './constants'

export const fetchFilterTransactions = (sortParams, pageNumber) => async (dispatch, getState) => {
  const { paginatedTransactions: { pageNo, txs } } = getState()

  dispatch({ type: 'LOADING_TX' })

  const pNumber = pageNumber || pageNo
  let params = `?pageNo=${pNumber}`
  if (sortParams) {
    params += `&${sortParams}`
  }

  try {
    const res = await getRequest({ path: '/api/transactions/paginated', params })
    const { transactions: newTxs, noTxs } = res.json
    let txsToStore = []
    if (!pNumber) {
      txsToStore = newTxs
    } else {
      txsToStore = [...txs, ...newTxs]
    }

    const payload = {
      txs: txsToStore,
      noTxs,
      pageNo: pNumber + 1
    }
    dispatch({ type: GET_FILTERED_TX, payload })
    return res.json
  } catch (err) {
    showError(err.message || err.msg || 'Something went wrong')(dispatch)
  }
}

export const setTransactionCategory = body => async (dispatch, getState) => {
  const { paginatedTransactions: payload } = getState()
  return postRequest({ path: '/api/transactions/set-category', body }).then(res => {
    const idx = payload.periodTx.findIndex(({ id }) => id === body.id)
    const tx = payload.periodTx[idx]
    payload.periodTx.splice(idx, 1, { ...tx, user_category: body.userCategory })
    dispatch({ type: GET_FILTERED_TX, payload })
    return payload.periodTx[idx]
  }).catch(err => {
    showError(err.msg || 'Something went wrong')(dispatch)
    dispatch({ type: GET_FILTERED_TX, payload })
  })
}

export const uploadTransactionReceipts = body => async (dispatch, getState) => {
  const { paginatedTransactions: payload } = getState()
  try {
    const res = await fileUpload({ path: '/api/transactions/receipts/upload', body })
    window.analytics.track('Upload Transaction Receipts', { body })
    const { urls } = res.json
    const txId = body.get('transactionId')
    const idx = payload.txs.findIndex(({ id }) => id === txId)
    const tx = payload.txs[idx]
    payload.periodTx.splice(idx, 1, { ...tx, receipts: urls })
    dispatch({ type: GET_FILTERED_TX, payload })
    return payload.periodTx[idx]
  } catch (error) {
    showError(error.msg || 'Something went wrong')(dispatch)
  }
}

export const setTransactionNotes = body => async (dispatch, getState) => {
  const { paginatedTransactions: payload } = getState()
  try {
    await putRequest({ path: '/api/transactions/notes', body })
    window.analytics.track('Set Transaction Note', { body })
    const idx = payload.txs.findIndex(({ id }) => id === body.transactionId)
    const tx = payload.txs[idx]
    payload.txs.splice(idx, 1, { ...tx, notes: body.notes })
    dispatch({ type: GET_FILTERED_TX, payload })
    return payload.periodTx[idx]
  } catch (err) {
    showError(err.msg || 'Something went wrong')
  }
}
