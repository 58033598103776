import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  Col,
  Container
} from 'reactstrap'
import CustomInput from 'components/CustomInput'
import { startFederatedLogIn, signInWithEmailAndPassword } from '../actions/authActions'
import TextLogo from 'components/Logo/TextLogo'
import LoginLayout from '../assets/img/login-layout.webp'

const Login = props => {
  const [showEmailLogin, setEmailLogin] = useState(false)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [email, setEmail] = useState('')
  const [newPwdRequired, setNewPwdRequired] = useState(false)
  const [user, setUser] = useState(null)

  const signIn = async (email, password) => {
    if (newPwdRequired) {
      await props.signInWithEmailAndPassword(email, newPassword, true, user)
      return
    }

    const response = await props.signInWithEmailAndPassword(email, password)
    if (response && response.type === 'NEW_PASSWORD_REQUIRED') {
      setNewPwdRequired(true)
      setUser(response.user)
    }
  }

  return (
    <div
      style={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: `url(${LoginLayout})`
      }}
    >
      <Container className='h-100'>
        <Row className='align-items-center h-100'>
          <Col lg='12' className='mx-auto'>
            <div className='justify-content-center text-shadow'>
              <h2 className='upper-title'>Welcome to Maytana</h2>
              <h1 className='title'>The fastest business banking experience ever made</h1>
              <Row className='justify-content-center mt-5 mb-3'>
                <button
                  className='btn login-button'
                  onClick={() => props.startFederatedLogIn()}
                  size='lg'
                  type='submit'
                >Continue with Google
                </button>
              </Row>
              <div>
                <p className='text-center' style={{ color: '#fff', fontFamily: 'sf-pro-text-light' }}>
                  You can also <span onClick={() => setEmailLogin(true)} style={{ color: '#fff', textDecoration: 'underline', cursor: 'pointer' }}>continue with email</span>
                </p>
              </div>
              {showEmailLogin &&
                <>
                  <Row className='justify-content-center'>
                    <CustomInput
                      value={email}
                      placeholder='Input email'
                      type='email'
                      style={{ width: '400px', color: '#fff', backgroundColor: 'rgba(0,0,0,0.0)' }}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Row>
                  <Row className='justify-content-center'>
                    <CustomInput
                      value={password}
                      placeholder={newPwdRequired ? 'Old Password' : 'Input password'}
                      type='password'
                      style={{ width: '400px', color: '#fff', backgroundColor: 'rgba(0,0,0,0.0)' }}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </Row>
                  {newPwdRequired && (
                    <Row className='justify-content-center'>
                      <CustomInput
                        value={newPassword}
                        placeholder='New password'
                        type='password'
                        style={{ width: '400px', color: '#fff', backgroundColor: 'rgba(0,0,0,0.0)' }}
                        onChange={e => setNewPassword(e.target.value)}
                      />
                    </Row>
                  )}
                  <Row className='justify-content-center mt-4'>
                    <button
                      className='btn login-button'
                      onClick={() => signIn(email, password)}
                      size='lg'
                      type='submit'
                    >Sign in
                    </button>
                  </Row>
                </>}
            </div>
          </Col>
        </Row>
        <Row className='fixed-row-bottom mr-3 position-absolute bottom-0 right-0'>
          <TextLogo />
        </Row>
      </Container>
    </div>
  )
}

export default connect(undefined, { startFederatedLogIn, signInWithEmailAndPassword })(Login)
