import React from 'react'
import { Input } from 'reactstrap'
import { bankTransactionCategories } from '../../util'

export const CategorySelect = ({ setCategory, selectedCategory }) => {
  return (
    <>
      <Input
        onChange={e => setCategory(e.target.value)}
        type='select'
        placeholder='Set category'
        className='transaction-table-input'
        style={{
          padding: '0.25rem 0.4rem',
          height: 'calc(2rem + 2px)',
          display: 'inline-block',
          textAlign: 'center',
          borderRadius: 0
        }}
        value={selectedCategory}
      >
        {bankTransactionCategories.map(cat => {
          return (
            <option
              className='tx-table-option'
              value={cat.category}
              key={cat.id}
            >
              {cat.category}
            </option>
          )
        })}
      </Input>
    </>
  )
}
