import React, { useState } from 'react'
import InvoicesContent from './InvoicesContent'
import InvoicesDetails from './InvoicesDetails'

const InvoicesList = props => {
  const [period, setPeriod] = useState({ text: '3 months', value: 90 })
  const [txDetails, setTxDetails] = useState({})

  return (
    <>
      <div className='item2'>
        <InvoicesContent
          period={period}
          txDetails={txDetails}
          setTransactionDetails={setTxDetails}
        />
      </div>
      <div className='item3'>
        <div className='tab-sidebar'>
          <InvoicesDetails
            period={period}
            setPeriod={setPeriod}
            txDetails={txDetails}
            timePeriod={period}
            setTransactionDetails={setTxDetails}
            setInvoiceModalOpen={props.setInvoiceModalOpen}
            setInvoiceModalDetails={props.setInvoiceModalDetails}
          />
        </div>
      </div>
    </>
  )
}

export default InvoicesList
