export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING'
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT'

export const SET_ERROR = 'SET_ERROR'
export const HIDE_ERROR = 'HIDE_ERROR'

export const UPDATE_BALANCES = 'UPDATE_BALANCES'
export const GET_BALANCES = 'GET_BALANCES'
export const BALANCES_LOADING = 'BALANCES_LOADING'

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING'

export const GET_COMPANY = 'GET_COMPANY'
export const SET_COMPANY = 'SET_COMPANY'
export const COMPANY_LOADING = 'COMPANY_LOADING'

export const GET_ENTITIES = 'GET_ENTITIES'
export const SET_ENTITIES = 'SET_ENTITIES'
export const ENTITIES_LOADING = 'ENTITIES_LOADING'

export const AUTHORIZED_LOADING = 'AUTHORIZED_LOADING'
export const STOP_AUTHORIZED_LOADING = 'STOP_AUTHORIZED_LOADING'
export const SET_EVENT = 'SET_EVENT'
export const SET_INSTITUTION = 'SET_INSTITUTION'

export const UPDATE_CONSOLIDATED = 'UPDATE_CONSOLIDATED'
export const GET_CONSOLIDATED = 'GET_CONSOLIDATED'

export const SET_CONSOLIDATED_BALANCES = 'SET_CONSOLIDATED_BALANCES'
export const SET_ACCOUNTS_BALANCE = 'SET_ACCOUNTS_BALANCE'

export const SET_PERIOD_CASHFLOW = 'SET_PERIOD_CASHFLOW'

export const SET_CONSOLIDATED_BALANCE_RANGE = 'SET_CONSOLIDATED_BALANCE_RANGE'

export const GET_FILTERED_TX = 'GET_FILTERED_TX'
export const FILTERED_LOADING = 'FILTERED_LOADING'

export const INITIALIZE_STATES = 'INITIALIZE_STATES'

export const GET_ANALYTICS = 'GET_ANALYTICS'
export const ANALYTICS_LOADING = 'ANALYTICS_LOADING'

export const GET_ALERTS = 'GET_ALERTS'
export const UPDATE_ALERTS = 'UPDATE_ALERTS'
export const ALERTS_LOADING = 'ALERTS_LOADING'

export const GET_TEAM = 'GET_TEAM'
export const UPDATE_TEAM = 'UPDATE_TEAM'
export const TEAM_LOADING = 'TEAM_LOADING'

export const GET_SETTINGS = 'GET_SETTINGS'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const SETTINGS_LOADING = 'SETTINGS_LOADING'

export const SET_USER_ATTRIBUTES = 'SET_USER_ATTRIBUTES'

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_STARTED = 'LOGIN_STARTED'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

export const SET_MESSAGE = 'SET_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'

export const SET_INVOICES = 'SET_INVOICES'

export const SET_CONSOLIDATED_CASHFLOW = 'SET_CONSOLIDATED_CASHFLOW'
export const SET_ACCOUNT_CASHFLOW = 'SET_ACCOUNT_CASHFLOW'
export const SET_LAST_MONTH_CONSOLIDATED_CASHFLOW = 'SET_LAST_MONTH_CONSOLIDATED_CASHFLOW'
