import moment from 'moment'
import { getRequest } from 'API/ApiClient'

export const getConvertedTransactions = async (currency, endpoint) => {
  return getRequest({ path: `/api/transactions/${endpoint}?currency=${currency}` })
}

export const formatCashflowAnalytics = (cashIn, cashOut, latestBalance, comparisonBalance) => {
  const nowEnd = moment()
  const nowStart = moment().startOf('month')
  const beforeEnd = moment().subtract(1, 'month').endOf('month')
  const beforeStart = moment().subtract(1, 'month').startOf('month')
  const result = {
    moneyIn: 0,
    moneyOut: 0,
    cashflow: 0,
    latestBalance: latestBalance || 0,
    txIn: 0,
    txOut: 0,
    moneyInLM: 0,
    moneyOutLM: 0,
    cashflowLM: 0,
    comparisonBalance: comparisonBalance || 0,
    txInLM: 0,
    txOutLM: 0
  }
  cashIn.forEach(dataPoint => {
    const { amount, date } = dataPoint
    const isBetweenNow = moment(date).isBetween(nowStart, nowEnd)
    const betweenThen = moment(date).isBetween(beforeStart, beforeEnd)
    if (isBetweenNow) {
      result.moneyIn += amount
      result.cashflow += amount
      result.txIn += 1
    }
    if (betweenThen) {
      result.moneyInLM += amount
      result.cashflowLM += amount
      result.txInLM += 1
    }
  })
  cashOut.forEach(dataPoint => {
    const { amount, date } = dataPoint
    const isBetweenNow = moment(date).isBetween(nowStart, nowEnd)
    const betweenThen = moment(date).isBetween(beforeStart, beforeEnd)
    if (isBetweenNow) {
      result.moneyOut += amount
      result.cashflow += amount
      result.txOut += 1
    }
    if (betweenThen) {
      result.moneyOutLM += amount
      result.cashflowLM += amount
      result.txOutLM += 1
    }
  })
  return result
}
