import { getRequest } from 'API/ApiClient'
import { SET_INVOICES, INITIALIZE_STATES } from './constants'

export const fetchInvoices = () => async dispatch => {
  try {
    const { json: { invoices } } = await getRequest({ path: '/api/invoices/list' })
    dispatch({ type: SET_INVOICES, payload: invoices })
    return invoices
  } catch (error) {
    console.log(error)
  }
}

export const initInvoices = () => dispatch => {
  dispatch({ type: INITIALIZE_STATES })
}
