import { INITIALIZE_STATES, GET_ACCOUNTS, ACCOUNT_LOADING, SET_SELECTED_ACCOUNT } from 'actions/constants'

const initState = {
  selectedAccountId: null,
  accounts: [],
  pspAccounts: [],
  loading: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case ACCOUNT_LOADING:
      return {
        ...state,
        loading: true
      }
    case GET_ACCOUNTS: {
      return {
        accounts: action.payload,
        loading: false
      }
    }
    case SET_SELECTED_ACCOUNT: {
      return {
        ...state,
        selectedAccountId: action.payload
      }
    }
    case INITIALIZE_STATES:
      return initState
    default:
      return state
  }
}
