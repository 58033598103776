import {
  GET_FILTERED_TX
} from 'actions/constants'

const initState = {
  loading: false,
  txs: [],
  noTxs: false,
  pageNo: 0
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_FILTERED_TX:
      return {
        loading: false,
        ...action.payload
      }
    case 'LOADING_TX':
      return {
        ...state,
        loading: true
      }
    default:
      return state
  }
}
