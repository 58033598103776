import React, { useState } from 'react'
import { connect } from 'react-redux'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const LegalEntitySelect = ({ selectedEntity, setLegalSelectedLegalEntity, entities: { legalEntities } }) => {
  const [isOpen, toggleOpen] = useState(false)
  const {
    flag_url: flag,
    legal_business_name: name,
    business_address_city: city,
    business_address_zip: zip
  } = selectedEntity

  const getAccountSelections = () => {
    return (
      <div style={{ position: 'relative' }}>
        <div className='accounts-dropdown'>
          {
            legalEntities.map((entity) => (
              <div
                key={entity.id}
                onClick={() => {
                  entity.country = entity.country_of_incorporation
                  setLegalSelectedLegalEntity(entity)
                  toggleOpen(false)
                }} className='account-selection'
              >
                {entity.legal_business_name}
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  return (
    <div className='account-select'>
      <div className='selected-account' style={{ position: 'relative' }} onClick={() => toggleOpen(!isOpen)}>
        <img className='bank-logo rounded' src={flag} alt='country flag' />
        <div className='acc-details'>
          <span className='acc-name'>{name}</span>
          <span className='acc-balance'>{city} {zip}</span>
        </div>
        <FontAwesomeIcon icon={faCaretDown} className='custom-caret' size='sm' />
      </div>
      {isOpen ? getAccountSelections() : undefined}
    </div>
  )
}

const propMap = state => ({
  entities: state.entities
})

export default connect(propMap)(LegalEntitySelect)
