import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { updateWorkspaceSettings } from 'actions/settingsActions'
import CustomInput from 'components/CustomInput'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { currencyList } from '../../util'

const Workspace = props => {
  const [saveButtonLoading, setSaveButtonLoading] = useState(false)
  const [companyNameValue, setCompanyNameValue] = useState('')
  const [currency, setCurrency] = useState('')

  useEffect(() => {
    if (props.settings.companySettings) {
      setCompanyNameValue(props.settings.companySettings.companyName)
      setCurrency(props.settings.companySettings.currency)
    }
  }, [props.settings])

  const handleSave = async () => {
    setSaveButtonLoading(true)
    await props.updateWorkspaceSettings({
      companyName: companyNameValue,
      currency
    })
    setSaveButtonLoading(false)
  }

  return (
    <Row className='setting-row w-100'>
      <Col>
        <div className='setting-card'>
          <CustomInput
            value={companyNameValue}
            name='companyName'
            labelName='Company Name'
            type='text'
            placeholder='Your company name'
            onChange={e => setCompanyNameValue(e.target.value)}
          />
          <CustomInput
            valueLabel={currency}
            value={currency}
            name='currencyCode'
            labelName='Default Currency'
            type='select'
            placeholder='Default currency'
            options={currencyList}
            onChange={e => setCurrency(e.target.value)}
          />
          <div className='mt-3 text-right'>
            <PrimaryButton
              text='Save Details'
              loading={saveButtonLoading}
              handler={() => handleSave()}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

const propMap = state => ({
  settings: state.settings
})

export default connect(propMap, { updateWorkspaceSettings })(Workspace)
