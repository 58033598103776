import { getRequest } from 'API/ApiClient'

const {
  SET_CONSOLIDATED_BALANCES,
  SET_ACCOUNTS_BALANCE
} = require('./constants')

export const getConsolidatedBalances = currency => async dispatch => {
  try {
    const res = await getRequest({ path: `/api/balances/consolidated?currency=${currency}` })
    dispatch({ type: SET_CONSOLIDATED_BALANCES, payload: res.json })
  } catch (error) {
    console.error(error)
  }
}

export const getLastConsolidatedBalances = currency => async dispatch => {
  try {
    const res = await getRequest({ path: `/api/balances/consolidated/last?currency=${currency}` })
    dispatch({ type: 'SET_LAST_CONSOLIDATED_BALANCES', payload: res.json.lastBalances })
  } catch (error) {
    console.error(error)
  }
}

export const fetchAccountBalances = (accountId, currency) => async dispatch => {
  try {
    const res = await getRequest({ path: `/api/balances/account?accountId=${accountId}&currency=${currency}` })
    const payload = { currency, accountId, consolidatedBalances: res.json.balance }
    dispatch({ type: SET_ACCOUNTS_BALANCE, payload })
  } catch (err) {
    console.error(err.message || 'Failed to fetch balances')
  }
}
