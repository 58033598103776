import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'
import Modal from 'react-modal'
import { getSlackAuthURL } from 'components/Alerts/helper'
import CustomInput from 'components/CustomInput'
import CrossIcon from '../../assets/icons/cross.svg'

const CreateAlert = ({
  toggle,
  alerts,
  accounts,
  modalOpen,
  deliveryMethods,
  createAlert,
  createDeliveryMethod
}) => {
  const [loading, setLoading] = useState(false)

  const [dmForm, setDmForm] = useState({
    email: { value: '', placeholder: 'Email address', id: null, selected: false, saved: true },
    phoneNumber: { value: '', placeholder: 'Phone number', id: null, selected: false, saved: true },
    slack: { value: '', placeholder: 'Slack channel (filled automatically)', id: null, selected: false, saved: true },
    webhook: { value: '', placeholder: 'Webhook URL', id: null, selected: false, saved: true }
  })
  const [steps, setSteps] = useState([
    {
      step: 'customize',
      title: 'Customize alert',
      index: '01',
      active: true
    },
    {
      step: 'notifications',
      title: 'Set notifications',
      index: '02',
      active: false
    }
  ])

  const [customize, setCustomize] = useState([
    {
      labelName: 'Notify me when',
      name: 'fundMovementType',
      type: 'select',
      value: '',
      valueLabel: '',
      optionsOpen: false,
      options: [
        {
          label: 'Funds move out of',
          value: 'debit'
        },
        {
          label: 'Funds move in to',
          value: 'credit'
        },
        {
          label: 'Funds move in and out of',
          value: 'any'
        }
      ]
    },
    {
      labelName: 'account',
      name: 'accounts',
      type: 'select',
      value: '',
      valueLabel: '',
      optionsOpen: false,
      options: accounts.map(acc => ({ value: acc.id, label: acc.name }))
    },
    {
      labelName: 'the amount is',
      name: 'operator',
      type: 'select',
      value: '',
      valueLabel: '',
      optionsOpen: false,
      options: [
        {
          value: 'gt',
          label: 'more than'
        },
        {
          value: 'lt',
          label: 'less than'
        },
        {
          value: 'eq',
          label: 'exactly'
        },
        {
          value: 'any',
          label: 'any amount'
        }
      ]
    },
    {
      labelName: '$ amount',
      name: 'amount',
      type: 'number',
      value: '1000'
    }
  ])

  useEffect(() => {
    const copy = { ...dmForm }
    if (deliveryMethods) {
      Object.keys(deliveryMethods).forEach(k => {
        const { value, id } = deliveryMethods[k]
        if (k !== 'whatsapp') {
          copy[k] = { ...copy[k], value, id }
        }
      })
    }
    setDmForm(copy)
  }, [deliveryMethods])

  const activeStep = steps.find(({ active }) => active)

  const handleDeliveryMethodChange = e => {
    const { value, name } = e.target
    const copy = { ...dmForm }
    copy[name].value = value
    if (copy[name].value !== deliveryMethods[name].value) {
      copy[name].saved = false
    } else {
      copy[name].saved = true
    }
    setDmForm(copy)
  }

  const handleChange = e => {
    const { value, name, valueLabel } = e.target
    const copy = [...customize]
    const idx = copy.findIndex(({ name: fn }) => fn === name)
    if (idx !== -1) {
      copy.splice(idx, 1, { ...copy[idx], value, valueLabel })
    }
    setCustomize(copy)
  }

  const changeStep = stepType => {
    const copy = steps.map(s => ({ ...s, active: false }))
    const idx = copy.findIndex(({ step }) => step === stepType)
    copy.splice(idx, 1, { ...copy[idx], active: true })
    setSteps(copy)
  }

  const getStepHeader = () => {
    const { title, index } = activeStep
    return (
      <div>
        <h5>{title} <span className='float-right'>{index}</span></h5>
      </div>
    )
  }

  const saveMethod = async (newMethod, methodKey) => {
    const copy = { ...dmForm }
    const method = {
      deliveryMethodType: methodKey === 'phoneNumber' ? 'sms' : methodKey,
      [methodKey]: dmForm[methodKey].value
    }
    const { phoneNumber, deliveryMethodType, slackWebHookUrl, email, whatsapp, webhook } = method
    const body = {
      deliveryMethodType,
      phoneNumber,
      slackWebHookUrl,
      email,
      whatsapp,
      webhook
    }
    await createDeliveryMethod(deliveryMethodType, body)
    copy[methodKey].saved = true
    setDmForm(copy)
  }

  const selectMethod = (name, isSelected) => {
    const copy = { ...dmForm }
    const value = !JSON.parse(isSelected)
    if (copy[name].id) {
      copy[name].selected = value
    }
    setDmForm(copy)
  }

  const openSlackAuth = async () => {
    try {
      const { json: { url } } = await getSlackAuthURL()
      window.open(url, '_self')
    } catch (err) {
      console.log(err)
    }
  }

  const compareStringArr = (arr1, arr2) => {
    arr1 = arr1.sort((a, b) => a > b)
    arr2 = arr2.sort((a, b) => a > b)
    return arr1.join('') === arr2.join('')
  }

  const noDuplicates = newAlert => {
    const noDuplicates = true
    if (!alerts.length) {
      return noDuplicates
    }
    const { deliveryMethods, amount, fundMovementType, accounts, operator } = newAlert
    try {
      alerts.forEach(alert => {
        const noDuplicates = !(Number(amount) === alert.amount && fundMovementType === alert.fund_movement_type && operator === alert.operator && compareStringArr(alert.accounts, accounts) && compareStringArr(deliveryMethods, alert.delivery_methods))
        if (!noDuplicates) {
          throw new Error('Duplicate alert')
        }
      })
    } catch (err) {
      return false
    }
    return noDuplicates
  }

  const initializeState = () => {
    const copyC = [...customize].map(v => ({ ...v, value: '', valueLabel: '', optionsOpen: false }))
    const copyD = {}
    const stepCopy = [...steps].map((s, idx) => ({ ...s, active: idx === 0 }))
    Object.keys(dmForm).forEach(k => {
      copyD[k] = {
        ...dmForm[k],
        selected: false
      }
    })
    setCustomize(copyC)
    setDmForm(copyD)
    setSteps(stepCopy)
  }

  const handleCreateClick = async () => {
    const body = {
      deliveryMethods: []
    }
    Object.keys(dmForm).forEach(key => {
      const item = dmForm[key]
      if (item.selected && item.id) {
        body.deliveryMethods.push(item.id)
      }
    })
    customize.forEach(item => {
      const { name, value } = item
      body[name] = value
      if (name === 'accounts') {
        body[name] = [value]
      }
    })
    setLoading(true)
    try {
      if (noDuplicates(body)) {
        await createAlert(body)
        initializeState()
        toggle()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const getStepContent = () => {
    const { step } = activeStep
    if (step === 'customize') {
      return (
        <div className='alert-step-content'>
          {getStepHeader()}
          <span className='d-block text-gray mt-2 mb-4'>
            Setup your custom alert
          </span>
          {
              customize.map((f, idx) => (
                <Row key={idx}>
                  <Col xs='12'>
                    <CustomInput
                      onChange={handleChange}
                      {...f}
                    />
                  </Col>
                  {idx === 1
                    ? (
                      <Col xs='12' className='mt-3 mb-3'>
                        <span className='sep'>
                          and
                        </span>
                      </Col>
                      )
                    : undefined}
                  {
                    idx === customize.length - 1
                      ? (
                        <Col xs='12' className='mt-3 mb-2'>
                          <Button onClick={() => changeStep('notifications')} className='alert-btn'>
                            Continue
                          </Button>
                        </Col>
                        )
                      : undefined
                  }
                </Row>
              ))
            }
        </div>
      )
    }
    return (
      <div className='alert-step-content'>
        {getStepHeader()}
        <span className='d-block text-gray mt-2 mb-4'>
          How should we send the alert to you
        </span>
        {
          Object.keys(dmForm).map((key, idx) => {
            const { value, id, placeholder, selected, saved } = dmForm[key]
            return (
              <Row key={key}>
                <Col xs='8'>
                  <CustomInput
                    type='text'
                    name={key}
                    placeholder={placeholder}
                    labelName={
                      key === 'phoneNumber' ? 'SMS' : key.slice(0, 1).toUpperCase() + key.slice(1, key.length)
                    }
                    value={value}
                    disabled={key === 'slack'}
                    onChange={handleDeliveryMethodChange}
                  />
                </Col>
                <Col xs='2'>
                  {
                  !saved && key !== 'slack'
                    ? (
                      <div className='unsaved-dm' onClick={() => saveMethod(dmForm[key], key)}>
                        <span>Save</span>
                      </div>
                      )
                    : undefined
                  }
                  {
                    key === 'slack'
                      ? (
                        <div className='slack-connect' onClick={openSlackAuth}>
                          <span>{id ? 'Update' : 'Connect'}</span>
                        </div>
                        )
                      : undefined
                  }
                </Col>
                <Col xs='2'>
                  <div className='switch-wrapper'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        value={selected}
                        defaultChecked={selected}
                        onChange={() => selectMethod(key, selected)}
                      />
                      <span className='slider round' />
                    </label>
                  </div>
                </Col>
                {
                  idx === Object.keys(dmForm).length - 1
                    ? (
                      <Col xs='12' className='mt-3 mb-2'>
                        {
                          loading
                            ? (
                              <Spinner size='sm' color='primary' />
                              )
                            : (
                              <Button onClick={() => handleCreateClick()} className='alert-btn'>
                                Save alert
                              </Button>
                              )
                        }
                      </Col>
                      )
                    : undefined
                }
              </Row>
            )
          })
        }
      </div>
    )
  }

  const getStepSidebar = () => {
    return (
      <div className='alert-step-sidebar'>
        <h5 className='mb-4'>Steps</h5>
        {
          steps.map(s => (
            <div onClick={() => changeStep(s.step)} key={s.index} className={s.active ? 'alert-step active' : 'alert-step'}>
              <span>{s.index} {s.title}</span>
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <Modal
      isOpen={modalOpen}
      style={{ overlay: { zIndex: 2 } }}
      // className='create-alert-modal'
      toggle={toggle}
    >
      <div id='create-alert-modal' className='create-alert-modal'>
        <div id='invoice-modal-header'>
          <img style={{ cursor: 'pointer' }} height='20px' src={CrossIcon} onClick={() => toggle()} />
        </div>
        <div className='create-alert-modal-sub-header'>
          <span style={{ fontSize: '1.5rem' }}>Create alert</span>
        </div>
        <div className='create-alert-modal-steps'>
          {getStepSidebar()}
        </div>
        <div className='create-alert-modal-create-zone'>
          {getStepContent()}
        </div>
      </div>
    </Modal>
  )
}

export default CreateAlert
