import { HIDE_ERROR, INITIALIZE_STATES, SET_ERROR } from './constants'

export const hideError = () => dispatch => {
  dispatch({ type: HIDE_ERROR })
}

export const showError = (msg, timeout = 7000) => dispatch => {
  dispatch({ type: SET_ERROR, payload: { msg: msg || 'Something went wrong', error: true } })
  setTimeout(() => {
    hideError()(dispatch)
  }, timeout)
}

export const initAllStates = () => dispatch => {
  dispatch({ type: INITIALIZE_STATES })
}
