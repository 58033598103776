import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'

export const FileUploadSection = ({
  isDragging,
  typeSupported,
  setDragging,
  handleDragDrop,
  handleOnDragOver,
  triggerInput,
  file,
  showInstruction,
  small,
  fileText
}) => {
  const dropRef = useRef()

  const spanStyle = { fontSize: small ? '0.9rem' : 'initial' }

  return (
    <div
      ref={dropRef}
      className='w-100 p-3 d-flex text-center bg-white border mt-3'
      style={{
        flexDirection: 'column',
        cursor: 'pointer'
      }}
      onDrop={handleDragDrop}
      onDragOver={handleOnDragOver}
      onDragEnter={handleOnDragOver}
      onDragEnd={() => setDragging(false)}
      onDragExit={() => setDragging(false)}
      onClick={triggerInput}
    >
      {fileText &&
        (isDragging
          ? <span style={spanStyle} className='text-center'>Drop file here</span>
          : <span style={spanStyle} className='text-center'>{fileText}</span>)}
      <FontAwesomeIcon
        style={{ fontSize: small ? '1.5rem' : '5rem', margin: '1rem auto' }}
        className='text-gray'
        size='lg'
        icon={faUpload}
      />
      <span className='text-muted d-block mb-1'>
        {showInstruction && (
          <>
            <span className='text-primary'>Drag file here </span>
            <span>or</span>
            <span className='text-primary'> click here to browse filesystem</span>
          </>)}
      </span>
      <span style={spanStyle} className='text-muted d-block'>
        {typeSupported}
      </span>
    </div>
  )
}
