import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { showError } from 'actions/errorActions'
import { updateCompany } from 'actions/companyActions'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import CustomInput from 'components/CustomInput'
import history from '../../history'

const CompanyStep = props => {
  const [workspaceName, setWorkspaceName] = useState('')
  const [workspaceWebsite, setWorkspaceWebsite] = useState('')
  const [showBtn, setShowBtn] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  useEffect(() => {
    const { company } = props
    if (company.company_name && company.company_website) {
      setWorkspaceName(company.company_name)
      setWorkspaceWebsite(company.company_website)
      setShowBtn(true)
    }
  }, [])

  useEffect(() => {
    if (workspaceName.length > 0 && workspaceWebsite.length > 0) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }, [workspaceName, workspaceWebsite])

  const handleNextClick = async () => {
    setBtnLoading(true)
    await props.updateCompany({
      companyName: workspaceName,
      companyWebsite: workspaceWebsite
    })
    setBtnLoading(false)
    setShowBtn(false)
    props.setIndex(1)
    history.push('/onboarding/entities')
  }

  return (
    <div className='step-wrapper'>
      <Row>
        <Col xs='12' className='my-2'>
          <CustomInput
            name='workspaceName'
            labelName='Workspace Name'
            placeholder='Enter the name for your organisation'
            type='text'
            value={workspaceName}
            onChange={e => setWorkspaceName(e.target.value)}
            completed={workspaceName !== ''}
          />
        </Col>
        <Col xs='12' className='my-2'>
          <CustomInput
            name='workspaceWebsite'
            labelName='Workspace Website'
            placeholder='Enter the website of your organisation'
            type='text'
            value={workspaceWebsite}
            onChange={e => setWorkspaceWebsite(e.target.value)}
            completed={workspaceWebsite !== ''}
          />
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col xs='12'>
          {showBtn &&
            <PrimaryButton
              text='Next'
              handler={handleNextClick}
              loading={btnLoading}
            />}
        </Col>
      </Row>
    </div>
  )
}

const propMap = state => ({
  company: state.company
})

export default connect(propMap, {
  updateCompany,
  showError
})(CompanyStep)
