let config = {}
const env = 'prod'

if (env === 'sandbox') {
  config = {
    apiOrigin: 'http://localhost:3001',
    budgetInsightClientId: '39085976',
    budgetInsightPrefixUrl: 'shipl-sandbox',
    cognitoDomain: 'xe1gflcorpek.auth.us-east-1.amazoncognito.com',
    cognitoPoolId: '7vaok3rlshfocdrnofb1i3c6ts'
  }
} else if (env === 'staging') {
  config = {
    domain: 'auth.maytana.com',
    clientId: 'fsIKyx2ixSdOrKiLQByn7K5mbuY9iL4Q',
    audience: 'https://shipl.eu.auth0.com/api/v2/',
    apiOrigin: 'https://maytana-backend.herokuapp.com',
    budgetInsightClientId: '39085976',
    budgetInsightPrefixUrl: 'shipl-sandbox',
    truelayerClientId: 'sandbox-metabank-8dba08'
  }
} else {
  config = {
    apiOrigin: 'https://api.getmetabank.com',
    // apiOrigin: 'http://localhost:3001',
    budgetInsightClientId: '91873194',
    budgetInsightPrefixUrl: 'maytana',
    cognitoPoolId: 'us-east-1_WjsJmgguc',
    cognito: {
      aws_project_region: 'us-east-1',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_WjsJmgguc',
      aws_user_pools_web_client_id: '2jf5h1gaeljfvm6oqmu5mqfdvb',
      oauth: {
        domain: 'maytana.auth.us-east-1.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://app.maytana.com',
        // redirectSignIn: 'http://localhost:3000',
        redirectSignOut: 'https://maytana.com',
        responseType: 'token'
      }
    }
  }
}

module.exports = config
