import {
  SET_CONSOLIDATED_CASHFLOW,
  SET_ACCOUNT_CASHFLOW,
  SET_PERIOD_CASHFLOW,
  SET_CONSOLIDATED_BALANCE_RANGE,
  SET_LAST_MONTH_CONSOLIDATED_CASHFLOW
} from '../actions/constants'

const initState = {
  consolidated: [],
  lastMonth: [],
  accounts: [],
  period: [],
  balanceRange: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_CONSOLIDATED_CASHFLOW:
      return {
        ...state,
        consolidated: [...state.consolidated, action.payload]
      }
    case SET_ACCOUNT_CASHFLOW: {
      return {
        ...state,
        accounts: [...state.accounts, action.payload]
      }
    }
    case SET_PERIOD_CASHFLOW: {
      return {
        ...state,
        period: [initState.period, action.payload]
      }
    }
    case SET_LAST_MONTH_CONSOLIDATED_CASHFLOW: {
      return {
        ...state,
        lastMonth: [...state.lastMonth, action.payload]
      }
    }
    case SET_CONSOLIDATED_BALANCE_RANGE: {
      return {
        ...state,
        balanceRange: action.payload
      }
    }
    default:
      return state
  }
}
