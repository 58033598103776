import { getRequest } from 'API/ApiClient'
import { ANALYTICS_LOADING, GET_ANALYTICS } from './constants'
import { showError } from './errorActions'

export const getCashFlowAnalytics = currency => async (dispatch, getState) => {
  const path = `/api/analytics/cashflow?currency=${currency}`
  const { analytics } = getState()
  dispatch({ type: ANALYTICS_LOADING })
  return getRequest({ path }).then(res => {
    const { cashIn, cashOut } = res.json
    analytics.cashflowAnalytics = {
      cashIn,
      cashOut
    }
    dispatch({ type: GET_ANALYTICS, payload: analytics })
    return res.json
  }).catch(err => {
    showError(err.message || err.msg)(dispatch)
    dispatch({ type: GET_ANALYTICS, payload: analytics })
  })
}

export const getCashReport = currency => async (dispatch, getState) => {
  const path = '/api/analytics/reports/last?currency=USD'

  try {
    const { json } = await getRequest({ path: path })
    dispatch({ type: 'SET_REPORT', payload: json })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAccountAnalytics = (currency, account) => async (dispatch, getState) => {
  const path = `/api/analytics/cashflow?currency=${currency}&account=${account}`
  const { analytics } = getState()
  return getRequest({ path }).then(res => {
    const { cashIn, cashOut } = res.json
    analytics.accountAnalytics = {
      accountId: account,
      cashIn,
      cashOut
    }
    dispatch({ type: GET_ANALYTICS, payload: analytics })
    return res.json
  }).catch(err => {
    showError(err.message || err.msg)(dispatch)
    dispatch({ type: GET_ANALYTICS, payload: analytics })
  })
}

export const getBalanceInRange = (start, end, currency, accId) => async (dispatch, getState) => {
  let params = `?start=${start}&end=${end}&currency=${currency}&conversionType=converted`
  let key = 'balanceRange'
  if (accId) {
    params += `&accountId=${accId}`
    key = 'accoutBalanceRange'
  }
  const { analytics } = getState()
  dispatch({ type: ANALYTICS_LOADING })
  return getRequest({ path: '/api/balances/range', params }).then(res => {
    const { periodBalance } = res.json
    analytics[key] = {
      periodBalance
    }
    if (accId) {
      analytics[key].accountId = accId
    }
    dispatch({ type: GET_ANALYTICS, payload: analytics })
    return res.json
  }).catch(err => {
    showError(err.message || err.msg)(dispatch)
    dispatch({ type: GET_ANALYTICS, payload: analytics })
  })
}

export const getPrimaryAndComparisonBalance = (compareStart, compareEnd, primaryStart, primaryEnd, accountId = '') => async (dispatch, getState) => {
  let params = `?compareStart=${compareStart}&compareEnd=${compareEnd}&primaryStart=${primaryStart}&primaryEnd=${primaryEnd}`
  if (accountId) {
    params += `&accountId=${accountId}`
  }
  return getRequest({ path: '/api/balances/latest/by-month', params }).then(res => {
    const { latestBalance, comparisonBalance } = res.json
    const { analytics: payload } = getState()
    Object.assign(payload, { latestBalance, comparisonBalance })
    dispatch({ type: GET_ANALYTICS, payload })
    return true
  }).catch(err => {
    showError(err.message || err.msg)(dispatch)
  })
}
