import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Router,
  Switch,
  Route,
  useLocation,
  withRouter
} from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import CompanyStep from 'views/Signup/CompanyStep'
import ConnectStep from 'views/Signup/ConnectStep'
import LegalEntityStep from 'views/Signup/LegalEntityStep'
import StepSidebar from 'views/Signup/StepSidebar'

import history from '../history'

import Mountain from 'assets/img/login-layout.webp'

const stepsSideData = [
  { value: 'Workspace Info', path: '/onboarding/company' },
  { value: 'Legal Entities', path: '/onboarding/entities' },
  { value: 'Connect Bank Accounts', path: '/onboarding/connect' }
]

const headerData = [
  { header: 'First, create your workspace', description: "We'll need a few details to get your started" },
  { header: 'Attach, a legal entity to the ', description: "Search for your business to add it's legal details" },
  { header: 'Connect at least one bank account to ', description: 'Connect multiple accounts using our secure third party provider' }
]

const SignupLayout = (props) => {
  const location = useLocation()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    updateIndex()
  }, [props.company, props.entities, props.accounts])

  const updateIndex = () => {
    switch (location.pathname) {
      case '/onboarding/company':
        setIndex(0)
        break
      case '/onboarding/entities':
        setIndex(1)
        break
      case '/onboarding/connect':
        setIndex(2)
        break
      default:
        setIndex(0)
        break
    }
  }

  return (
    <div style={{ backgroundImage: `url(${Mountain})` }} id='app'>
      <main style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
        <div className='bg-white justify-content-center step-signup' style={{ height: 'calc(100vh - 30px)' }}>
          <Row className='header-container' style={{ margin: '15px' }}>
            <Col xs='5' sm='3'>
              <h1 className='active-step-index'>
                <Row style={{ flexWrap: 'nowrap' }} className='steps-container'>
                  <span className='active-idx'>0{(index + 1)}</span>
                  <span className='inactive-idx'>/03</span>
                </Row>
              </h1>
            </Col>
            <Col xs='9'>
              <h1 className='step-title text-xs-center'>
                {headerData[index].header}
                {index === 1 && <><span style={{ color: '#007AFF' }}>{props.company.company_name}</span> workspace</>}
                {index === 2 && <span style={{ color: 'black' }}>{props.entities.hle.legal_business_name}</span>}
              </h1>
              <p style={{ color: 'gray' }}>{headerData[index].description}</p>
            </Col>
          </Row>
          <Row className='position-relative' style={{ height: '80%', margin: '20px' }}>
            <Col xs='5' sm='3' className='steps-sidebar-container'>
              <StepSidebar
                stepsSideData={stepsSideData}
                updateIndex={updateIndex}
              />
            </Col>
            <Col md='9' style={{ height: '100%', overflow: 'scroll' }}>
              <Router history={history}>
                <Switch>
                  <Route path='/onboarding/company'>
                    <CompanyStep setIndex={setIndex} />
                  </Route>
                  <Route path='/onboarding/entities'>
                    <LegalEntityStep setIndex={setIndex} />
                  </Route>
                  <Route path='/onboarding/connect'>
                    <ConnectStep setIndex={setIndex} />
                  </Route>
                </Switch>
              </Router>
            </Col>
          </Row>
        </div>
      </main>
    </div>
  )
}

const propMap = state => ({
  company: state.company,
  entities: state.entities,
  accounts: state.accounts,
  authorization: state.authorization
})

export default withRouter(connect(propMap)(SignupLayout))
