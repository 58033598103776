import { combineReducers } from 'redux'

import accounts from './accounts'
import balances from './balances'
import transactions from './transactions'
import invoices from './invoices'
import company from './company'
import entities from './entities'
import errors from './errors'
import authorization from './authorization'
import consolidated from './consolidated'
import cashflow from './cashflow'
import analytics from './analytics'
import team from './team'
import alerts from './alerts'
import paginatedTransactions from './paginatedTransactions'
import settings from './settings'
import auth from './auth'
import message from './message'

export default combineReducers(
  {
    auth,
    message,
    accounts,
    entities,
    balances,
    transactions,
    invoices,
    company,
    errors,
    consolidated,
    alerts,
    cashflow,
    analytics,
    team,
    paginatedTransactions,
    settings,
    authorization
  }
)
