import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import validator from 'validator'
import { getRequest } from 'API/ApiClient'
import SearchInput from './SearchInput'
import CustomInput from 'components/CustomInput'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { createLegalEntity } from 'actions/entityActions'
import { countries } from '../../util'
import history from '../../history'

const CreateLegalEntity = props => {
  const [initSearch, setInitSearch] = useState(true)
  const [suggestions, setSuggestions] = useState(null)
  const [showBtn, setShowBtn] = useState(false)
  const [loading, setLoading] = useState(false)

  const [countryOfIncorporation, setCountryOfIncorporation] = useState('')
  const [legalBusinessName, setLegalBusinessName] = useState('')
  const [registrationNumber, setRegistrationNumber] = useState('')
  const [businessType, setBusinessType] = useState('')
  const [dateOfIncorporation, setDateOfIncorporation] = useState('')
  const [addressStreet, setAddressStreet] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressZip, setAddressZip] = useState('')
  const [holding, setHolding] = useState(true)
  const [subsidiaryOf, setSubsidiaryOf] = useState({})

  const [subsidiaryOfOptions, setSubsidiaryOfOptions] = useState([])
  const [subsidiaryOfHide, setSubsidiaryOfHide] = useState(true)

  const [subTrigger, setSubTrigger] = useState(false)

  useEffect(() => {
    const opts = props.entities.legalEntities.map(sub => ({ value: sub.id, label: sub.legal_business_name }))
    setSubsidiaryOfHide(false)
    setSubsidiaryOfOptions(opts)
  }, [props.entities, subTrigger])

  const handleLegalNameChange = async e => {
    e.persist()
    if (e.key === 'Enter') {
      try {
        let params = `?legalBusinessName=${legalBusinessName}`
        if (countryOfIncorporation.length > 1) {
          params += `&country_code=${countryOfIncorporation}`
        }

        const { json: { result } } = await getRequest({ path: '/api/entities/search', params })
        if (!result.length) {
          setSuggestions(null)
          return
        }
        setSuggestions(result)
      } catch (error) {
        console.error(error)
        setInitSearch(false)
      }
    }
  }

  const autofillCompanyDetails = details => {
    setInitSearch(false)
    setCountryOfIncorporation(details.country)
    setLegalBusinessName(details.name)
    setRegistrationNumber(details.company_number)
    setDateOfIncorporation(details.incorporation_date)
    setBusinessType(details.company_type || '')
    setAddressStreet(details.registered_address.street_address || '')
    setAddressCity(details.registered_address.locality || '')
    setAddressZip(details.registered_address.postal_code || '')
    setSuggestions(null)
    setShowBtn(true)
  }

  const setInitState = () => {
    setCountryOfIncorporation('')
    setLegalBusinessName('')
    setRegistrationNumber('')
    setDateOfIncorporation('')
    setBusinessType('')
    setAddressStreet('')
    setAddressCity('')
    setAddressZip('')
    setHolding(null)
    setSubsidiaryOf({})
    setSuggestions('')
    setSubTrigger(!subTrigger)
    setLoading(false)
  }

  const validateFields = () => {
    if (!validator.isPostalCode(addressZip || '', 'any')) {
      window.alert('Enter the correct zip')
      return
    }
    if (addressCity.length === 0) {
      window.alert('Enter the correct city')
      return
    }
    if (addressStreet.length === 0) {
      window.alert('Enter a correct street')
      return
    }
    if (businessType.length === 0) {
      window.alert('Enter a correct business type')
      return
    }
    if (countryOfIncorporation.length === 0) {
      window.alert('Enter a correct country')
      return
    }
    if (legalBusinessName.length === 0) {
      window.alert('Enter a correct legal business name')
      return
    }
    if (registrationNumber.length === 0) {
      window.alert('Enter a correct registation number')
      return
    }
    return true
  }

  const handleSaveClick = async () => {
    if (validateFields()) {
      const body = {
        countryOfIncorporation,
        legalBusinessName,
        registrationNumber,
        dateOfIncorporation,
        businessType,
        addressZip,
        addressStreet,
        addressCity,
        holding: props.onboarding ? holding : false,
        subsidiaryOf: subsidiaryOf.value
      }
      setLoading(true)
      await props.createLegalEntity(body)
      setInitState()
      setLoading(false)

      if (props.onboarding) {
        history.push('/onboarding/connect')
        props.setIndex(2)
      }

      if (props.toggleModal) {
        props.toggleModal()
      }
    } else {
      window.alert('Fill all fields')
    }
  }

  return (
    <Row style={{ paddingBottom: '20px' }}>
      <Col xs='12'>
        <CustomInput
          name='countryOfIncorporation'
          labelName='Country Of Incorporation'
          type='select'
          value={countryOfIncorporation}
          valueLabel={countryOfIncorporation}
          options={countries}
          onChange={e => setCountryOfIncorporation(e.target.valueLabel)}
          completed={countryOfIncorporation !== ''}
        />
      </Col>
      <Col xs='12' className='my-3'>
        <SearchInput
          name='legalBusinessName'
          labelName='Business Legal Name'
          value={legalBusinessName}
          suggestions={suggestions}
          setValue={autofillCompanyDetails}
          onKeyUp={e => handleLegalNameChange(e)}
          onChange={e => setLegalBusinessName(e.target.value)}
        />
      </Col>
      <Col xs='12' className='my-3'>
        Can't find your business? {' '}
        <span
          onClick={() => {
            setInitSearch(false)
            setShowBtn(true)
          }}
          style={{ color: '#007AFF', cursor: 'pointer' }}
        >
          Enter details manually
        </span>
      </Col>
      {!initSearch &&
        <>
          <Col xs='6' className='my-3'>
            <CustomInput
              name='registrationNumber'
              labelName='Business Registration Number'
              type='text'
              value={registrationNumber}
              onChange={e => setRegistrationNumber(e.target.value)}
              completed={registrationNumber !== ''}
            />
          </Col>
          <Col xs='6 my-3'>
            <CustomInput
              name='businessType'
              labelName='Business type'
              type='text'
              value={businessType}
              onChange={e => setBusinessType(e.target.value)}
              completed={businessType !== ''}
            />
          </Col>
          <Col xs='6 my-3'>
            <CustomInput
              name='dateOfIncorporation'
              labelName='Date Of Incorporation'
              type='date'
              value={dateOfIncorporation}
              onChange={e => setDateOfIncorporation(e.target.value)}
              completed={dateOfIncorporation !== ''}
            />
          </Col>
          <Col xs='6 my-3'>
            <CustomInput
              name='addressStreet'
              labelName='Street'
              type='text'
              value={addressStreet}
              onChange={e => setAddressStreet(e.target.value)}
              completed={addressStreet !== ''}
            />
          </Col>
          <Col xs='6 my-3'>
            <CustomInput
              name='addressZip'
              labelName='Zip Code'
              type='text'
              value={addressZip}
              onChange={e => setAddressZip(e.target.value)}
              completed={addressZip !== ''}
            />
          </Col>
          <Col xs='6 my-3'>
            <CustomInput
              name='addressCity'
              labelName='City'
              type='text'
              value={addressCity}
              onChange={e => setAddressCity(e.target.value)}
              completed={addressCity !== ''}
            />
          </Col>
          {props.onboarding
            ? (
              <Col xs='6 my-3'>
                <CustomInput
                  name='holding'
                  labelName='This is the holding or only legal entity'
                  type='checkbox'
                  value={holding}
                  onChange={e => setHolding(e.target.value)}
                  completed={holding !== ''}
                />
              </Col>)
            : (
              <Col xs='6 my-3'>
                <CustomInput
                  name='subsidiaryOf'
                  labelName='Subsidiary of'
                  type='select'
                  value={subsidiaryOf}
                  valueLabel={subsidiaryOf.valueLabel}
                  options={subsidiaryOfOptions}
                  hide={subsidiaryOfHide}
                  onChange={e => setSubsidiaryOf(e.target)}
                />
              </Col>
              )}
        </>}
      {showBtn && (
        <Col
          className='float-left mt-3 w-100 pb-2'
          style={{ display: 'flex', justifyContent: 'start' }}
          xs='12'
        >
          <PrimaryButton
            text='Save Entity'
            loading={loading}
            handler={handleSaveClick}
          />
        </Col>)}
    </Row>
  )
}

const propMap = state => ({
  company: state.company,
  entities: state.entities
})

export default connect(propMap, {
  createLegalEntity
})(CreateLegalEntity)
