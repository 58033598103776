import React from 'react'
import { DropdownItem } from 'reactstrap'
import { timeInterval } from '../../util'
import UniversalDropdown from './UniversalDropdown'

const TimeIntervalDropDown = props => {
  const displayPeriodIntervalList = timeInterval.filter(tp => tp.value !== props.selectedPeriodInterval.value)

  console.log('displayPeriodIntervalList', displayPeriodIntervalList)

  return (
    <UniversalDropdown
      menuSize='sm'
      toggleText={props.selectedPeriodInterval.name}
    >
      {displayPeriodIntervalList.map(period => {
        console.log('period', period)

        return (
          <DropdownItem
            key={period.value}
            onClick={() => props.setSelectedPeriodInterval(period)}
          >{period.name}
          </DropdownItem>
        )
      })}
    </UniversalDropdown>
  )
}

export default TimeIntervalDropDown
