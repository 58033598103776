
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { fetchInvoices } from '../../actions/invoiceActions'
import TimePeriodDropDown from 'components/DropDowns/TimeDropDown'
import icon from '../../assets/icons/icon-category.svg'

const InvoicesContent = (props) => {
  const [groupedTx, setGroupedTx] = useState([])

  useEffect(() => {
    props.fetchInvoices()
  }, [])

  const cleanInvoicesData = (invoice) => {
    console.log('invoice', invoice)

    const {
      id,
      aggregator_invoice_id: invoiceId,
      invoice_category: invoiceCategory,
      gross_amount: amount,
      iso_currency_code: currency,
      status,
      file_name: fileName,
      due_date: dueDate,
      merchant_name: merchantName,
      sender_name: senderName,
      payment_status: paymentStatus,
      notes,
      date,
      merchant_logo_url: ogLogo
    } = invoice
    let logoURL = icon
    let isLogo = false
    if (ogLogo) {
      logoURL = ogLogo
      isLogo = true
    }

    return {
      id,
      invoiceId,
      isLogo,
      paymentStatus,
      invoiceCategory,
      logoURL,
      amount,
      fileName,
      date,
      notes,
      currency,
      status,
      dueDate,
      merchantName,
      senderName
    }
  }

  useEffect(() => {
    const result = {}
    const target = []

    const cleanedInvoices = props.invoices.map(invoice => cleanInvoicesData(invoice))

    // Group transaction by date
    cleanedInvoices.forEach(invoice => {
      const txDate = moment(invoice.date).format('ddd, D, MMMM')
      if (result[txDate]) {
        result[txDate].push(invoice)
      } else {
        result[txDate] = [invoice]
      }
    })

    // Transform object into array
    for (const date in result) {
      if (Object.prototype.hasOwnProperty.call(result, date)) {
        const element = result[date]
        target.push({
          id: uuidv4(),
          date,
          children: element
        })
      }
    }

    setGroupedTx(target)
  }, [props.invoices])

  const getInvoiceRows = () => {
    if (groupedTx.length === 0) {
      return <div />
    }
    return groupedTx.map(group => (
      <div className='period-tx-wrapper' key={group.id}>
        <span className='period-text'>{group.date}</span>
        <div className='period-tx'>
          {
            group.children.map(invoice => {
              const {
                id,
                invoiceId,
                isLogo,
                logoURL,
                amount,
                invoiceCategory,
                currency,
                paymentStatus,
                merchantName,
                senderName
              } = invoice

              const rowClass = props.txDetails && props.txDetails.id && props.txDetails.id === id ? 'period-tx-details selected' : 'period-tx-details'
              let transformStyle = 'rotate(180deg)'
              if (isLogo) {
                transformStyle = ''
              }

              return (
                <div
                  id='invoice-row'
                  key={id}
                  className={rowClass}
                  onClick={() => props.setTransactionDetails(invoice)}
                  onMouseEnter={() => props.setTransactionDetails(invoice)}
                >
                  <div className='invoice-row-left details-1'>
                    <img
                      className='tx-icon'
                      src={logoURL}
                      style={amount > 0 ? { borderRadius: '50%' } : { transform: transformStyle, borderRadius: '50%' }}
                    />
                    <div className='tx-detail-wrapper'>
                      <span className='tx-title'>{merchantName || senderName}</span>
                      <span className='tx-category'>{invoiceCategory}</span>
                    </div>
                  </div>
                  <div className='invoice-row-middle details-1'>
                    <div className='tx-detail-wrapper'>
                      <span style={{
                        textTransform: 'uppercase',
                        backgroundColor: '#dbde39bd',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        borderRadius: '5px',
                        display: 'block'
                      }}
                      >{paymentStatus}
                      </span>
                    </div>
                  </div>
                  <div className='invoice-row-right details-2'>
                    <div className='tx-meta-wrapper'>
                      <span className='tx-amt'>{currency} {amount.toFixed(2).toLocaleString()}</span>
                      <span className='tx-bank text-gray'>{invoiceId}</span>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    ))
  }

  return (
    <>
      <div id='transactions-content'>
        <div className='transactions-header'>
          <span>
            Invoices from received in the last
            <TimePeriodDropDown
              selectedTimePeriod={props.period.text}
              setSelectedTimePeriod={props.selectPeriod}
            />
          </span>
        </div>
        <div className='transactions-list dashboard-transactions'>
          {getInvoiceRows()}
        </div>
      </div>
    </>
  )
}

const propMap = state => ({
  invoices: state.invoices.invoices
})

export default connect(propMap, { fetchInvoices })(InvoicesContent)
