import React from 'react'
import {
  Spinner
} from 'reactstrap'
import LoginLayout from '../assets/img/login-layout.webp'

const Loading = () => {
  return (
    <div
      id='loading'
      style={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: `url(${LoginLayout})`
      }}
    >
      <div className='mainLoading' />
      <div className='align-items-center footer-loading'>
        <h2>
          <Spinner color='white' />
          {'  '} Getting ready...
        </h2>
      </div>
    </div>
  )
}

export default Loading
