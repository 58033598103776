import React from 'react'

const TextLogo = () => {
  return (
    <p
      style={{
        fontFamily: 'sf-pro-text-light',
        textAlign: 'right',
        fontWeight: '200',
        textTransform: 'uppercase',
        letterSpacing: '2px',
        color: 'white',
        fontSize: '1em',
        marginBottom: '7kpx',
        marginTop: '7px'
      }}
    >
      Maytana
    </p>
  )
}

export default TextLogo
