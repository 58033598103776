import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Col, Row } from 'reactstrap'
import moment from 'moment'

const CashFlowDetails = forwardRef(({}, ref) => {
  const [primPeriod, setPrimPeriod] = useState(null)
  const [compPeriod, setCompPeriod] = useState(null)

  const setPrimaryPeriod = data => {
    setPrimPeriod(data)
  }

  const setComparisonPeriod = data => {
    setCompPeriod(data)
  }

  useImperativeHandle(ref, () => {
    return {
      setPrimaryPeriod: setPrimaryPeriod,
      setComparisonPeriod: setComparisonPeriod
    }
  })

  const formatAmt = numAmt => {
    if (!numAmt) return '0.00'
    return numAmt.toFixed(2).toLocaleString()
  }

  const hide = !primPeriod || !compPeriod

  const getColumns = () => {
    if (hide) {
      return <div />
    }
    const { period: primaryPeriod, bankBalance, cashIn: primCashIn, cashOut: primCashOut } = primPeriod
    const netPrimFlow = formatAmt(primCashIn + primCashOut)

    const { period: secPeriod, bankBalance: secBal, ashIn: secIn, cashOut: secOut } = compPeriod
    const netSecFlow = formatAmt(secIn + secOut)
    const netChange = netSecFlow - netPrimFlow
    let moDiff = moment(primPeriod).diff(moment(secPeriod), 'months') + ' month'
    if (moDiff > 1) {
      moDiff += 's'
    }

    const rows = {
      primary: [
        {
          data: moment(primaryPeriod).format('MMM YYYY'),
          meta: 'Period'
        },
        {
          data: formatAmt(primCashIn),
          meta: 'Money in'
        },
        {
          data: formatAmt(primCashOut),
          meta: 'Money out'
        },
        {
          data: netPrimFlow,
          meta: 'Cashflow'
        },
        {
          data: formatAmt(Number(bankBalance)) || 0,
          meta: 'Closing balance'
        }
      ],
      comparison: [
        {
          data: moment(secPeriod).format('MMM YYYY'),
          meta: moDiff
        },
        {
          data: formatAmt(secIn),
          meta: 'Money in'
        },
        {
          data: formatAmt(secOut),
          meta: 'Money out'
        },
        {
          data: netSecFlow,
          meta: 'Cashflow'
        },
        {
          data: formatAmt(Number(secBal)) || 0,
          meta: formatAmt(netChange)
        }
      ]
    }

    return (
      rows.primary.map((d, i) => {
        return (
          <div key={i}>
            {
            i === 0
              ? (
                <Row>
                  <Col md='6'>
                    <h3 className='text-left'>Selection</h3>
                  </Col>
                  <Col md='6'>
                    <h3 className='text-right'>Comparison</h3>
                  </Col>
                </Row>
                )
              : <div />
            }
            <Row className='period-row'>
              <Col md='6'>
                <div className='period-data'>
                  <span className='d-block cft-text'>
                    {d.data}
                  </span>
                  <span className='d-block cft-meta'>
                    {d.meta}
                  </span>
                </div>
              </Col>
              <Col md='6' className='comparison-col'>
                <div className='period-data'>
                  <span className='d-block cft-text text-right'>
                    {rows.comparison[i].data}
                  </span>
                  <span className='d-block cft-meta text-right'>
                    {rows.comparison[i].meta}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        )
      })
    )
  }

  return (
    hide
      ? (
        <div style={{ height: '700px' }} />
        )
      : (
        <div className='cash-flow-table' style={{ height: '700px' }}>
          <Row>
            <Col xs='12'>
              <h2 className='text-left mb-4'>
                Breakdown
              </h2>
            </Col>
          </Row>
          {getColumns()}
        </div>
        )
  )
})

export default CashFlowDetails
