import CustomInput from 'components/CustomInput'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'

const ShopifyModal = ({ setShopName, loading, shopname, toggle, isOpen, handleSave }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <span style={{ fontSize: '1.25rem', fontWeight: '600' }}>Connect Shopify store</span>
      </ModalHeader>
      <ModalBody style={{ overflow: 'auto' }}>
        <CustomInput onChange={setShopName} {...shopname} />
      </ModalBody>
      <ModalFooter>
        {loading ? (
          <div className='d-block' style={{ margin: '0 50%' }}><Spinner size='sm' color='primary' /></div>
        ) : <div className='btn btn-block btn-primary' onClick={handleSave}>Connect</div>}
      </ModalFooter>
    </Modal>
  )
}

export default ShopifyModal
