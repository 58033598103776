const {
  GET_COMPANY,
  SET_COMPANY,
  COMPANY_LOADING,
  INITIALIZE_STATES
} = require('actions/constants')

const initState = {
  company: null,
  users: [],
  loading: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_COMPANY:
    case SET_COMPANY:
      return {
        ...action.payload,
        loading: false
      }
    case COMPANY_LOADING: {
      return {
        ...action.payload,
        loading: true
      }
    }
    case 'SET_COMPANY_USERS': {
      return {
        ...state,
        users: action.payload
      }
    }
    case INITIALIZE_STATES:
      return initState
    default:
      return state
  }
}
