import { getRequest, putRequest } from 'API/ApiClient'
import {
  GET_SETTINGS,
  UPDATE_SETTINGS,
  SET_USER_ATTRIBUTES
} from './constants'
import { showError } from './errorActions'

export const fetchAllSettings = () => async dispatch => {
  const payload = {
    companySettings: {},
    profile: {},
    apiTokens: {}
  }

  try {
    const resC = await getRequest({ path: '/api/company' })
    const { company_name: companyName, default_currency: currency } = resC.json.company
    payload.companySettings = {
      companyName,
      currency
    }
    const resA = await getRequest({ path: '/api/company/role' })
    payload.apiTokens = {
      ...resA.json.dbConnection
    }
    dispatch({ type: GET_SETTINGS, payload })
  } catch (err) {
    showError(err.msg || 'Failed to fetch settings')(dispatch)
  }
}

export const updateProfileSettings = ({ firstName, lastName }) => async (dispatch, getState) => {
  const { company: payload } = getState()

  try {
    const body = { firstName, lastName }
    await putRequest({ path: '/api/company/profile', body })
    const newPayload = { ...payload, given_name: firstName, family_name: lastName }
    dispatch({ type: SET_USER_ATTRIBUTES, newPayload })
  } catch (err) {
    showError(err.msg || 'Failed to fetch settings')(dispatch)
  }
}

export const updateWorkspaceSettings = ({ companyName, currency }) => async (dispatch, getState) => {
  const { company: payload } = getState()
  try {
    const body = { companyName, currencyCode: currency }
    await putRequest({ path: '/api/company/general', body })
    const newPayload = { ...payload, companyName, currency }
    dispatch({ type: SET_USER_ATTRIBUTES, newPayload })
  } catch (err) {
    showError(err.msg || 'Failed to fetch settings')(dispatch)
  }
}

export const updateCompanySetting = (body, type, endpoint) => async (dispatch, getState) => {
  const { company: payload } = getState()

  try {
    const res = await putRequest({ path: endpoint, body })
    if (type === 'company') {
      const { company_name: companyName, default_currency: currency } = res.json.company
      payload.companySettings = {
        companyName,
        currency
      }
      dispatch({ type: UPDATE_SETTINGS, payload })
    } else if (type === 'profile') {
      const { firstName, lastName } = res.json.company
      const newPayload = { ...payload, given_name: firstName, family_name: lastName }
      dispatch({ type: SET_USER_ATTRIBUTES, newPayload })
    }
  } catch (err) {
    showError(err.msg || 'Failed to fetch settings')(dispatch)
  }
}
