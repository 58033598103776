import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Amount from 'components/Amount'
import { getLastConsolidatedBalances } from 'actions/consolidatedActions'
import { getCashReport } from 'actions/analyticsActions'

const getTimeOfDay = () => {
  const hour = new Date().getHours()
  if (hour >= 4 && hour <= 11) return 'morning'
  if (hour >= 12 && hour <= 16) return 'afternoon'
  if (hour >= 17 && hour <= 20) return 'evening'
  if (hour >= 21 || hour <= 3) return 'night'
}

const Home = props => {
  const [timeOfDay, setTimeOfDay] = useState(getTimeOfDay())
  const [balance, setBalance] = useState(0.00)
  const [amountSize, setAmountSize] = useState(30)
  const [selectedCurrency, setSelectedCurrency] = useState({
    symbol: '$',
    value: 'USD',
    front: true
  })

  useEffect(() => {
    props.getCashReport()
    const size = window.innerHeight <= 900 ? 30 : 40
    setAmountSize(size)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => setTimeOfDay({ timeOfDay: getTimeOfDay() }), 6000000)
    return () => clearInterval(interval)
  })

  useEffect(() => {
    const balanceToFind = props.lastBalances.find(({ target_currency: currency }) => currency === selectedCurrency.value)
    if (!balanceToFind) {
      props.getLastConsolidatedBalances(selectedCurrency.value)
      return
    }

    setBalance(balanceToFind.balance_current_rate)
  }, [props.lastBalances])

  return (
    <>
      <div className='container h-100'>
        <div className='row h-100 justify-content-center align-items-center'>
          <div className='main-text'>
            <h2 className='greeting'>
              Good {timeOfDay}, {props.user.given_name}
            </h2>
            <div className='metric text-align-center'>
              <span className='metric'>{props.company.company_name}'s available balance is </span> <Amount amount={balance} fontSize={amountSize} /> {selectedCurrency.value}
            </div>
            <div className='metric text-align-center'>
              <span style={{ fontSize: '25px' }} className='metric'>Which translates into {props.analytics.reports.runway} months of runway</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const propMap = state => ({
  lastBalances: state.consolidated.lastBalances,
  company: state.company,
  user: state.auth.user.attributes,
  analytics: state.analytics
})

export default connect(propMap, { getLastConsolidatedBalances, getCashReport })(Home)
