import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TransactionsContent from './TransactionsContent'
import TransactionDetails from './TransactionDetails'
import moment from 'moment'
import {
  fetchFilterTransactions
} from 'actions/paginateTransactions'

const Transactions = props => {
  const [period, setPeriod] = useState({ text: '3 months', value: 90 })
  const [txDetails, setTxDetails] = useState({})
  const [selectedAccount, setSelectedAccount] = useState(null)

  useEffect(() => {
    props.fetchFilterTransactions()
  }, [])

  const selectAccount = (accountId) => {
    setSelectedAccount(accountId)
    if (accountId) {
      const params = `single=${accountId}`
      props.fetchFilterTransactions(params, 0)
    } else {
      props.fetchFilterTransactions()
    }
  }

  const selectPeriod = (period) => {
    setPeriod(period)
    const to = moment().format('YYYY-MM-DD')
    const from = moment().subtract(period.value, 'days').format('YYYY-MM-DD')
    if (selectedAccount) {
      const params = `single=${selectedAccount}?dateFrom=${from}&dateTo=${to}`
      props.fetchFilterTransactions(params)
    } else {
      props.fetchFilterTransactions(`dateFrom=${from}&dateTo=${to}`)
    }
  }

  return (
    <>
      <div className='item2'>
        <TransactionsContent
          period={period}
          txDetails={txDetails}
          setTransactionDetails={setTxDetails}
          selectPeriod={selectPeriod}
          selectAccount={selectAccount}
          selectedAccount={selectedAccount}
        />
      </div>
      <div className='item3'>
        <div className='tab-sidebar'>
          <TransactionDetails
            period={period}
            setPeriod={setPeriod}
            txDetails={txDetails}
            timePeriod={period}
            setTransactionDetails={setTxDetails}
          />
        </div>
      </div>
    </>
  )
}

const propMap = state => ({
  transactions: state.transactions,
  balances: state.balances,
  accounts: state.accounts
})

export default connect(propMap, { fetchFilterTransactions })(Transactions)
