import Cohere from 'cohere-js'
import {
  federatedSignIn,
  signOut,
  signIn,
  completeNewPassword
} from '../services/auth'
import {
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE
} from './constants'

export const startFederatedLogIn = () => async dispatch => {
  try {
    window.analytics.track('Started Login')
    await federatedSignIn()
    dispatch({ type: LOGIN_STARTED })
  } catch (error) {
    console.error(error)
    dispatch({ type: LOGIN_FAIL })
    dispatch({
      type: SET_MESSAGE,
      payload: error.message
    })
  }
}

export const finishLogin = user => async dispatch => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { user }
  })
}

export const signInWithEmailAndPassword = (username, password, newPwd, userOldPwd) => async dispatch => {
  try {
    window.analytics.track('Started Login')
    dispatch({ type: LOGIN_STARTED })
    if (newPwd) {
      await completeNewPassword(userOldPwd, password)
    } else {
      const user = await signIn(username, password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch({ type: 'LOGIN_PASSWORD_REQUIRED' })
        return { type: 'NEW_PASSWORD_REQUIRED', user }
      }
    }
  } catch (error) {
    console.log('error signing in', error)
    return error
  }
}

export const logout = () => async dispatch => {
  await signOut()
  dispatch({ type: LOGOUT })
}
