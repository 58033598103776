const {
  SET_CONSOLIDATED_BALANCES,
  SET_ACCOUNTS_BALANCE
} = require('actions/constants')

const initState = {
  consolidatedBalances: [],
  accountBalances: [],
  lastBalances: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_CONSOLIDATED_BALANCES:
      return {
        ...state,
        consolidatedBalances: action.payload.consolidatedBalances
      }
    case 'SET_LAST_CONSOLIDATED_BALANCES':
      return {
        ...state,
        lastBalances: [...state.lastBalances, action.payload].filter(Boolean)
      }
    case SET_ACCOUNTS_BALANCE:
      return {
        ...state,
        accountBalances: [...state.accountBalances, action.payload]
      }
    default:
      return state
  }
}
