import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Tree, TreeNode } from 'react-organizational-chart'
import { Col, Row, Container } from 'reactstrap'
import ReactModal from 'react-modal'
import CreateLegalEntity from 'views/LegalEntities/CreateLegalEntity'
import LegalEntityCard from './LegalEntityCard'
import CrossIcon from '../../assets/icons/cross.svg'

ReactModal.setAppElement('body')

export const LegalEntities = ({ setLegalSelectedLegalEntity, entities: { hle } }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = () => setModalOpen(!modalOpen)

  return (
    <Container className='mt-4 align-items-center' style={{ overflow: 'scroll' }}>
      <h1 className='mb-4' style={{ textAlign: 'center' }}>Select a legal entity or <span style={{ color: '#007AFF', cursor: 'pointer' }} onClick={toggleModal}>create one</span></h1>
      <Row className='align-items-center'>
        <Col className='align-items-center'>
          {hle && hle.subsdiariesEntites
            ? (
              <Tree label={<LegalEntityCard {...hle} setLegalSelectedLegalEntity={setLegalSelectedLegalEntity} />}>
                {hle && hle.subsdiariesEntites
                  ? hle.subsdiariesEntites.map(subsidiary => {
                      console.log({ subsidiary })
                      return (
                        <TreeNode
                          key={subsidiary.id} label={
                            <LegalEntityCard
                              {...subsidiary}
                              setLegalSelectedLegalEntity={setLegalSelectedLegalEntity}
                            />
                          }
                        />
                      )
                    })
                  : <div />}
              </Tree>
              )
            : hle && (
              <LegalEntityCard
                {...hle}
                setLegalSelectedLegalEntity={setLegalSelectedLegalEntity}
              />
            )}

        </Col>
      </Row>
      <ReactModal isOpen={modalOpen} toggle={toggleModal}>
        <div id='entity-modal'>
          <div id='entity-modal-header'>
            <img style={{ cursor: 'pointer' }} height='20px' src={CrossIcon} onClick={() => toggleModal()} />
          </div>
          <div className='entity-modal-body'>
            <CreateLegalEntity toggleModal={toggleModal} />
          </div>
        </div>
      </ReactModal>
    </Container>
  )
}

const propMap = state => ({
  entities: state.entities
})

export default connect(propMap)(LegalEntities)
