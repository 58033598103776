import React, { useState } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import history from '../../history'

import Transactions from './Transactions/Transactions'
import Accounts from './Accounts/Accounts'
import CashFlow from './CashFlow/CashFlow'

const Cash = () => {
  const [tabs, setTabs] = useState([
    {
      type: 'accounts',
      name: 'Accounts'
    },
    {
      type: 'transactions',
      name: 'transactions'
    },
    {
      type: 'cashflow',
      name: 'Cash flow'
    }
  ])

  // const [state, setState] = useState({
  //   commandbarCurrency: 'USD',
  //   selectedAccount: {},
  //   showBalance: true,
  //   balanceCurrency: {
  //     symbol: '$',
  //     value: 'USD',
  //     front: true
  //   },
  //   breakdown: null
  // })

  // useEffect(() => {
  //   window.CommandBar.addCallback('reports-currency-change', args => {
  //     setState({ commandbarCurrency: args.currency })
  //   })
  //   // this.props.fetchBalances()
  //   // const end = moment().subtract(30, 'days')
  //   // this.props.fetchPeriodTransactions(end.format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
  //   // if (!this.props.analytics.cashflowAnalytics) {
  //   //   this.props.getCashFlowAnalytics('USD')
  //   // }
  // }, [])

  // async fetchTransactions (periodFrom, periodTo, sortParams) {
  //   this.props.fetchPeriodTransactions(periodFrom, periodTo, sortParams, this.state.selectedAccount.id)
  // }

  const changeActiveTab = newType => {
    history.push(`/admin/cash/${newType}`)
    const index = tabs.findIndex(({ type }) => type === newType)
    const copy = [...tabs].map(tab => ({ ...tab, active: false }))
    copy[index].active = true
    setTabs(copy)
  }

  const isActiveTab = (tabType) => {
    const path = `/admin/cash/${tabType}`
    if (path === history.location.pathname) {
      return true
    }
    return false
  }

  const getTabNavigationContent = () => (
    tabs.map(tab => {
      const { name, type } = tab
      return (
        <div
          key={name}
          onClick={() => changeActiveTab(type)}
          className={isActiveTab(tab.type) ? 'tab tab-btn active' : 'tab tab-btn'}
        >
          <span className='tab-name text-capitalize'>{name}</span>
        </div>
      )
    })
  )

  return (
    <main id='dashboard' className='dashboard'>
      <div className='item1'>
        <div className='tab-nav'>
          {getTabNavigationContent()}
        </div>
      </div>
      <Router history={history}>
        <Switch>
          <Route path='/admin/cash/accounts'>
            <Accounts />
          </Route>
          <Route path='/admin/cash/transactions'>
            <Transactions />
          </Route>
          <Route path='/admin/cash/cashflow'>
            <CashFlow />
          </Route>
          <Redirect from='/admin/cash' to='/admin/cash/accounts' />
        </Switch>
      </Router>
    </main>
  )
}

export default Cash
