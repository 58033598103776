import React from 'react'
import { Spinner } from 'reactstrap'

const ThirdButton = ({ text, loading, handler }) => {
  return (
    <button
      type='button'
      className='btn float-left'
      onClick={handler}
      style={{
        boxShadow: 'none',
        backgroundColor: '#FFFFFF',
        border: '1px solid #EEEEEE',
        boxSizing: 'border-box',
        borderRadius: '2px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px'
      }}
    >
      {loading
        ? <Spinner size='sm' />
        : text}
    </button>
  )
}

export default ThirdButton
