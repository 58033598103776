import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts'
import { setSelectedAccount } from 'actions/accountActions'
import { fetchBalances, updateBalances } from 'actions/balanceActions'
import { getConsolidatedBalances, getLastConsolidatedBalances, fetchAccountBalances } from 'actions/consolidatedActions'
import AccountsDropDown from 'components/DropDowns/AccountsDropDown'
import CurrencyDropDown from 'components/DropDowns/CurrencyDropDown'
import ExchangeRateDropDown from 'components/DropDowns/ExchangeRateDropDown'
import Amount from 'components/Amount'
import { getRequest } from 'API/ApiClient'

const AccountsHeader = props => {
  const [chartSize, setChartSize] = useState(200)
  const [data, setData] = useState([])
  const [balance, setBalance] = useState(0.00)
  const [selectedCurrency, setCurrency] = useState({
    symbol: '$',
    value: 'USD',
    front: true
  })
  const [selectedRate, setExchangeRate] = useState({
    name: 'live exchange rate', value: 'balance_current_rate'
  })

  useEffect(() => {
    const size = window.innerHeight <= 900 ? 200 : 300
    setChartSize(size)
  }, [])

  useEffect(() => {
    const { accountBalances, consolidatedBalances } = props.consolidated
    const fetchAndFormatBalances = () => {
      try {
        if (props.selectedAccountId) {
          const accountToFind = accountBalances.find(({ accountId }) => accountId === props.selectedAccountId)
          if (!accountToFind) {
            props.fetchAccountBalances(props.selectedAccountId, selectedCurrency.value)
            return
          }

          setData(accountToFind.consolidatedBalances)
        } else {
          if (!consolidatedBalances.length) {
            props.getConsolidatedBalances(selectedCurrency.value)
            return
          }

          setData(consolidatedBalances)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchAndFormatBalances()
  }, [props.selectedAccountId, selectedCurrency, props.consolidated, selectedRate])

  useEffect(() => {
    props.fetchBalances()
  }, [props.selectedCurrency, props.selectedRate])

  useEffect(() => {
    const computeBalance = async () => {
      if (props.selectedAccountId) {
        const account = props.accounts.accounts.find(({ id }) => id === props.selectedAccountId)
        const currency = account.iso_currency_code
        const currentBalance = account.current_balance
        if (currency !== selectedCurrency.value) {
          const { json: { amount } } = await getRequest({ path: '/api/currency/convertion', params: `?base_currency=${currency}&target_currency=${selectedCurrency.value}&amount=${currentBalance}` })
          setBalance(amount.toLocaleString())
          return
        } else {
          setBalance(currentBalance)
          return
        }
      }

      const balanceToFind = props.lastBalances.find(({ target_currency: currency }) => currency === selectedCurrency.value)
      if (!balanceToFind) {
        props.getLastConsolidatedBalances(selectedCurrency.value)
        return
      }

      if (selectedRate.value === 'balance_current_rate') {
        setBalance(balanceToFind.balance_current_rate)
      } else if (selectedRate.value === 'balance_historical_rate') {
        setBalance(balanceToFind.balance_historical_rate)
      }
    }

    computeBalance()
  }, [selectedCurrency, selectedRate, props.lastBalances, props.selectedAccountId])

  return (
    <div className='dashboard-header'>
      <div className='balance'>
        <Amount amount={balance} fontSize={40} />
        <div className='mt-2 text text-gray'>
          <AccountsDropDown
            allAccountsTextToDisplay='Consolidated accounts'
            accounts={props.accounts.accounts}
            selectedAccount={props.accounts.selectedAccountId}
            setSelectedAccount={props.setSelectedAccount}
          />
          balance in
          <CurrencyDropDown
            selectedCurrency={selectedCurrency.value}
            setSelectedCurrency={setCurrency}
          />
          at the
          <ExchangeRateDropDown
            selectedRate={selectedRate.name}
            setSelectedExchangeRate={setExchangeRate}
          />
        </div>
      </div>
      <ResponsiveContainer width='100%' height={chartSize}>
        <AreaChart
          data={data}
          width='100%' height='100%'
          margin={{ top: 20, right: 30, left: 30, bottom: 0 }}
        >
          <XAxis tickLine axisLine={false} reversed dataKey='date' tick={<CustomizedXAxisTick />} />
          <YAxis tickLine={false} axisLine={false} tick={<CustomizedYAxisTick />} />
          <Tooltip />
          <Area type='monotone' dataKey='balance_current_rate' stroke='#109cf19c' fill='#e7f5fe' />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

const CustomizedYAxisTick = props => {
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text x={0} y={0} dy={5} textAnchor='end' fill='#666'>
        {props.payload.value.toLocaleString()}
      </text>
    </g>
  )
}

const CustomizedXAxisTick = props => {
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text x={0} y={0} dy={16} textAnchor='middle' fill='#666'>
        {moment(props.payload.value).format('MMM YYYY')}
      </text>
    </g>
  )
}

const propMap = state => ({
  selectedAccountId: state.accounts.selectedAccountId,
  lastBalances: state.consolidated.lastBalances,
  accounts: state.accounts,
  balances: state.balances,
  consolidated: state.consolidated
})

export default connect(propMap, {
  setSelectedAccount,
  getConsolidatedBalances,
  getLastConsolidatedBalances,
  fetchAccountBalances,
  fetchBalances,
  updateBalances
})(AccountsHeader)
